import { useEffect, useMemo, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"

import { ReactComponent as NotFoundIllustration } from "../assets/icons/404.svg"
import {
  agentRoleFeaturesSelector,
  isMainAccountSelector,
} from "../features/user/user-selectors"
import { getFirstRouteUrlWithViewPermission } from "../lib/agents/agent"

import routes from "../routes/routeDefinitions"

const NotFoundPage = () => {
  const { t } = useTranslation()
  const ref = useRef()

  const agentRoleFeatures = useSelector(agentRoleFeaturesSelector)
  const isMainAccount = useSelector(isMainAccountSelector)

  useEffect(() => {
    const timeoutHandle = setTimeout(() => {
      ref.current.classList.add("active")
    }, 50)

    return () => clearTimeout(timeoutHandle)
  }, [])

  const filteredRouteUrl = useMemo(() => {
    if (isMainAccount === false) {
      return getFirstRouteUrlWithViewPermission(agentRoleFeatures)
    }
    return routes.home.path
  }, [agentRoleFeatures, isMainAccount])

  return (
    <div className="not-found-page" data-testid="not-found-page">
      <NotFoundIllustration
        ref={ref}
        className="not-found-page__illustration"
      />
      <h1 className="not-found-page__title">{t("web_c_pagenotfount_title")}</h1>
      <p className="not-found-page__description">
        {t("web_c_pagenotfound_text")}
      </p>
      <Link
        data-testid="not-found-page-action-link"
        className="button button--primary not-found-page__action-link"
        to={filteredRouteUrl}
      >
        {t("web_c_pagenotfound_goback_button")}
      </Link>
    </div>
  )
}

export default NotFoundPage
