import * as yup from "yup"
import { ALPHABETICAL_NAME_VALIDATOR_REGEX } from "../general"

export const ADD_TEAM_STATE = {
  IDLE: "IDLE",
  STARTED: "STARTED",
  INFORMATION_SET: "INFORMATION_SET",
  AUTHORIZING: "AUTHORIZING",
  ABORTING: "ABORTING",
  REJECTED: "REJECTED",
  AUTHORIZED: "AUTHORIZED",
  ERRORED: "ERRORED",
  ADDED: "ADDED",
}

export const ADD_TEAM_INFORMATION_FORM_ID = "ADD_TEAM_INFORMATION_FORM_ID"

export const getStepNumberFromAddTeamState = (state) => {
  switch (state) {
    case ADD_TEAM_STATE.STARTED:
    case ADD_TEAM_STATE.INFORMATION_SET:
      return 0
    case ADD_TEAM_STATE.AUTHORIZING:
    case ADD_TEAM_STATE.ABORTING:
    case ADD_TEAM_STATE.REJECTED:
    case ADD_TEAM_STATE.AUTHORIZED:
    case ADD_TEAM_STATE.ADDED:
      return 1
    case ADD_TEAM_STATE.IDLE:
    default:
      return -1
  }
}

export const addTeamInformationSchema = yup.object({
  name: yup
    .string()
    .required("web_c_teams_addteammodal_namevalidation1_label")
    .max(30, "web_c_teams_addteammodal_namevalidation2_label")
    .matches(ALPHABETICAL_NAME_VALIDATOR_REGEX, {
      message: "web_c_teams_addteammodal_namevalidation3_label",
    }),
})
