export const BALANCE_HISTORY_OPTION_THIS_YEAR = "THIS_YEAR"
export const BALANCE_HISTORY_OPTION_THIS_MONTH = "THIS_MONTH"
export const BALANCE_HISTORY_OPTION_LAST_THREE_MONTHS = "LAST_THREE_MONTHS"
export const BALANCE_HISTORY_OPTION_THIS_WEEK = "THIS_WEEK"
export const BALANCE_HISTORY_OPTION_TODAY = "TODAY"
export const BALANCE_HISTORY_OPTION_CUSTOM = "CUSTOM"

/**
 * @typedef { BALANCE_HISTORY_OPTION_THIS_YEAR |
 * BALANCE_HISTORY_OPTION_THIS_MONTH |
 * BALANCE_HISTORY_OPTION_THIS_WEEK |
 * BALANCE_HISTORY_OPTION_TODAY
 * } BALANCE_HISTORY_OPTION
 */

export const BALANCE_HISTORY_OPTIONS = [
  BALANCE_HISTORY_OPTION_THIS_YEAR,
  BALANCE_HISTORY_OPTION_THIS_MONTH,
  BALANCE_HISTORY_OPTION_THIS_WEEK,
  BALANCE_HISTORY_OPTION_TODAY,
]

export const MAP_BALANCE_HISTORY_OPTION_TO_LABEL = {
  [BALANCE_HISTORY_OPTION_THIS_YEAR]: "1Y",
  [BALANCE_HISTORY_OPTION_THIS_MONTH]: "1M",
  [BALANCE_HISTORY_OPTION_LAST_THREE_MONTHS]: "3M",
  [BALANCE_HISTORY_OPTION_THIS_WEEK]: "1W",
  [BALANCE_HISTORY_OPTION_TODAY]: "1D",
}

export const DASHBOARD_BREADCRUMB_DATA = {
  title: "web_c_analytics_dashboard_title",
  breadcrumbsList: [
    { text: "web_c_menu_analytics_label" },
    { text: "web_c_analytics_submenu_dashboard_label" },
  ],
}
