import axios from "axios"
import {
  getToken,
  saveAuthenticationData,
} from "../lib/authentication/auth-utils"
import Keycloak from "../lib/authentication/keycloak"
import { getCurrentLocale } from "../lib/i18n"
import { MIN_KC_TOKEN_VALIDITY } from "../shared/constants/auth"

const AxiosInstance = axios.create({
  baseURL: localStorage.getItem("apiUrl"),
})

// istanbul ignore next
AxiosInstance.interceptors.request.use(async (config) => {
  const kc = Keycloak.getInstance()
  const isRefreshed = await kc.updateToken(MIN_KC_TOKEN_VALIDITY)
  if (isRefreshed) {
    saveAuthenticationData(kc)
  }

  const token = getToken()
  const localeObj = JSON.parse(getCurrentLocale())
  const updatedConfig = { ...config }

  updatedConfig.headers["X-Application-Type"] = "Corporate"
  updatedConfig.headers["X-Application-Platform"] = "Web-Browser"

  if (token && !updatedConfig?.headers?.Authorization) {
    updatedConfig.headers.Authorization = `Bearer ${token}`
  }
  if (localeObj) {
    updatedConfig.headers.locale = `${localeObj.lang}`
  }
  return updatedConfig
})

export const kcAxiosInstance = axios.create({
  baseURL: localStorage.getItem("kcAuthUrl"),
})

export default AxiosInstance
