import classNames from "classnames"
import PropTypes from "prop-types"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { ReactComponent as ChevronDownIcon } from "../../assets/icons/chevron.svg"
import { convertNumbersToArabic } from "../../lib/general"
import {
  nextPageDispatcherBuilder,
  prevPageDispatcher,
} from "../../lib/pagination"
import usePagination from "../../shared/hooks/usePagination"

const Pagination = ({
  pageNumber,
  pageSize,
  totalElements,
  totalPages,
  isFirst,
  isLast,
  setPage,
  isRTL,
}) => {
  const { t } = useTranslation()
  const pages = usePagination({
    count: totalPages,
    page: pageNumber,
  })

  let entriesFrom = pageNumber === 1 ? 1 : (pageNumber - 1) * pageSize
  let entriesTo = (pageNumber - 1) * pageSize + pageSize
  let totalEntries = totalElements

  if (entriesTo >= totalElements) {
    entriesTo = totalElements
  }

  if (isRTL) {
    entriesFrom = convertNumbersToArabic(entriesFrom.toString())
    entriesTo = convertNumbersToArabic(entriesTo.toString())
    totalEntries = convertNumbersToArabic(totalEntries.toString())
  }

  useEffect(() => {
    if (pageNumber !== 1 && pageNumber - 1 >= totalPages) {
      setPage(Math.max(totalPages - 1, 0))
    }
  }, [pageNumber, setPage, totalPages])

  return (
    <div className="pagination">
      <p className="pagination__entries">
        {t("general_pagination_entriesnumber_label", {
          from: entriesFrom,
          to: entriesTo,
          total: totalEntries,
        })}
      </p>
      <div className="page-controls">
        <button
          className="page-controls__prev-page"
          data-testid="pagination-prev-page"
          type="button"
          disabled={isFirst}
          onClick={() => {
            setPage(prevPageDispatcher)
          }}
        >
          <ChevronDownIcon width={16} height={16} />
          {t("general_pagination_previous_button")}
        </button>
        {pages.map(({ key, page, isEllipses }) => (
          <button
            key={key}
            className={classNames("page-controls__numbered-page", {
              "page-controls__numbered-page--current": pageNumber === page,
            })}
            type="button"
            data-testid="pagination-numbered-page"
            disabled={pageNumber === page || isEllipses}
            onClick={() => {
              setPage(page - 1)
            }}
          >
            {!isEllipses && isRTL
              ? convertNumbersToArabic(page.toString())
              : page}
          </button>
        ))}
        <button
          className="page-controls__next-page"
          data-testid="pagination-next-page"
          type="button"
          disabled={isLast}
          onClick={() => {
            setPage(nextPageDispatcherBuilder(totalPages))
          }}
        >
          {t("general_pagination_next_button")}
          <ChevronDownIcon width={16} height={16} />
        </button>
      </div>
    </div>
  )
}

Pagination.propTypes = {
  pageNumber: PropTypes.number,
  pageSize: PropTypes.number,
  totalElements: PropTypes.number,
  totalPages: PropTypes.number,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  setPage: PropTypes.func.isRequired,
  isRTL: PropTypes.bool,
}

Pagination.defaultProps = {
  pageNumber: 0,
  pageSize: 0,
  totalElements: 0,
  totalPages: 0,
  isFirst: false,
  isLast: false,
  isRTL: false,
}

export default Pagination
