/**
 * @readonly
 * @enum {string}
 */
export const BULK_TRANSFER = {
  IDLE: "IDLE",
  STARTED: "STARTED",
  UPLOADING: "UPLOADING",
  UPLOADED: "UPLOADED",
  ABORT_UPLOADED: "ABORT_UPLOADED",
  UPLOAD_ERRORED: "UPLOAD_ERRORED",
  ABORT_AUTHORIZATION: "ABORT_AUTHORIZATION",
  CONFIRMATION: "CONFIRMATION",
  CONFIRMED: "CONFIRMED",
  AUTHORIZATION_ERROR: "AUTHORIZATION_ERROR",
  DISTRIBUTING: "DISTRIBUTING",
  DISTRIBUTED: "DISTRIBUTED",
  REJECTED: "REJECTED",
}

export const getStepNumberFromBulkTransferState = (uploadFileState) => {
  switch (uploadFileState) {
    case BULK_TRANSFER.STARTED:
    case BULK_TRANSFER.UPLOADING:
    case BULK_TRANSFER.UPLOADED:
    case BULK_TRANSFER.ABORT_UPLOADED:
    case BULK_TRANSFER.UPLOAD_ERRORED:
      return 0

    case BULK_TRANSFER.CONFIRMATION:
    case BULK_TRANSFER.CONFIRMED:
    case BULK_TRANSFER.DISTRIBUTING:
    case BULK_TRANSFER.DISTRIBUTED:
    case BULK_TRANSFER.REJECTED:
    case BULK_TRANSFER.ABORT_AUTHORIZATION:
    case BULK_TRANSFER.AUTHORIZATION_ERROR:
      return 1

    case BULK_TRANSFER.IDLE:
    default:
      return -1
  }
}

export const CSV_FILE_TABLE_COLUMNS = [
  "web_c_transfers_bulktransfermodal_previewstep_number_label",
  "web_c_transfers_bulktransfermodal_previewstep_name_label",
  "web_c_transfers_bulktransfermodal_previewstep_amount_label",
  "general_account_type_label",
]

export const FILE_RECOVERABLE_ERROR_CODES = [
  "PAYEES_ALREADY_PAID",
  "BALANCE_TOO_LOW",
  "DISTRIBUTION_CURRENCY_MISMATCH",
  "NOT_ALLOWED_CURRENCY",
]

export const FILE_PROMPTABLE_ERROR_CODES = ["DUPLICATED_PAYEES_FOUND"]

// FIXME this mapping should be removed when Backend returns
// the error messages instead of setting it ourselves
/**
 * @constant
 * @readonly
 */
export const INCORRECT_FILE_CONTENT_ERROR_CODE = "INCORRECT_FILE_CONTENT"
export const WRONG_CSV_HEADERS_ERROR_CODE = "WRONG_CSV_HEADERS"
export const DISTRIBUTION_LIMITATION = "DISTRIBUTION_LIMITATION"

export const MAP_BULK_TRANSFER_CSV_ERROR_CODE_TO_MESSAGE = {
  [WRONG_CSV_HEADERS_ERROR_CODE]: "web_c_csvfile_wrongheader_error_message",
  [INCORRECT_FILE_CONTENT_ERROR_CODE]:
    "web_c_csvfile_wrongcontent_error_message",
  [DISTRIBUTION_LIMITATION]:
    "web_c_transfer_bulktransfer_limitexceed_error_text",
}

export const MAP_USER_TYPE_TO_TRANSLATION_KEY = {
  PERSONAL: "general_account_type_personal_label",
  BUSINESS: "general_account_type_business_label",
  CORPORATE: "general_account_type_corporate_label",
  CORPORATE_TERMINAL: "general_account_type_corporate_terminal_label",
  BUSINESS_TERMINAL: "general_account_type_business_terminal_label",
}
