import { lazy, Suspense, useCallback, useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom"
import RootErrorBoundary from "../components/errorBoundaries/RootErrorBoundary"

import Navbar from "../components/navbar/Navbar"
import Topbar from "../components/topbar/Topbar"
import {
  agentRoleFeaturesSelector,
  isMainAccountSelector,
  isNavbarCollapsedSelector,
  isOTPVerifiedSelector,
} from "../features/user/user-selectors"
import { fetchAgentRole, navbarToggled } from "../features/user/user-slice"
import TeamDetails from "../pages/teams/TeamDetails"
import useAuthentication from "../shared/hooks/useAuthentication"
import routes from "./routeDefinitions"
import {
  getFirstRouteUrlWithViewPermission,
  shouldRenderRoute,
} from "../lib/agents/agent"
import { AGENT_FEATURES } from "../shared/constants/agent"

const NotFoundPage = lazy(() => import("../pages/404"))
const NotAllowedUsers = lazy(() => import("../pages/NotAllowedUsers"))
// const Balance = lazy(() => import("../pages/analytics/Balance"))
const Dashboard = lazy(() => import("../pages/analytics/Dashboard"))
const LoginQRCode = lazy(() => import("../pages/LoginQRCode"))
const VerifyOTP = lazy(() => import("../pages/VerifyOTP"))
const TransactionHistory = lazy(() =>
  import("../pages/analytics/TransactionHistory")
)
const Terminals = lazy(() => import("../pages/terminals/Terminals"))
const Employees = lazy(() => import("../pages/employees/Employees"))
const TerminalDetails = lazy(() => import("../pages/terminals/TerminalDetails"))
const Teams = lazy(() => import("../pages/teams/Teams"))
const Payroll = lazy(() => import("../pages/payroll/Payroll"))
const Transfer = lazy(() => import("../pages/transfer/Transfer"))
const Roles = lazy(() => import("../pages/roles/Roles"))
const Users = lazy(() => import("../pages/users/Users"))
const PendingApprovals = lazy(() =>
  import("../pages/pendingApprovals/PendingApprovals")
)

const RoutesComponent = () => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const isNavbarCollapsed = useSelector(isNavbarCollapsedSelector)
  const agentRoleFeatures = useSelector(agentRoleFeaturesSelector)
  const isMainAccount = useSelector(isMainAccountSelector)
  const isOTPVerified = useSelector(isOTPVerifiedSelector)
  const isAuthenticated = useAuthentication()

  const agentRouteUrl = useMemo(
    () => getFirstRouteUrlWithViewPermission(agentRoleFeatures),
    [agentRoleFeatures]
  )

  const toggleNavbar = useCallback(() => {
    dispatch(navbarToggled())
  }, [dispatch])

  useEffect(() => {
    const listener = (e) => {
      if (
        "[]".includes(e.key) &&
        document.activeElement.tagName
          .toLowerCase()
          .search(/input|textarea/) === -1
      )
        toggleNavbar()
    }

    document.addEventListener("keyup", listener)

    return () => {
      document.removeEventListener("keyup", listener)
    }
  }, [toggleNavbar])

  useEffect(() => {
    if (
      isMainAccount === false &&
      isOTPVerified &&
      pathname !== routes.verifyOTP.path &&
      !agentRoleFeatures
    )
      dispatch(fetchAgentRole())
  }, [agentRoleFeatures, dispatch, isMainAccount, isOTPVerified, pathname])

  return (
    <Suspense fallback="">
      <Routes>
        {isAuthenticated ? (
          <Route
            path={routes.home.path}
            element={
              <>
                <Topbar toggleNavbar={toggleNavbar} />
                <Navbar isCollapsed={isNavbarCollapsed} />
                <main className="main-content-wrapper">
                  <Suspense>
                    <RootErrorBoundary key={pathname} pathname={pathname}>
                      <Outlet />
                    </RootErrorBoundary>
                  </Suspense>
                </main>
              </>
            }
          >
            {shouldRenderRoute(isMainAccount, agentRoleFeatures, [
              AGENT_FEATURES.DASHBOARD,
            ]) && <Route index element={<Dashboard />} />}
            {/* FIXME: add balance page back later if asked */}
            {/* {shouldRenderRoute(isMainAccount, agentRoleFeatures, [
              AGENT_FEATURES.BALANCE,
            ]) && <Route path={routes.balance.path} element={<Balance />} />} */}
            {shouldRenderRoute(isMainAccount, agentRoleFeatures, [
              AGENT_FEATURES.OPERATION_HISTORY,
            ]) && (
              <Route
                path={routes.transactionHistory.path}
                element={<TransactionHistory />}
              />
            )}
            {shouldRenderRoute(isMainAccount, agentRoleFeatures, [
              AGENT_FEATURES.TERMINALS,
            ]) && (
              <>
                <Route path={routes.terminals.path} element={<Terminals />} />
                <Route
                  path={routes.terminals.terminalDetails.path}
                  element={<TerminalDetails />}
                />
              </>
            )}
            {shouldRenderRoute(isMainAccount, agentRoleFeatures, [
              AGENT_FEATURES.EMPLOYEES,
            ]) && (
              <Route path={routes.employees.path} element={<Employees />} />
            )}
            {shouldRenderRoute(isMainAccount, agentRoleFeatures, [
              AGENT_FEATURES.TEAMS,
            ]) && (
              <>
                <Route path={routes.teams.path} element={<Teams />} />
                <Route
                  path={routes.teams.teamDetails.path}
                  element={<TeamDetails />}
                />
              </>
            )}
            {shouldRenderRoute(isMainAccount, agentRoleFeatures, [
              AGENT_FEATURES.FILE_SALARY_DISTRIBUTIONS,
              AGENT_FEATURES.TEAM_SALARY_DISTRIBUTIONS,
            ]) && <Route path={routes.payroll.path} element={<Payroll />} />}
            {shouldRenderRoute(isMainAccount, agentRoleFeatures, [
              AGENT_FEATURES.BANK_TRANSFERS,
              AGENT_FEATURES.RECEIVE_PAYMENTS,
            ]) && <Route path={routes.transfer.path} element={<Transfer />} />}
            {shouldRenderRoute(isMainAccount, agentRoleFeatures, [
              AGENT_FEATURES.ROLES,
            ]) && <Route path={routes.roles.path} element={<Roles />} />}
            {shouldRenderRoute(isMainAccount, agentRoleFeatures, [
              AGENT_FEATURES.AGENTS,
            ]) && <Route path={routes.users.path} element={<Users />} />}
            {isMainAccount && (
              <Route
                path={routes.pendingApprovals.path}
                element={<PendingApprovals />}
              />
            )}
            <Route
              path={pathname === routes.home.path ? "/" : "*"}
              element={
                pathname === routes.home.path ? (
                  <Navigate to={agentRouteUrl} replace />
                ) : (
                  <NotFoundPage />
                )
              }
            />
          </Route>
        ) : undefined}
        <Route path="*" element="" />
        <Route path={routes.verifyQrcode.path} element={<LoginQRCode />} />
        <Route path={routes.verifyOTP.path} element={<VerifyOTP />} />
        <Route
          path={routes.notAllowedUsers.path}
          element={<NotAllowedUsers />}
        />
      </Routes>
    </Suspense>
  )
}

export default RoutesComponent
