import classNames from "classnames"
import PropTypes from "prop-types"

import { ReactComponent as TimesIcon } from "../../assets/icons/times.svg"
import {
  MAP_TOAST_TYPE_TO_ICON,
  TOAST_TYPE_ERROR,
  TOAST_TYPE_SUCCESS,
} from "../../shared/constants/toast"

const Toast = ({
  closeToast,
  text,
  type,
  auxilaryButtonLabel,
  showAuxiliaryButton,
  onAuxiliaryClicked,
}) => {
  const ToastIcon = MAP_TOAST_TYPE_TO_ICON[type]

  return (
    <div
      className={classNames("toast", {
        "toast--error": type === TOAST_TYPE_ERROR,
        "toast--success": type === TOAST_TYPE_SUCCESS,
      })}
      data-testid="toast"
    >
      <div className="toast__icon-container">
        <ToastIcon width={20} height={20} />
      </div>
      <p className="toast__content" data-testid="toast-content">
        {text}
      </p>
      {showAuxiliaryButton && (
        <button
          className="button button--secondary toast__auxilary-button"
          data-testid="auxilary-button"
          type="button"
          onClick={onAuxiliaryClicked}
        >
          {auxilaryButtonLabel}
        </button>
      )}
      <button
        className="toast__close-button"
        data-testid="close-button"
        onClick={closeToast}
        type="button"
      >
        <TimesIcon />
      </button>
    </div>
  )
}

Toast.propTypes = {
  closeToast: PropTypes.func,
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf([TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS]).isRequired,
  auxilaryButtonLabel: PropTypes.string,
  showAuxiliaryButton: PropTypes.bool,
  onAuxiliaryClicked: PropTypes.func,
}

Toast.defaultProps = {
  closeToast: undefined,
  auxilaryButtonLabel: undefined,
  showAuxiliaryButton: false,
  onAuxiliaryClicked: undefined,
}

export default Toast
