import {
  ENGLISH_LOCALE,
  ARABIC_LOCALE,
  KURDISH_CENTRAL_LOCALE,
  KURDISH_KURMANJI_LOCALE,
} from "./languages"

export const LANGUAGE_SELECT_OPTIONS = [
  {
    ...ENGLISH_LOCALE,
    text: "web_c_accsettingsidebar_language_english_label",
  },
  {
    ...ARABIC_LOCALE,
    text: "web_c_accsettingsidebar_language_arabic_label",
  },
  {
    ...KURDISH_CENTRAL_LOCALE,
    text: "web_c_accsettingsidebar_language_kurdishsorani_label",
  },
  {
    ...KURDISH_KURMANJI_LOCALE,
    text: "web_c_accsettingsidebar_language_kurdishbadini_label",
  },
]

export const MAP_BUSINESS_TYPE_TO_LOKALISE_KEY = {
  CAFE: "web_c_corporatecategory_cafe",
  FOOD: "web_c_corporatecategory_food",
  GAS_STATION: "web_c_corporatecategory_gasstation",
  GROCERY: "web_c_corporatecategory_grocery",
  KIOSK: "web_c_corporatecategory_kiosk",
  PERSONAL_CARE: "web_c_corporatecategory_personalcare",
  PHARMACY: "web_c_corporatecategory_pharmacy",
  SHOPPING: "web_c_corporatecategory_shopping",
  WORKSHOP: "web_c_corporatecategory_workshop",
  PHONE_STORE: "web_c_corporatecategory_phonestore",
  BARBERSHOP: "web_c_corporatecategory_barbershophairdresser",
  IT_SERVICES: "web_c_corporatecategory_itservices",
  EXCHANGE_OFFICE: "web_c_corporatecategory_exchangeoffice",
  NOT_CHOSEN: "web_c_corporatecategory_notchosen",
}

// FIXME this mapping should be removed when Backend returns
// the error messages instead of setting it ourselves
/**
 * @constant
 * @readonly
 */
export const IRAQI_NUMBER_HAS_WRONG_PREFIX_ERROR_CODE =
  "IRAQI_NUMBER_HAS_WRONG_PREFIX"

export const MAP_USER_ERROR_CODE_TO_MESSAGE = {
  [IRAQI_NUMBER_HAS_WRONG_PREFIX_ERROR_CODE]:
    "web_c_users_invalidphonenumber_error_message",
}

export const USER_GROUP_CORPORATE = "CORPORATE"

/**
 * BE returns this when the user has verified OTP before.
 */
export const OTP_STATUS_VERIFIED = "OTP_VERIFIED"

/**
 * BE returns this when the user hasn't verified OTP before.
 */
export const OTP_STATUS_NOT_VERIFIED = "OTP_NOT_VERIFIED"

/**
 * @typedef { OTP_STATUS_VERIFIED | OTP_STATUS_NOT_VERIFIED } OTP_STATUS
 */

/**
 * BE returns this when the user needs to update password
 * before having any access to the app whatsoever.
 */
export const PASSWORD_CHANGE_REQUIRED = "PASSWORD_CHANGE_REQUIRED"

/**
 * BE returns this when the user doesn't need to perform any action
 * before accessing the app.
 */
export const NO_ACTION_NEEDED = "NO_ACTION_NEEDED"

/**
 * @typedef { PASSWORD_CHANGE_REQUIRED | NO_ACTION_NEEDED } PASSWORD_STATUS
 */
