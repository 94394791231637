import { AGENT_FEATURES, PERMISSIONS } from "../agent"
import { ALIGN_START } from "../table"

export const getTeamDetailsBreadcrumbData = (title = "") => ({
  title,
  breadcrumbsList: [
    { text: "web_c_menu_employee_label" },
    { text: "web_c_teams_title" },
    { text: title },
  ],
})

export const ACTION_BUTTONS_ICON_SIZE = 16

export const TEAM_DETAILS_COLUMNS = [
  {
    name: "name",
    title: "web_c_teams_teamdetail_name_label",
    requiredPermissions: [],
  },
  {
    name: "phoneNumber",
    title: "web_c_teams_teamdetail_phonenumber_label",
    requiredPermissions: [],
  },
  {
    name: "iban",
    title: "web_c_teams_teamdetail_iban_label",
    requiredPermissions: [],
  },
  {
    name: "position",
    title: "web_c_teams_teamdetail_position_label",
    requiredPermissions: [],
  },
  {
    name: "dateOfBirth",
    title: "web_c_teams_teamdetail_dateofbirth_label",
    requiredPermissions: [],
  },
  {
    name: "action",
    title: "web_c_teams_teamdetail_action_label",
    requiredPermissions: [
      {
        feature: AGENT_FEATURES.TEAMS,
        permission: PERMISSIONS.EDIT,
      },
    ],
  },
].map((field) => ({ ...field, align: ALIGN_START }))

export const TEAM_MEMBER_DELETION_STATE = {
  IDLE: "IDLE",
  STARTED: "STARTED",
  AUTHORIZING: "AUTHORIZING",
  ABORTING: "ABORTING",
  REJECTED: "REJECTED",
  AUTHORIZED: "AUTHORIZED",
  DELETING: "DELETING",
  DELETED: "DELETED",
  ERRORED: "ERRORED",
}
