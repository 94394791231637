import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import dayjs from "dayjs"

import {
  checkAuthorizationCode,
  createAuthorizationCode,
} from "../../api/endpoints"
import {
  getElevatedTokenByVerifyingPin,
  getPinStatus,
} from "../../api/endpoints/users"
import { diffWithNow } from "../../lib/general"
import { GENERAL_ERROR } from "../../shared/constants/general"
import {
  OTAC_STATUS_AUTHORIZED,
  OTAC_STATUS_REJECTED,
} from "../../shared/constants/otac"

const SLICE_NAME = "otac"

/**
 * @param {string} context - the context that the OTAC will be used for (i.e LOGIN or OTHER).
 */
export const requestOneTimeAuthCodePayloadCreator = async (
  context,
  thunkApi
) => {
  const response = await createAuthorizationCode(
    { context },
    { signal: thunkApi.signal }
  )
  const { data, status, statusText } = response
  return { data, status, statusText }
}

export const requestOneTimeAuthCode = createAsyncThunk(
  `${SLICE_NAME}/requestOneTimeAuthCode`,
  requestOneTimeAuthCodePayloadCreator
)

/**
 * @param {string} authorizationCode - the OTAC code generated previously
 */
export const checkOneTimeAuthCodePayloadCreator = async (authorizationCode) => {
  const response = await checkAuthorizationCode(authorizationCode)
  const { data, status, statusText } = response
  return { data, status, statusText }
}

export const checkOneTimeAuthCode = createAsyncThunk(
  `${SLICE_NAME}/checkOneTimeAuthCodeStatus`,
  checkOneTimeAuthCodePayloadCreator
)

// Pin Authorization
export const checkingPinAuthorizationStatus = createAsyncThunk(
  `${SLICE_NAME}/checkingPinAuthorizationStatus`,
  async (_, thunkAPI) => {
    try {
      const { data } = await getPinStatus({ signal: thunkAPI.signal })
      return { data }
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data)
    }
  }
)

export const fetchElevatedTokenByAuthorizingPin = createAsyncThunk(
  `${SLICE_NAME}/fetchElevatedTokenByAuthorizingPin`,
  /**
   * @type {import("@reduxjs/toolkit").AsyncThunkPayloadCreator<>}
   */
  async ({ instanceId, enteredPin: pin }, thunkAPI) => {
    try {
      const { data, status, statusText } = await getElevatedTokenByVerifyingPin(
        { instanceId, pin },
        {
          signal: thunkAPI.signal,
        }
      )
      return { data, status, statusText }
    } catch (error) {
      const preparedError = {
        code: GENERAL_ERROR,
        message: "web_c_general_error_massage",
      }
      if (error?.response?.data?.error) {
        preparedError.code = error.response.data.error
        preparedError.message = error.response.data.error_description
      }

      throw thunkAPI.rejectWithValue(preparedError)
    }
  }
)

export const initialState = {
  /**
   * The OTAC that should be generated by BE and will be
   * presented as QR Code to be scanned by FIB mobile apps.
   * @type {string}
   * @default null
   */
  authorizationCode: null,

  /**
   * the context that the OTAC will be used for (i.e LOGIN or OTHER).
   * @type {string}
   * @default null
   */
  context: null,

  /**
   * the number of `ms` that remained from the time when
   * the OTAC generated, will be used to show countdown
   * in the UI layer
   * @type {number}
   * @default null
   */
  countDown: null,

  /**
   * The elevated token that will be returned by BE when
   * the OTAC code have been authorized.
   * @type {string}
   * @default null
   */
  elevatedToken: null,

  /**
   * the error message that happened during OTAC request
   * or verification.
   * @type {string}
   * @default null
   */
  error: null,

  /**
   * the status of the OTAC code
   * @type {string}
   * @default null
   */
  status: null,

  /**
   * the time in `ms` until which the OTAC is valid, it's
   * calculated as currentTime + X minute
   * where X is the minutes the OTAC will be valid to.
   * @type {number}
   * @default null
   */
  validUntil: null,

  /**
   * indicates whether the OTAC code is authorized or not
   * @type {boolean}
   * @default false
   */
  isAuthorized: false,

  /**
   * indicates whether the OTAC code is rejected or not
   * @type {boolean}
   * @default false
   */
  isRejected: false,

  /**
   * indicates whether the OTAC is still to be created
   * or not.
   * @type {boolean}
   * @default false
   */
  isPending: false,

  /**
   * indicates whether OTAC successfully generated or not
   * @type {boolean}
   * @default false
   */
  isSucceed: false,

  /**
   * indicates whether an error happened at any stage or not.
   * @type {boolean}
   * @default false
   */
  isError: false,

  /**
   * indicates whether the OTAC is expired or not.
   * @type {boolean}
   * @default false
   */
  isExpired: false,

  /**
   * represents the number of times checking status of
   * the OTAC failures.
   * @type {number}
   * @default 0
   */
  checkStatusRejectionCount: 0,

  /**
   * indicates errors that happen when authorizing the pin to do sensitive actions for agents
   */
  pinAuthorizationError: undefined,
  isPinAuthorizationLoading: false,
  pinAuthorizationInstanceId: undefined,
}

const OTACSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    countDownTicked: (state) => {
      state.countDown = diffWithNow(state.validUntil).valueOf()
    },
    expired: (state) => {
      state.isExpired = true
    },
    resetState: () => initialState,
    // -------- pin authorization -------- \\
    resetPinAuthorizationError: (state) => {
      state.pinAuthorizationError = initialState.pinAuthorizationError
    },
  },
  extraReducers: (builder) => {
    /** One Time Authorization Code Requesting Actions */
    builder.addCase(requestOneTimeAuthCode.pending, (state, action) => {
      state.context = action.meta.arg
      state.authorizationCode = null
      state.validUntil = null
      state.countDown = null
      state.error = null
      state.checkStatusRejectionCount = 0
      state.isPending = true
      state.isSucceed = false
      state.isError = false
      state.isExpired = false
      state.isAuthorized = false
      state.isRejected = false
    })

    builder.addCase(requestOneTimeAuthCode.fulfilled, (state, action) => {
      state.isSucceed = action.payload.status === 201
      state.isPending = false
      state.validUntil = dayjs()
        .add(
          process.env.NODE_ENV === "test" ? global.OTAC_TIMEOUT || 10 : 60,
          "second"
        )
        .valueOf()
      state.countDown = diffWithNow(state.validUntil).valueOf()
      state.authorizationCode = action.payload.data.oneTimeAuthorizationCode
    })

    builder.addCase(requestOneTimeAuthCode.rejected, (state, action) => {
      state.isPending = false
      if (action.meta.aborted) {
        state.context = null
        return
      }

      state.isError = true
      state.error = action.error.message
    })

    /** One Time Authorization Code Status Checking Actions */
    builder.addCase(checkOneTimeAuthCode.fulfilled, (state, action) => {
      state.isAuthorized = action.payload.data.status === OTAC_STATUS_AUTHORIZED
      state.isRejected = action.payload.data.status === OTAC_STATUS_REJECTED
      state.elevatedToken = action.payload.data.elevatedToken
      state.status = action.payload.data.status
    })

    builder.addCase(checkOneTimeAuthCode.rejected, (state, action) => {
      state.checkStatusRejectionCount += 1

      if (state.checkStatusRejectionCount > 4) {
        state.isError = true
        state.error = action.error.message
      }
    })

    // -------- pin authorization -------- \\
    builder.addCase(checkingPinAuthorizationStatus.pending, (state) => {
      state.pinAuthorizationError = undefined
    })
    builder.addCase(
      checkingPinAuthorizationStatus.fulfilled,
      (state, action) => {
        state.pinAuthorizationInstanceId = action.payload.data?.instanceId
      }
    )
    builder.addCase(
      checkingPinAuthorizationStatus.rejected,
      (state, action) => {
        const preparedError = {
          code: GENERAL_ERROR,
          message: "web_c_general_error_massage",
        }

        if (Array.isArray(action?.payload?.errors)) {
          const [firstError] = action.payload.errors
          preparedError.code = firstError.code
          preparedError.message = firstError.detail
        }

        state.pinAuthorizationError = preparedError
      }
    )

    builder.addCase(fetchElevatedTokenByAuthorizingPin.pending, (state) => {
      state.pinAuthorizationError = undefined
      state.isPinAuthorizationLoading = true
    })
    builder.addCase(
      fetchElevatedTokenByAuthorizingPin.fulfilled,
      (state, action) => {
        state.elevatedToken = action.payload.data.access_token
        state.isPinAuthorizationLoading = false
      }
    )
    builder.addCase(
      fetchElevatedTokenByAuthorizingPin.rejected,
      (state, action) => {
        state.isPinAuthorizationLoading = false
        state.pinAuthorizationError = action.payload
      }
    )
  },
})

export const {
  expired,
  countDownTicked,
  resetState,
  // -------- pin authorization -------- \\
  resetPinAuthorizationError,
} = OTACSlice.actions

export default OTACSlice.reducer
