import classNames from "classnames"
import PropType from "prop-types"
import {
  ALIGN_CENTER,
  ALIGN_END,
  ALIGN_START,
} from "../../shared/constants/table"

const Cell = ({ children, align, customClasses, title }) => {
  const className = classNames("table-cell", customClasses, {
    "table-cell--start-aligned": align === ALIGN_START,
    "table-cell--end-aligned": align === ALIGN_END,
    "table-cell--center-aligned": align === ALIGN_CENTER,
  })

  return (
    <td className={className} title={title}>
      {children}
    </td>
  )
}

Cell.propTypes = {
  children: PropType.node,
  align: PropType.oneOf([ALIGN_START, ALIGN_CENTER, ALIGN_END]),
  customClasses: PropType.string,
  title: PropType.string,
}

Cell.defaultProps = {
  children: undefined,
  align: undefined,
  customClasses: undefined,
  title: undefined,
}

export default Cell
