import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import {
  createRole,
  deleteRole,
  getRolesPermissionTemplate,
  getRoleDetails,
  getRoles,
  authorizeRole,
  setRoleApprovalThresholds,
} from "../../api/endpoints/roles"
import { ROLE_ADDITION_STATE } from "../../shared/constants/roles/add-role"
import { ROLE_DELETION_STATE } from "../../shared/constants/roles/delete-role"

const SLICE_NAME = "roles"

export const roleAuthorization = createAsyncThunk(
  `${SLICE_NAME}/roleAuthorization`,
  async (_, thunkAPI) => {
    const { elevatedToken } = thunkAPI.getState().otac

    if (!elevatedToken)
      throw new Error("web_c_general_notauthorized_error_text")

    const { id } = thunkAPI.getState().roles.roleDetails

    try {
      const { data, status, statusText } = await authorizeRole(id, {
        headers: { Authorization: `Bearer ${elevatedToken}` },
      })

      return { data, status, statusText }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

export const fetchRolePermissionsTemplate = createAsyncThunk(
  `${SLICE_NAME}/fetchRolePermissionsTemplate`,
  async () => {
    const { data, status, statusText } = await getRolesPermissionTemplate()
    return {
      data,
      status,
      statusText,
    }
  }
)

export const roleCreation = createAsyncThunk(
  `${SLICE_NAME}/roleCreation`,
  async (role) => {
    const { data, status, statusText } = await createRole(role)
    return {
      data,
      status,
      statusText,
    }
  }
)

export const roleApprovalThresholdsSetting = createAsyncThunk(
  `${SLICE_NAME}/roleApprovalThresholdsSetting`,
  async ({ roleId, name, approvalThresholds }) => {
    const { data, status, statusText } = await setRoleApprovalThresholds(
      roleId,
      {
        name,
        approvalThresholds,
      }
    )
    return {
      data,
      status,
      statusText,
    }
  }
)

export const fetchRoleDetails = createAsyncThunk(
  `${SLICE_NAME}/fetchRoleDetails`,
  async (id) => {
    const { data, status, statusText } = await getRoleDetails(id)
    return {
      data,
      status,
      statusText,
    }
  }
)

export const fetchRoles = createAsyncThunk(
  `${SLICE_NAME}/fetchRoles`,
  async ({ page, size }) => {
    const { data, status, statusText } = await getRoles({
      page,
      size,
    })
    return { data, status, statusText }
  }
)

export const roleDeletion = createAsyncThunk(
  `${SLICE_NAME}/roleDeletion`,
  async (_, thunkAPI) => {
    const { elevatedToken } = thunkAPI.getState().otac

    if (!elevatedToken)
      throw new Error("web_c_general_notauthorized_error_text")

    const { id } = thunkAPI.getState().roles.roleDeletion

    const { data, status, statusText } = await deleteRole(id, {
      headers: { Authorization: `Bearer ${elevatedToken}` },
    })
    return { data, status, statusText }
  }
)

/**
 * @typedef RoleDeletion
 * @property {import("../../shared/constants/roles/delete-role").RoleDeletionState} state
 * @property {string=} id
 * @property {string=} name
 * @property {import("@reduxjs/toolkit").SerializedError} error
 *
 * @typedef RolesInitialState
 * @property {object[]} roles
 * @property {boolean} isRolesLoading
 * @property {RoleDeletion} roleDeletion
 * @property {RoleAddition} roleAddition
 * @property {import("@reduxjs/toolkit").SerializedError} rolesError
 * @property {boolean} isFirst
 * @property {boolean} isLast
 * @property {number} pageNumber
 * @property {number} pageSize
 * @property {number} totalElements
 * @property {number} totalPages
 *
 * @property {import("../../api/endpoints/roles").GetRoleDetailsResponse} roleDetails
 * @property {boolean} isRoleDetailsLoading
 * @property {import("@reduxjs/toolkit").SerializedError} roleDetailsError
 */

/**
 * @typedef RoleAddition
 * @property {import("../../shared/constants/roles/add-role").RoleAdditionState} state
 * @property {string} name
 * @property {boolean} isRoleTemplateLoading
 * @property {boolean} isRoleCreationLoading
 * @property {boolean} isApprovalThresholdsSettingLoading
 * @property {import("../../api/endpoints/roles").RolesPermissionTemplate} roleTemplate
 * @property {import("@reduxjs/toolkit").SerializedError} roleTemplateError
 * @property {string} roleId
 * @property {import("@reduxjs/toolkit").SerializedError} error
 * @property {import("../../api/endpoints/roles").roleFeatures} roleFeatures
 * @property {import("../../api/endpoints/roles").ApprovalThreshold} roleLimitation
 * @property {boolean} isConfirmed
 * @property {boolean} shouldFetchRoleDetails
 */

/**
 * @type {RolesInitialState}
 */

export const initialState = {
  roles: undefined,
  isRolesLoading: false,
  rolesError: undefined,
  isFirst: false,
  isLast: false,
  pageNumber: undefined,
  pageSize: undefined,
  totalElements: undefined,
  totalPages: undefined,

  roleDeletion: {
    state: ROLE_DELETION_STATE.IDLE,
    id: undefined,
    name: undefined,
    error: undefined,
  },

  roleAddition: {
    state: ROLE_ADDITION_STATE.IDLE,
    name: undefined,
    isRoleTemplateLoading: false,
    isRoleCreationLoading: false,
    isApprovalThresholdsSettingLoading: false,
    roleTemplate: undefined,
    roleTemplateError: undefined,

    roleId: undefined,
    error: undefined,
    roleFeatures: undefined,
    roleLimitation: undefined,

    /**
     * @description whether the user confirmed the role addition
     * to be authorized to add a role
     */
    isConfirmed: false,

    /**
     * @description whether to fetch roleDetails in CONFIRMATION state
     */
    shouldFetchRoleDetails: false,
  },

  roleDetails: undefined,
  isRoleDetailsLoading: false,
  roleDetailsError: undefined,
}

const rolesSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    // -------- role details -------- \\
    resetRoleDetails: (state) => {
      state.roleDetails = initialState.roleDetails
      state.roleDetailsError = initialState.roleDetailsError
    },
    // -------- delete role -------- \\
    /**
     * @param {import("@reduxjs/toolkit").PayloadAction<{
     *  id: string,
     *  name: string,
     * }>} action
     */
    roleDeletionStarted: (state, action) => {
      state.roleDeletion.state = ROLE_DELETION_STATE.STARTED
      state.roleDeletion.id = action.payload.id
      state.roleDeletion.name = action.payload.name
    },
    roleDeletionRejected: (state) => {
      state.roleDeletion.state = ROLE_DELETION_STATE.REJECTED
    },
    roleDeletionAuthorizing: (state) => {
      state.roleDeletion.state = ROLE_DELETION_STATE.AUTHORIZING
    },
    roleDeletionAborting: (state) => {
      state.roleDeletion.state = ROLE_DELETION_STATE.ABORTING
    },
    roleDeletionFinished: (state) => {
      state.roleDeletion = initialState.roleDeletion
    },

    // -------- add role -------- \\
    roleAdditionStarted: (state) => {
      state.roleAddition.state = ROLE_ADDITION_STATE.STARTED
      state.roleAddition.name = undefined
    },
    roleAdditionFinished: (state) => {
      state.roleAddition = initialState.roleAddition
      state.roleDetails = undefined
    },
    roleAdditionPermissionSet: (state) => {
      state.roleAddition.state = ROLE_ADDITION_STATE.PERMISSION_SET
    },
    roleAdditionAuthorizing: (state) => {
      state.roleAddition.state = ROLE_ADDITION_STATE.AUTHORIZING
      state.roleAddition.isConfirmed = true
    },
    roleAdditionRejected: (state) => {
      state.roleAddition.state = ROLE_ADDITION_STATE.REJECTED
    },
    roleAdditionAborting: (state) => {
      state.roleAddition.state = ROLE_ADDITION_STATE.ABORTING
    },
    roleAdditionChangedName: (state, action) => {
      state.roleAddition.name = action.payload
    },
    roleAdditionConfirmation: (state) => {
      state.roleAddition.state = ROLE_ADDITION_STATE.CONFIRMATION
      state.roleAddition.shouldFetchRoleDetails = false
    },
  },
  extraReducers: (builder) => {
    // -------- get role template -------- \\
    builder.addCase(fetchRolePermissionsTemplate.pending, (state) => {
      state.roleAddition.isRoleTemplateLoading = true
    })
    builder.addCase(fetchRolePermissionsTemplate.fulfilled, (state, action) => {
      state.roleAddition.isRoleTemplateLoading = false
      state.roleAddition.roleTemplate = action.payload.data.features
    })
    builder.addCase(fetchRolePermissionsTemplate.rejected, (state, action) => {
      state.roleAddition.isRoleTemplateLoading = false
      state.roleAddition.roleTemplateError = action.error
    })
    // -------- get roles -------- \\
    builder.addCase(fetchRoles.pending, (state) => {
      state.isRolesLoading = true
    })
    builder.addCase(fetchRoles.fulfilled, (state, action) => {
      state.isRolesLoading = false
      state.roles = action.payload.data.content
      state.isFirst = action.payload.data.first
      state.isLast = action.payload.data.last
      state.pageNumber = action.payload.data.pageNumber
      state.pageSize = action.payload.data.pageSize
      state.totalElements = action.payload.data.totalElements
      state.totalPages = action.payload.data.totalPages
    })
    builder.addCase(fetchRoles.rejected, (state, action) => {
      state.isRolesLoading = false
      state.rolesError = action.error
    })
    // -------- add role -------- \\
    builder.addCase(roleCreation.pending, (state) => {
      state.roleAddition.isRoleCreationLoading = true
    })
    builder.addCase(roleCreation.fulfilled, (state, action) => {
      state.roleAddition.state = ROLE_ADDITION_STATE.PERMISSION_SET
      state.roleAddition.roleId = action.payload.data.id
      state.roleAddition.name = action.meta.arg.name
      state.roleAddition.roleFeatures = action.meta.arg
      state.roleAddition.isRoleCreationLoading = false
    })
    builder.addCase(roleCreation.rejected, (state, action) => {
      state.roleAddition.state = ROLE_ADDITION_STATE.ERRORED
      state.roleAddition.error = action.error
      state.roleAddition.isRoleCreationLoading = false
    })
    builder.addCase(roleApprovalThresholdsSetting.pending, (state) => {
      state.roleAddition.isApprovalThresholdsSettingLoading = true
    })
    builder.addCase(
      roleApprovalThresholdsSetting.fulfilled,
      (state, action) => {
        state.roleAddition.state = ROLE_ADDITION_STATE.CONFIRMATION
        state.roleAddition.isConfirmed = false
        state.roleAddition.roleLimitation = action.meta.arg
        state.roleAddition.name = action.meta.arg.name
        state.roleAddition.shouldFetchRoleDetails = true
        state.roleAddition.isApprovalThresholdsSettingLoading = false
      }
    )
    builder.addCase(roleApprovalThresholdsSetting.rejected, (state, action) => {
      state.roleAddition.state = ROLE_ADDITION_STATE.ERRORED
      state.roleAddition.error = action.error
      state.roleAddition.isApprovalThresholdsSettingLoading = false
    })
    builder.addCase(roleAuthorization.fulfilled, (state) => {
      state.roleAddition.state = ROLE_ADDITION_STATE.ADDED
    })
    builder.addCase(roleAuthorization.rejected, (state, action) => {
      state.roleAddition.state = ROLE_ADDITION_STATE.ERRORED
      const error = { message: "web_c_general_error_massage" }

      if (Array.isArray(action.payload?.errors)) {
        const { detail } = action.payload.errors.pop()
        error.message = detail
      } else {
        error.message = action.error.message
      }

      state.roleAddition.error = error
    })
    // -------- delete role -------- \\
    builder.addCase(roleDeletion.fulfilled, (state) => {
      state.roleDeletion.state = ROLE_DELETION_STATE.DELETED
    })
    builder.addCase(roleDeletion.rejected, (state, action) => {
      state.roleDeletion.state = ROLE_DELETION_STATE.ERRORED
      state.roleDeletion.error = action.error
    })
    // ------- get role details -------- \\
    builder.addCase(fetchRoleDetails.pending, (state) => {
      state.isRoleDetailsLoading = true
    })
    builder.addCase(fetchRoleDetails.fulfilled, (state, action) => {
      state.isRoleDetailsLoading = false
      state.roleDetails = action.payload.data
    })
    builder.addCase(fetchRoleDetails.rejected, (state, action) => {
      state.isRoleDetailsLoading = false
      state.roleDetailsError = action.error
    })
  },
})

export const {
  // -------- Add Role -------- \\
  roleAdditionStarted,
  roleAdditionFinished,
  roleAdditionPermissionSet,
  roleAdditionAuthorizing,
  roleAdditionRejected,
  roleAdditionAborting,
  roleAdditionChangedName,
  roleAdditionConfirmation,
  // -------- role details -------- \\
  resetRoleDetails,
  // -------- delete role -------- \\
  roleDeletionStarted,
  roleDeletionRejected,
  roleDeletionAuthorizing,
  roleDeletionAborting,
  roleDeletionFinished,
} = rolesSlice.actions

export default rolesSlice.reducer
