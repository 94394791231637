import { createSelector } from "@reduxjs/toolkit"

export const teamlessEmployeesSelector = createSelector(
  (state) => state.employees.teamlessEmployees,
  (teamlessEmployees) => {
    if (!Array.isArray(teamlessEmployees)) return []

    return teamlessEmployees
  }
)

export const employeesSelector = (state) => state.employees.employees

export const isEmployeesLoadingSelector = (state) =>
  state.employees.isEmployeesLoading

export const employeesErrorSelector = (state) => state.employees.employeesError

export const employeeAccountIbanSelector = (state) =>
  state.employees.addOrEditEmployeeInfo.accountIban

export const employeeIsEmployeeHasFIBLoadingSelector = (state) =>
  state.employees.addOrEditEmployeeInfo.isEmployeeHasFIBLoading

export const employeeAccountIbanErrorSelector = (state) =>
  state.employees.addOrEditEmployeeInfo.accountIbanErrors

export const employeeIsDateOfBirthMatchedSelector = (state) =>
  state.employees.addOrEditEmployeeInfo.isDateOfBirthMatched

// -------- Add Employee -------- \\
export const employeeAdditionStateSelector = (state) =>
  state.employees.employeeAddition.state

/**
 * @typedef {object} error
 * @property {string} error.message
 *
 * @returns {error}
 */
export const employeeAdditionErrorSelector = (state) =>
  state.employees.employeeAddition.error

// -------- Employee Deletion -------- \\
export const employeeDeletionStateSelector = (state) =>
  state.employees.employeeDeletion.state

export const employeeDeletionEmployeeNameSelector = (state) =>
  state.employees.employeeDeletion.name

export const isFirstPageSelector = (state) => state.employees.isFirst
export const isLastPageSelector = (state) => state.employees.isLast
export const pageNumberSelector = (state) => state.employees.pageNumber
export const pageSizeSelector = (state) => state.employees.pageSize
export const totalElementsSelector = (state) => state.employees.totalElements
export const totalPagesSelector = (state) => state.employees.totalPages

// -------- Edit Employee -------- \\
export const editingEmployeeStateSelector = (state) =>
  state.employees.editingEmployee.state

export const editingEmployeeNameSelector = (state) =>
  state.employees.editingEmployee.name

export const editingEmployeeDateOfBirthSelector = (state) =>
  state.employees.editingEmployee.dateOfBirth

export const editingEmployeePhoneNumberSelector = (state) =>
  state.employees.editingEmployee.phoneNumber

export const editingEmployeePositionSelector = (state) =>
  state.employees.editingEmployee.position

export const editingEmployeeSalarySelector = (state) =>
  state.employees.editingEmployee.salary

export const editingEmployeeTeamSelector = (state) =>
  state.employees.editingEmployee?.team

export const editingEmployeeErrorSelector = (state) =>
  state.employees.editingEmployee.error

// -------- Get Single Employee Details -------- \\

export const employeeDetailsSelector = (state) =>
  state.employees.employeeDetails

export const isEmployeeDetailsLoadingSelector = (state) =>
  state.employees.isEmployeeDetailsLoading
