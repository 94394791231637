import React from "react"
import { QRCodeSVG } from "@cheprasov/qrcode"
import PropTypes from "prop-types"

import FIBQRCodeLogoIcon from "../../assets/images/qrcode/FIBLogo.svg"
import { formatAuthCode } from "../../lib/general"

const StaticQRCode = ({ source }) => {
  const qr = new QRCodeSVG(source, {
    level: "H",
    fgColor: "#24292e",
    bgColor: "transparent",
    image: {
      source: FIBQRCodeLogoIcon,
      width: 7,
      height: 7,
      x: "center",
      y: "center",
    },
  })

  return (
    <div className="static-qr-code">
      <div
        className="canvas"
        /* eslint-disable-next-line react/no-danger */
        dangerouslySetInnerHTML={{ __html: qr?.toString() }}
        data-testid="static-qr-code"
      />
      {source && (
        <p className="static-qr-code-source" data-testid="qrcode-source">
          {formatAuthCode(source)}
        </p>
      )}
    </div>
  )
}

StaticQRCode.propTypes = {
  source: PropTypes.string.isRequired,
}

export default StaticQRCode
