/**
 * @readonly
 * @enum {string}
 */
export const TEAM_DELETION_STATE = {
  IDLE: "IDLE",
  STARTED: "STARTED",
  AUTHORIZING: "AUTHORIZING",
  ABORTING: "ABORTING",
  REJECTED: "REJECTED",
  DELETED: "DELETED",
  ERRORED: "ERRORED",
}
