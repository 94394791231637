/**
 * Key to be used to indicate whether a user verified
 * after logged in with the `App` or the `PIN` or not.
 */
export const LOGIN_VERIFIED = "LOGIN_VERIFIED"

/**
 * This constant will be used as a minimum seconds remaining
 * for the keycloak `access token` to expire, we'll use it to
 * determine whether we refresh the access token or not.
 */
export const MIN_KC_TOKEN_VALIDITY = 15
