import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { QRCodeCanvas } from "@cheprasov/qrcode"
import { useTranslation } from "react-i18next"
import DetailsSidebar from "../../detailsSidebar/DetailsSidebar"
import StaticQRCode from "../../staticQRCode/StaticQRCode"
import { ReactComponent as DownloadIcon } from "../../../assets/icons/download.svg"
import ITServiceIcon from "../../../assets/icons/IT-service.svg"
import { MAP_BUSINESS_TYPE_TO_LOKALISE_KEY } from "../../../shared/constants/user"
import FIBQRCodeLogoIcon from "../../../assets/images/qrcode/FIBLogo.svg"
import { formatAuthCode, formatNumbers } from "../../../lib/general"
import useDir from "../../../shared/hooks/useDir"

const canvasConfig = {
  level: "H",
  padding: 20,
  scale: 12,
  fgColor: "#24292e",
  image: {
    source: FIBQRCodeLogoIcon,
    width: 6,
    height: 6,
    x: "center",
    y: "center",
  },
}

export const createCanvasQRCode =
  (setQRCodeCanvasLink, t, userData) => (canvas) => {
    const ctx = canvas.getContext("2d")
    ctx.font = "600 40px Inter"
    ctx.fillStyle = "#586069"
    ctx.textAlign = "center"
    ctx.fillText(userData.name, canvas.width / 2, 120)

    ctx.font = "400 30px Inter"
    ctx.fillStyle = "#959DA5"
    ctx.textAlign = "center"
    ctx.fillText(
      t(MAP_BUSINESS_TYPE_TO_LOKALISE_KEY[userData.businessCategory]),
      canvas.width / 2,
      160
    )

    ctx.font = "400 30px Inter"
    ctx.fillStyle = "#959DA5"
    ctx.textAlign = "center"
    ctx.fillText(
      userData.phoneDialingCode.concat(userData.phoneNumber),
      canvas.width / 2,
      200
    )

    ctx.font = "36px Inter"
    ctx.fillStyle = "#24292E"
    ctx.textAlign = "center"
    ctx.fillText(formatAuthCode(userData.readableId), canvas.width / 2, 605)

    const canvasContainer = document.createElement("canvas")
    const canvasContainerWidth = 500
    const canvasContainerHeight = 700
    const containerCtx = canvasContainer.getContext("2d")
    canvasContainer.width = canvasContainerWidth
    canvasContainer.height = canvasContainerHeight
    containerCtx.fillStyle = "white"
    containerCtx.fillRect(0, 0, canvasContainerWidth, canvasContainerHeight)
    containerCtx.drawImage(canvas, -44, 140, 600, 600)
    setQRCodeCanvasLink(canvasContainer.toDataURL())
  }

const UserQRCodeDetails = ({ userData }) => {
  const { t } = useTranslation()
  const { isRTL } = useDir()
  const [QRCodeCanvasLink, setQRCodeCanvasLink] = useState()

  useEffect(() => {
    const qrCanvas = new QRCodeCanvas(userData?.readableId, canvasConfig)
    let canvasWithQRCode = qrCanvas?.getCanvas()
    // istanbul ignore if
    if (canvasWithQRCode) {
      // istanbul ignore if
      if (typeof canvasWithQRCode.getContext === "function") {
        canvasWithQRCode = Promise.resolve(canvasWithQRCode)
      }
      canvasWithQRCode.then(
        createCanvasQRCode(setQRCodeCanvasLink, t, userData)
      )
    }
  }, [t, userData])

  return (
    <div className="user-qrcode-detail" data-testid="user-qrcode-detail">
      <section className="user-qrcode-detail__user-info">
        <img
          className={classNames("user-qrcode-detail__user-info-icon", {
            "user-qrcode-detail__user-info-fallback-icon": !userData.iconUrl,
          })}
          src={userData.iconUrl || ITServiceIcon}
          alt="user-icon"
          width={56}
          height={56}
          data-testid="details-sidebar-user-info-icon"
        />
        <h4
          className="user-qrcode-detail__user-info-name"
          data-testid="user-qrcode-detail-name"
        >
          {userData.name}
        </h4>
        <p
          className="user-qrcode-detail__user-info-business-category"
          data-testid="user-qrcode-detail-business-category"
        >
          {t(MAP_BUSINESS_TYPE_TO_LOKALISE_KEY[userData.businessCategory])}
        </p>
        <p
          className="user-qrcode-detail__user-info-phone-number plain-phone-number"
          data-testid="user-qrcode-detail-phone-number"
        >
          {userData.phoneDialingCode &&
            formatNumbers(
              userData.phoneDialingCode.concat(userData.phoneNumber),
              isRTL
            )}
        </p>
      </section>
      <DetailsSidebar.Section>
        <div className="qr-code__section">
          {userData.readableId && <StaticQRCode source={userData.readableId} />}
          <a
            href={QRCodeCanvasLink}
            download="static qrcode"
            className="button button--primary download-qrcode-button action-btn"
            data-testid="download-qrcode-button"
          >
            <DownloadIcon width={16} height={16} />
            <span>{t("web_c_staticqrcode_export_button")}</span>
          </a>
        </div>
      </DetailsSidebar.Section>
    </div>
  )
}

UserQRCodeDetails.propTypes = {
  userData: PropTypes.shape({
    iconUrl: PropTypes.string,
    name: PropTypes.string,
    role: PropTypes.string,
    businessCategory: PropTypes.string,
    readableId: PropTypes.string,
    phoneNumber: PropTypes.string,
    phoneDialingCode: PropTypes.string,
  }).isRequired,
}

export default UserQRCodeDetails
