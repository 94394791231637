import PropTypes from "prop-types"
import { useEffect, useMemo, useState } from "react"
import classNames from "classnames"
import { NavLink, useLocation, matchPath } from "react-router-dom"
import { NAVBAR_NAV_ICON_SIZE } from "../../shared/constants/icons"

import { ReactComponent as ChevronDownIcon } from "../../assets/icons/chevron.svg"
import { activeNavlinkClassNameBuilder } from "../../lib/general"

const NavlinkGroup = ({
  Icon,
  title,
  shouldCollapse,
  links,
  additionalLinks,
}) => {
  const [isOpen, setIsOpen] = useState(true)
  const { pathname } = useLocation()

  const matches = useMemo(
    () =>
      links
        .concat(...additionalLinks)
        .some((link) => matchPath(link.to, pathname) !== null),
    [additionalLinks, links, pathname]
  )

  const handleToggleNavlink = () => {
    if (!shouldCollapse) {
      setIsOpen((open) => !open)
    }
  }

  useEffect(() => {
    if (shouldCollapse) setIsOpen(false)
  }, [shouldCollapse])

  return (
    <div
      className={classNames("navlink-group", {
        "navlink-group--open": isOpen,
        "navlink-group--active": matches,
      })}
      data-testid="navlink-group"
      data-is-collapsed={!isOpen}
    >
      <button
        type="button"
        onClick={handleToggleNavlink}
        className="navlink-group-title"
        data-testid="navlink-group-title-btn"
        title={title}
      >
        <Icon
          className="navlink-group-icon"
          width={NAVBAR_NAV_ICON_SIZE}
          height={NAVBAR_NAV_ICON_SIZE}
          aria-label={title}
        />
        <span className="navlink-group-title-text">{title}</span>
        <ChevronDownIcon
          className="navlink-group-arrow"
          aria-label="expanding indicator"
          width={16}
          height={16}
        />
      </button>

      <div
        className="navlink-group-wrapper"
        data-is-collapsed={shouldCollapse}
        data-testid="navlink-group-wrapper"
      >
        {links.map(({ to, text }) => (
          <NavLink
            key={to}
            title={text}
            className={activeNavlinkClassNameBuilder("navlink", "navlink")}
            to={to}
            tabIndex={isOpen ? 0 : -1}
          >
            {text}
          </NavLink>
        ))}
      </div>
    </div>
  )
}

NavlinkGroup.defaultProps = {
  shouldCollapse: false,
  links: [],
  additionalLinks: [],
}

NavlinkGroup.propTypes = {
  Icon: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
  shouldCollapse: PropTypes.bool,
  /**
   * These links will be rendered as NavLinks for the user
   * to navigate through the app, also its used to match if
   * one of the links in this group is active or not.
   */
  links: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ),
  /**
   * These links are additional links that aren't directly rendered
   * in the navbar, but they refer to a nested route of one of the
   * direct child of the navbar, its used to match if one of the
   * links in this group is active or not.
   */
  additionalLinks: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string.isRequired,
    })
  ),
}

export default NavlinkGroup
