import AxiosInstance from "../axios"
import { getEmployeesParamsCreator } from "../endpointsHelper"

export const getEmployeesCSV = () =>
  AxiosInstance.get("/v1/corporate-users/me/employees/csv", {
    responseType: "blob",
  })

export const getEmployees = ({
  page,
  size,
  teamId,
  searchQuery,
  teamlessOnly,
}) => {
  const params = getEmployeesParamsCreator({
    page,
    size,
    teamId,
    searchQuery,
    teamlessOnly,
  })
  return AxiosInstance.get(`/v1/corporate-users/me/employees?${params}`)
}

export const deleteEmployee = (employeeId, config) =>
  AxiosInstance.delete(`/v1/corporate-users/me/employees/${employeeId}`, config)

export const checkingIfEmployeeHasFIB = ({
  dialingCode,
  phoneNumber,
  dateOfBirth,
}) =>
  AxiosInstance.post("/v1/personal-users/employee-data", {
    dialingCode,
    phoneNumber,
    dateOfBirth,
  })

export const addEmployee = (employeeInformation, config) =>
  AxiosInstance.post(
    "/v1/corporate-users/me/employees",
    employeeInformation,
    config
  )

export const editEmployee = (employeeInformation, config) =>
  AxiosInstance.put(
    `/v1/corporate-users/me/employees/${employeeInformation.employeeId}`,
    employeeInformation,
    config
  )

export const getEmployeeDetails = (employeeId) =>
  AxiosInstance.get(`/v1/corporate-users/me/employees/${employeeId}`)
