import PropTypes from "prop-types"

const Section = ({ header, children }) => (
  <section className="details-sidebar-body__section">
    {header && (
      <div className="details-sidebar-body__section-header">{header}</div>
    )}
    {children}
  </section>
)

Section.propTypes = {
  header: PropTypes.string,
  children: PropTypes.node.isRequired,
}

Section.defaultProps = {
  header: undefined,
}

export default Section
