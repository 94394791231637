import { getClientId } from "../../lib/authentication/auth-utils"
import AxiosInstance, { kcAxiosInstance } from "../axios"

/**
 *
 * @param {string} id
 * @param {import("axios").AxiosRequestConfig} config
 */
export const deleteUser = (id, config) =>
  AxiosInstance.delete(`/v1/corporate-users/me/agents/${id}`, config)

/**
 *
 * @param {object} data
 * @param {string} data.name
 * @param {string} data.dialingCode
 * @param {string} data.phoneNumber
 * @param {string} data.password
 * @param {import("axios").AxiosRequestConfig} config
 *
 * @returns {Promise<import("axios").AxiosResponse<{id: string}>>}
 */
export const createAgent = (data, config) =>
  AxiosInstance.post("/v1/corporate-users/me/agents", data, config)

/**
 * @param {string} instanceId
 * @param {import("axios").AxiosRequestConfig} config
 * @returns {Promise<import("axios").AxiosResponse<undefined>>}
 */
export const sendOTP = (instanceId, config) =>
  AxiosInstance.post("/v1/users/me/devices/send-otp", { instanceId }, config)

/**
 * @param {string} instanceId
 * @param {import("axios").AxiosRequestConfig} config
 * @returns {Promise<import("axios").AxiosResponse<{status: import("../../shared/constants/user").OTP_STATUS}>>}
 */
export const getInstanceOTPStatus = (instanceId, config) =>
  AxiosInstance.get(`/v1/users/me/devices/${instanceId}/status`, config)

/**
 *
 * @param {object} data
 * @param {string} data.instanceId
 * @param {string} data.otpCode
 * @param {import("axios").AxiosRequestConfig} config
 * @returns {Promise<import("axios").AxiosResponse<undefined>>}
 */
export const verifyOTP = (data, config) =>
  AxiosInstance.post("/v1/users/me/devices/verify-otp", data, config)

/**
 * @typedef {object} CheckPasswordStatusResponse
 * @property {import("../../shared/constants/user").PASSWORD_STATUS} action
 *
 * @param {import("axios").AxiosRequestConfig} config
 *
 * @returns {Promise<import("axios").AxiosResponse<CheckPasswordStatusResponse>>}
 */
export const getPasswordStatus = (config) =>
  AxiosInstance.get("/v1/users/me/password/status", config)

/**
 *
 * @param {object} data
 * @param {string} data.currentPassword
 * @param {string} data.newPassword
 * @param {string} data.confirmation
 *
 * @param {import("axios").AxiosRequestConfig} config
 * @returns {Promise<import("axios").AxiosResponse<undefined>>}
 */
export const updateUserPassword = (data, config) =>
  AxiosInstance.patch("/v1/users/me/password", data, config)

/**
 * @typedef GetUserDetails
 * @property {string} id
 * @property {string} name
 * @property {string} initials
 * @property {string} phoneNumber
 * @property {string} status
 * @property {object} role
 * @property {string} role.id
 * @property {string} role.name
 *
 * @param {string} id
 *
 * @returns { Promise<import("axios").AxiosResponse<GetUserDetails>> }
 */
export const getUserDetails = (id) =>
  AxiosInstance.get(`/v1/corporate-users/me/agents/${id}`)

/**
 * @typedef {object} CheckPinStatusResponse
 * @property {string} instanceId
 *
 * @param {import("axios").AxiosRequestConfig} config
 *
 * @returns {Promise<import("axios").AxiosResponse<CheckPinStatusResponse>>}
 */

export const getPinStatus = (config) =>
  AxiosInstance.get("/v1/users/me/web-device", config)

/**
 * @typedef {object} SetUserPinResponse
 * @property {string} instanceId
 *
 * @param {object} data
 * @param {string} data.elevatedPassword
 * @param {import("axios").AxiosRequestConfig} config
 *
 * @returns {Promise<import("axios").AxiosResponse<SetUserPinResponse>>}
 */

export const setUserPin = (data, config) =>
  AxiosInstance.post("/v1/users/me/web-device", data, config)

/**
 * @typedef {object} GetAccessTokenByVerifyingPinResponse
 * @property {string} access_token
 *
 * @param {string} instanceId
 * @param {string} pin
 *
 * @returns {Promise<import("axios").AxiosResponse<GetAccessTokenByVerifyingPinResponse>>}
 */

export const getElevatedTokenByVerifyingPin = ({ instanceId, pin }, config) => {
  const params = new URLSearchParams()
  params.append("grant_type", "password")
  params.append("client_id", getClientId())
  params.append("username", instanceId)
  params.append("password", pin)

  return kcAxiosInstance.post(
    "/realms/fib-corporate-elevated/protocol/openid-connect/token",
    params,
    config
  )
}

/**
 * @typedef {object} GetAgentRoleResponse
 * @property {string} id
 * @property {string} name
 * @property {string} corporateUserId
 * @property {"ACTIVE" | "DRAFT" | "DELETED"} status
 * @property {import("./roles").roleFeatures} features
 * @property {import("./roles").ApprovalThreshold} approvalThresholds
 *
 *
 * @param {import("axios").AxiosRequestConfig} config
 * @returns {Promise<import("axios").AxiosResponse<GetAgentRoleResponse>>}
 */
export const getAgentRole = (config) =>
  AxiosInstance.get("/v1/corporate-users/me/role", config)

/**
 *
 * @param {string} id
 * @param {import("axios").AxiosRequestConfig} config
 * @returns {Promise<import("axios").AxiosResponse<null>>}
 */
export const inactivateAgentStatus = (id, config) =>
  AxiosInstance.post(
    `/v1/corporate-users/me/agents/${id}/block`,
    undefined,
    config
  )

/**
 *
 * @param {string} id
 * @param {import("axios").AxiosRequestConfig} config
 * @returns {Promise<import("axios").AxiosResponse<null>>}
 */
export const activateAgentStatus = (id, config) =>
  AxiosInstance.post(
    `/v1/corporate-users/me/agents/${id}/unblock`,
    undefined,
    config
  )
