import {
  CURRENCY_TYPE_EUR,
  CURRENCY_TYPE_IQD,
  CURRENCY_TYPE_USD,
} from "../../../shared/constants/bank-accounts"

/**
 *
 * @param {import("../../../shared/constants/bank-accounts").CURRENCY_TYPE} currencyType
 * @param {boolean} isRTL
 */
export const mapCurrencyTypeToCurrencySymbol = (
  currencyType,
  isRTL = false
) => {
  switch (currencyType) {
    case CURRENCY_TYPE_IQD:
      return isRTL ? "د.ع" : "IQD"
    case CURRENCY_TYPE_USD:
      return "$"
    case CURRENCY_TYPE_EUR:
      return "€"
    default:
      throw new Error("Wrong currency Type")
  }
}

/**
 * @param {*[]} accounts
 * @param {import("../../../shared/constants/bank-accounts").CURRENCY_TYPE} currencyType
 * @returns the accountId of the balance account which it's currency type is
 * `currencyType` or undefined if there was no such accounts
 */
export const getCurrencyAccountId = (accounts, currencyType) =>
  accounts?.find(({ balance }) => balance.currency === currencyType)?.id
