import classNames from "classnames"
import PropTypes from "prop-types"

const SectionRow = ({ name, value, valueTestId, className }) => (
  <div className={classNames("details-sidebar-body__section-row", className)}>
    <div className="details-sidebar-body__section-row-name">{name}:</div>
    <div
      title={value}
      data-testid={valueTestId}
      className="details-sidebar-body__section-row-value"
    >
      {value}
    </div>
  </div>
)

SectionRow.propTypes = {
  className: PropTypes.string,
  valueTestId: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
}

SectionRow.defaultProps = {
  valueTestId: undefined,
  className: undefined,
}

export default SectionRow
