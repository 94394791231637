/**
 * Takes a blob of data and downloads it as a file named
 * using `fileName`
 * @param {Blob} data
 * @param {string} fileName
 */
export const downloadFile = (data, fileName) => {
  const url = window.URL.createObjectURL(data)
  const a = document.createElement("a")
  a.href = url
  a.download = fileName
  a.click()
}
