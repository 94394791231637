import classNames from "classnames"
import PropTypes from "prop-types"
import Row from "./Row"
import { ReactComponent as TriangleIcon } from "../../assets/icons/triangle-down.svg"
import { buildSortingClasses } from "../../lib/table"
import { ALIGN_END, ALIGN_START } from "../../shared/constants/table"

const Headers = ({ fields, setSorting, sortBy }) => (
  <thead>
    <Row>
      {fields.map((field) => {
        const preparedClasses = {
          "table-header--sortable": field.sortable,
          "table-header--start-aligned": field.align === ALIGN_START,
          "table-header--end-aligned": field.align === ALIGN_END,
          "table-header--center-aligned": field.align === undefined,
          ...buildSortingClasses("table-header", sortBy, field.name),
        }

        return (
          <th
            key={field.name}
            data-testid={field.name}
            className={classNames(
              "table-header",
              field.customClasses,
              preparedClasses
            )}
            onClick={
              field.sortable && setSorting ? setSorting(field.name) : undefined
            }
          >
            {field.title}
            <div className="sorting-indicator">
              <TriangleIcon
                width={16}
                height={16}
                className={classNames("sorting-indicator__up", {
                  "sorting-indicator__up--active":
                    field.sortable &&
                    field.name === sortBy.field &&
                    sortBy.ascending,
                })}
              />
              <TriangleIcon
                width={16}
                height={16}
                className={classNames("sorting-indicator__down", {
                  "sorting-indicator__down--active":
                    field.sortable &&
                    field.name === sortBy.field &&
                    !sortBy.ascending,
                })}
              />
            </div>
          </th>
        )
      })}
    </Row>
  </thead>
)

Headers.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      sortable: PropTypes.bool,
      name: PropTypes.string,
      align: PropTypes.oneOf([ALIGN_START, ALIGN_END]),
      title: PropTypes.string,
      customClasses: PropTypes.string,
    })
  ).isRequired,
  setSorting: PropTypes.func,
  sortBy: PropTypes.shape({
    field: PropTypes.string.isRequired,
    ascending: PropTypes.bool.isRequired,
  }),
}

Headers.defaultProps = {
  setSorting: undefined,
  sortBy: undefined,
}

export default Headers
