import classNames from "classnames"
import {
  useState,
  useMemo,
  useEffect,
  useRef,
  useLayoutEffect,
  lazy,
  Suspense,
} from "react"
import { useTranslation } from "react-i18next"
import { useSelector, useDispatch } from "react-redux"
import { useParams, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import useDir from "../../shared/hooks/useDir"

import SearchField from "../../components/searchField/SearchField"
import Headers from "../../components/table/Headers"
import Row from "../../components/table/Row"
import Cell from "../../components/table/Cell"
import NoData from "../../components/noData/NoData"
import Spinner from "../../components/spinner/Spinner"
import Pagination from "../../components/pagination/Pagination"
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs"

import { ReactComponent as DownloadIcon } from "../../assets/icons/download.svg"
import { ReactComponent as TrashbinIcon } from "../../assets/icons/trashbin.svg"
import { ReactComponent as EditPenIcon } from "../../assets/icons/edit-pen.svg"
import { ReactComponent as NoTeamMemberFound } from "../../assets/icons/no-data-found.svg"
import { ReactComponent as NoEmployeeIcon } from "../../assets/icons/no-employee-yet.svg"

import routes from "../../routes/routeDefinitions"
import {
  downloadTeamMembersCSV,
  refreshTeamsAndPushToBuilder,
  refreshTeamsBuilder,
} from "../../lib/teams/teams"

import {
  pageSizeSelector,
  isLastPageSelector,
  pageNumberSelector,
  totalPagesSelector,
  isFirstPageSelector,
  teamDetailsSelector,
  totalElementsSelector,
  teamMembersSelector,
  isTeamMembersLoadingSelector,
  isTeamDetailsLoadingSelector,
  teamMembersErrorSelector,
  teamDetailsErrorSelector,
} from "../../features/teams/team-selectors"
import {
  employeeDetailsSelector,
  isEmployeeDetailsLoadingSelector,
} from "../../features/employees/employee-selectors"
import {
  editingTeamStarted,
  fetchTeamDetails,
  teamDeletionStarted,
  fetchTeamMembers,
  teamMemberDeletionStarted,
  resetTeamDetails,
} from "../../features/teams/teams-slice"
import {
  getTeamDetailsBreadcrumbData,
  TEAM_DETAILS_COLUMNS,
  ACTION_BUTTONS_ICON_SIZE,
} from "../../shared/constants/teams/team-details"
import { ALIGN_START } from "../../shared/constants/table"
import { formatNumbers } from "../../lib/general"
import DetailsSidebar from "../../components/detailsSidebar/DetailsSidebar"
import {
  dismissEmployeeDetails,
  fetchEmployeeDetails,
} from "../../features/employees/employees-slice"
import EmployeeDetails from "../../components/sidebars/employeeDetails/EmployeeDetails"
import { NOT_FOUND, DEFAULT_TABLE_PARAMS } from "../../shared/constants/general"
import Toast from "../../components/toast/Toast"
import { TOAST_TYPE_ERROR } from "../../shared/constants/toast"
import {
  agentRoleFeaturesSelector,
  isMainAccountSelector,
} from "../../features/user/user-selectors"
import { permissionTypeBuilder } from "../../lib/agents/agent"
import { AGENT_FEATURES } from "../../shared/constants/agent"

const EditTeamProcess = lazy(() =>
  import("../../components/processes/teams/editTeam/EditTeamProcess")
)
const DeleteTeamProcess = lazy(() =>
  import("../../components/processes/teams/deleteTeam/DeleteTeamProcess")
)
const DeleteTeamMemberProcess = lazy(() =>
  import(
    "../../components/processes/teams/deleteTeamMember/DeleteTeamMemberProcess"
  )
)

const TeamDetails = () => {
  const employeeDetailsRequestRef = useRef()
  const tableContainerRef = useRef(null)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { teamId } = useParams()
  const navigate = useNavigate()
  const { isRTL } = useDir()
  const [search, setSearch] = useState("")
  const [page, setPage] = useState(0)
  const [isTableScrollable, setIsTableScrollable] = useState(false)
  const [windowResizedWidth, setWindowResizedWidth] = useState(null)
  const [isTableScrolledEnd, setIsTableScrolledEnd] = useState(false)
  const [isTableScrolledStart, setIsTableScrolledStart] = useState(true)

  const isFirstPage = useSelector(isFirstPageSelector)
  const isLastPage = useSelector(isLastPageSelector)
  const pageNumber = useSelector(pageNumberSelector)
  const pageSize = useSelector(pageSizeSelector)
  const totalElements = useSelector(totalElementsSelector)
  const totalPages = useSelector(totalPagesSelector)
  const teamDetails = useSelector(teamDetailsSelector)
  const teamDetailsError = useSelector(teamDetailsErrorSelector)
  const teamMembers = useSelector(teamMembersSelector)
  const currentEmployeeDetails = useSelector(employeeDetailsSelector)
  const isTeamMembersLoading = useSelector(isTeamMembersLoadingSelector)
  const teamMembersError = useSelector(teamMembersErrorSelector)
  const isTeamDetailsLoading = useSelector(isTeamDetailsLoadingSelector)
  const isEmployeeDetailsLoading = useSelector(isEmployeeDetailsLoadingSelector)
  const isMainAccount = useSelector(isMainAccountSelector)
  const agentRoleFeatures = useSelector(agentRoleFeaturesSelector)

  const teamsPermissions = useMemo(
    () =>
      permissionTypeBuilder(
        isMainAccount,
        agentRoleFeatures,
        AGENT_FEATURES.TEAMS
      ),
    [agentRoleFeatures, isMainAccount]
  )

  const preparedTeamsField = useMemo(() => {
    const permissions = {
      [AGENT_FEATURES.TEAMS]: teamsPermissions,
    }
    return TEAM_DETAILS_COLUMNS.filter(({ requiredPermissions }) =>
      requiredPermissions.every(
        ({ feature, permission }) => permissions[feature][permission]
      )
    ).map((column) => ({
      ...column,
      title: t(column.title),
    }))
  }, [t, teamsPermissions])

  const dispatchFetchTeamDetails = () => {
    dispatch(fetchTeamDetails(teamId))
  }

  const dispatchFetchTeamMembers = () => {
    if (teamDetails) {
      dispatch(
        fetchTeamMembers({
          ...DEFAULT_TABLE_PARAMS,
          teamId,
          page,
          searchQuery: search,
        })
      ).then(({ meta, payload }) => {
        if (
          meta.arg?.searchQuery.length === 0 &&
          payload?.data?.totalElements === 0
        )
          navigate(routes.teams.path)
      })
    }
  }

  // istanbul ignore next
  const handleTableResize = () => {
    setWindowResizedWidth(window.innerWidth)
    setIsTableScrolledEnd(false)
    setIsTableScrolledStart(tableContainerRef.current.scrollLeft === 0)
  }

  // istanbul ignore next
  const handleTableScroll = (e) => {
    const isScrolledEnd =
      e.target.scrollWidth - e.target.offsetWidth <=
      Math.ceil(e.target.scrollLeft)
    const isScrolledStart = e.target.scrollLeft === 0
    setIsTableScrolledEnd(isScrolledEnd)
    setIsTableScrolledStart(isScrolledStart)
  }

  useEffect(dispatchFetchTeamMembers, [
    teamDetails,
    dispatch,
    navigate,
    page,
    search,
    teamId,
  ])

  const onTeamDeletedSuccessfully = useMemo(
    () => refreshTeamsAndPushToBuilder(dispatch, navigate, routes.teams.path),
    [dispatch, navigate]
  )

  useEffect(dispatchFetchTeamDetails, [dispatch, teamId])

  useLayoutEffect(() => {
    if (
      teamDetailsError?.status === 404 ||
      // FIXME this should not expect 400 response status
      // after BE updated this, FE shall remove below condition
      teamDetailsError?.status === 400
    ) {
      const error = { ...teamDetailsError }
      error.code = NOT_FOUND
      throw error
    }
    return () => dispatch(resetTeamDetails())
  }, [dispatch, teamDetailsError])

  const onTeamModifiedSuccessfully = useMemo(
    () => refreshTeamsBuilder(dispatch),
    [dispatch]
  )

  useEffect(() => {
    tableContainerRef.current.scroll({ top: 0, left: 0, behavior: "smooth" })
  }, [teamMembers])

  const downloadCSV = async () => {
    try {
      await downloadTeamMembersCSV(teamId)
    } catch (err) {
      toast(
        <Toast
          text={t("web_c_teams_teametable_export_error_text")}
          type={TOAST_TYPE_ERROR}
        />
      )
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleTableResize)
    // istanbul ignore next
    setIsTableScrollable(
      tableContainerRef?.current?.scrollWidth >
        tableContainerRef?.current?.clientWidth
    )

    return () => window.removeEventListener("resize", handleTableResize)
  }, [windowResizedWidth, teamMembers])

  useEffect(
    () => () => {
      dispatch(dismissEmployeeDetails())
      const abort = employeeDetailsRequestRef.current?.abort
      if (typeof abort === "function") abort()
    },
    [dispatch]
  )

  return (
    <>
      <div
        className={classNames({
          "team-details-page--hidden": isTeamDetailsLoading,
        })}
      >
        <Breadcrumbs
          title={getTeamDetailsBreadcrumbData(teamDetails?.name).title}
          breadcrumbsList={
            getTeamDetailsBreadcrumbData(teamDetails?.name).breadcrumbsList
          }
        >
          {teamsPermissions.DELETE && (
            <button
              className="button button--outline-danger action-btn"
              data-testid="action-button-delete"
              type="button"
              onClick={() => {
                dispatch(
                  teamDeletionStarted({
                    id: teamId,
                    name: teamDetails?.name,
                  })
                )
              }}
            >
              <TrashbinIcon
                width={ACTION_BUTTONS_ICON_SIZE}
                height={ACTION_BUTTONS_ICON_SIZE}
              />
              <span className="action-btn__text">
                {t("web_c_teams_teamdetail_delete_button")}
              </span>
            </button>
          )}
          {teamsPermissions.EDIT && (
            <button
              className="button action-btn button--outline-secondary"
              data-testid="action-button-edit-team"
              type="button"
              onClick={() =>
                dispatch(
                  editingTeamStarted({
                    id: teamId,
                    name: teamDetails?.name,
                  })
                )
              }
            >
              <EditPenIcon
                width={ACTION_BUTTONS_ICON_SIZE}
                height={ACTION_BUTTONS_ICON_SIZE}
              />
              <span className="action-btn__text">
                {t("web_c_teams_teamdetail_edit_button")}
              </span>
            </button>
          )}
        </Breadcrumbs>
      </div>

      {teamsPermissions.DELETE && (
        <Suspense>
          <DeleteTeamProcess onSuccess={onTeamDeletedSuccessfully} />
        </Suspense>
      )}
      {teamsPermissions.EDIT && (
        <Suspense>
          <DeleteTeamMemberProcess
            onSuccess={() => {
              onTeamModifiedSuccessfully()
              dispatchFetchTeamDetails()
              dispatchFetchTeamMembers()
            }}
          />
          <EditTeamProcess
            onSuccess={() => {
              onTeamModifiedSuccessfully()
              dispatchFetchTeamDetails()
              dispatchFetchTeamMembers()
            }}
          />
        </Suspense>
      )}

      <DetailsSidebar
        title={t("web_c_employees_employeedetail_title")}
        shouldAppear={
          currentEmployeeDetails !== undefined || isEmployeeDetailsLoading
        }
        onClosed={() => dispatch(dismissEmployeeDetails())}
      >
        {currentEmployeeDetails !== undefined && (
          <EmployeeDetails employee={currentEmployeeDetails} />
        )}
        {currentEmployeeDetails === undefined && isEmployeeDetailsLoading && (
          <div className="spinner-container spinner-container--full spinner-container--delayed-250">
            <Spinner variant="medium" />
          </div>
        )}
      </DetailsSidebar>

      <div className="team-details-page">
        <div
          className={classNames("team-details-card", {
            "team-details-card--no-data":
              teamMembersError ||
              (isTeamDetailsLoading &&
                !search &&
                (teamMembers?.length === 0 || teamMembers === undefined)),
          })}
          data-testid="team-details-card"
        >
          <header className="team-details-card__header">
            <h2 className="card-title">
              {t("web_c_teams_teamdetail_teammember_label")}
            </h2>
            <button
              type="button"
              onClick={downloadCSV}
              className="export-button"
              data-testid="team-members-export"
            >
              <DownloadIcon
                height={18}
                width={18}
                data-testid="download-icon"
              />
              <span>{t("web_c_teams_teamdetail_export_button")}</span>
            </button>
          </header>

          <SearchField
            buttonLabel={t("web_c_teams_teamdetail_search_button")}
            placeholderLabel={t("web_c_teams_teamdetail_search_placeholder")}
            searchText={search}
            onSearchClick={setSearch}
            onEnter={setSearch}
          />
          <div
            className={classNames("team-members", {
              "team-members--no-data":
                isTeamMembersLoading ||
                teamMembers === undefined ||
                teamMembers.length === 0,
            })}
            data-testid="team-members"
          >
            <div
              id="team-members-table-container"
              className={classNames("table-container", {
                "table-container--last-column-fixed":
                  // istanbul ignore next
                  isTableScrollable && !isTableScrolledEnd,
                "table-container--first-column-fixed":
                  // istanbul ignore next
                  isTableScrollable && !isTableScrolledStart,
              })}
              ref={tableContainerRef}
              onScroll={handleTableScroll}
            >
              <table className="standard-table">
                <Headers fields={preparedTeamsField} />
                <tbody data-testid="table-body">
                  {teamMembers?.map((employee) => (
                    <Row
                      key={employee.id}
                      onClickHandler={() => {
                        employeeDetailsRequestRef.current = dispatch(
                          fetchEmployeeDetails(employee.id)
                        )
                      }}
                    >
                      <Cell>{employee.name}</Cell>
                      <Cell
                        customClasses="plain-phone-number"
                        align={ALIGN_START}
                      >
                        {formatNumbers(employee.phoneNumber, isRTL)}
                      </Cell>
                      <Cell>{employee.mainAccountIban} </Cell>
                      <Cell>{employee.position}</Cell>
                      <Cell>{employee.dateOfBirth}</Cell>
                      {teamsPermissions.EDIT && (
                        <Cell>
                          <button
                            type="button"
                            className="button button--outline-danger team-members__delete-button"
                            data-testid="delete-team-member-button"
                            onClick={(event) => {
                              event.stopPropagation()
                              dispatch(
                                teamMemberDeletionStarted({
                                  employeeId: employee.id,
                                  name: employee.name,
                                  teamId,
                                })
                              )
                            }}
                          >
                            <TrashbinIcon />
                          </button>
                        </Cell>
                      )}
                    </Row>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="table-container__separator" />
            <Pagination
              isFirst={isFirstPage}
              isLast={isLastPage}
              pageNumber={pageNumber + 1}
              pageSize={pageSize}
              setPage={setPage}
              totalElements={totalElements}
              totalPages={totalPages}
              isRTL={isRTL}
            />
          </div>
          {(isTeamMembersLoading || isTeamDetailsLoading) && (
            <div className="spinner-container spinner-container--full">
              <Spinner variant="medium" />
            </div>
          )}
          {!isTeamMembersLoading && search && teamMembers.length === 0 && (
            <div
              className="no-team-member-container"
              data-testid="no-data-container"
            >
              <NoData
                icon={<NoTeamMemberFound />}
                title={t("web_c_teams_teamdetail_noemployeefound_title")}
                text={t("web_c_teams_teamdetail_noemployeefound_text")}
              />
            </div>
          )}
        </div>

        {!isTeamDetailsLoading &&
          !isTeamMembersLoading &&
          !search &&
          (teamMembers?.length === 0 || teamMembers === undefined) && (
            <NoData
              icon={<NoEmployeeIcon />}
              title={t("web_c_employees_teams_teamdetail_noemployees_title")}
              text={t(
                "web_c_employees_teams_teamdetail_noemployees_description"
              )}
            />
          )}
      </div>
    </>
  )
}

export default TeamDetails
