import AxiosInstance from "../axios"
import { buildTeamsParams } from "../endpointsHelper"

export const getTeams = ({ page, size, searchQuery }) => {
  const params = buildTeamsParams({
    page,
    size,
    searchQuery,
  })
  return AxiosInstance.get(`/v1/corporate-users/me/teams?${params}`)
}

export const getTeamMembersCSV = (teamId) =>
  AxiosInstance.get(`/v1/corporate-users/me/employees/csv?teamId=${teamId}`, {
    responseType: "blob",
  })

/**
 * @typedef {object} TeamsAndEmployeesResponse
 * @property {object[]} teams
 * @property {string} teams.id
 * @property {string} teams.name
 * @property {object[]} teams.employees
 * @property {string} teams.employees.id
 * @property {string} teams.employees.name
 * @property {import("../../features/bankAccounts/bankAccounts-slice").MonetaryValue} teams.employees.salary
 *
 *
 * @param {string} [searchQuery]
 *
 * @returns { Promise<import("axios").AxiosResponse<TeamsAndEmployeesResponse>> }
 */
export const getTeamsAndEmployees = () =>
  AxiosInstance.get("/v1/corporate-users/me/teams-and-employees")

/**
 * @typedef GetTeamDetailsResponse
 * @property {string} id
 * @property {string} name
 * @property {string} initials
 * @property {object[]} employees
 * @property {string} employees.id
 * @property {string} employees.name
 * @property {string} employees.userId
 * @property {string} employees.position
 * @property {string} employees.phoneNumber
 * @property {string} employees.mainAccountId
 * @property {string} employees.mainAccountIban
 * @property {import("../../features/bankAccounts/bankAccounts-slice").MonetaryValue} employees.salary
 * @property {number} createdAt
 *
 *
 * @param {string} teamId
 * @returns { Promise<import("axios").AxiosResponse<GetTeamDetailsResponse>> }
 */
export const getTeamDetails = (teamId) =>
  AxiosInstance.get(`/v1/corporate-users/me/teams/${teamId}`)

/**
 * @typedef {array} TeamMemberResponse
 * @property {string} id
 * @property {string} name
 * @property {string} userId
 * @property {string} position
 * @property {string} phoneNumber
 * @property {string} mainAccountId
 * @property {string} mainAccountIban
 * @property {import("../../features/bankAccounts/bankAccounts-slice").MonetaryValue} teammember.salary
 * @property {number} createdAt
 * @property {object} team
 * @property {string} team.id
 * @property {string} team.name
 *
 * @param {string} teamId
 * @returns { Promise<import("axios").AxiosResponse<TeamMemberResponse>> }
 *
 * @description Get team members
 */
export const getTeamMembers = ({ page, size, teamId, searchQuery }) => {
  const params = new URLSearchParams()
  params.set("page", page)
  params.set("size", size)
  params.set("teamId", teamId)
  if (searchQuery) params.set("searchQuery", searchQuery)

  return AxiosInstance.get(`/v1/corporate-users/me/employees?${params}`)
}

export const addTeam = (teamInformation, config) =>
  AxiosInstance.post("/v1/corporate-users/me/teams", teamInformation, config)

export const updateTeam = (teamId, teamInformation, config) =>
  AxiosInstance.put(
    `/v1/corporate-users/me/teams/${teamId}`,
    teamInformation,
    config
  )

export const deleteTeam = (id, config) =>
  AxiosInstance.delete(`/v1/corporate-users/me/teams/${id}`, config)

export const deleteTeamMember = (employeeId, teamId, config) =>
  AxiosInstance.delete(
    `/v1/corporate-users/me/teams/${teamId}/employees/${employeeId}`,
    config
  )
