import React from "react"
import PropTypes from "prop-types"
import {
  CONFIRM_BOX_VARIANT_WARNING,
  CONFIRM_BOX_VARIANT_DANGER,
  CONFIRM_BOX_VARIANT_ACCESS_DENIED,
} from "../../shared/constants/confirm-box"
import { ReactComponent as FeaturedError } from "../../assets/icons/featured-error.svg"
import { ReactComponent as FeaturedWarning } from "../../assets/icons/featured-warning.svg"

const VARIANTS = [
  {
    name: CONFIRM_BOX_VARIANT_WARNING,
    icon: <FeaturedWarning width={50} height={50} data-testid="warning-svg" />,
    primaryBtnClass: "button--primary",
    secondaryBtnClass: "button--secondary",
  },
  {
    name: CONFIRM_BOX_VARIANT_DANGER,
    icon: <FeaturedError width={50} height={50} data-testid="danger-svg" />,
    primaryBtnClass: "button--danger button--danger-delete",
    secondaryBtnClass: "button--secondary",
  },
  {
    name: CONFIRM_BOX_VARIANT_ACCESS_DENIED,
    icon: <FeaturedError width={50} height={50} data-testid="danger-svg" />,
    primaryBtnClass: "button--primary",
  },
]

const ConfirmBox = ({ testId, variant, title, description, buttons }) => {
  const chosenVariant = VARIANTS.find(({ name }) => name === variant)

  return (
    <div className="confirm-box" data-testid={testId}>
      <div
        className="confirm-box__container"
        data-testid="confirm-box-container"
      >
        {chosenVariant.icon}
        <div className="confirm-box__title">{title}</div>
        <div className="confirm-box__body">{description}</div>
        <div className="confirm-box__buttons">
          {buttons.secondary && (
            <button
              type="button"
              className={`button confirm-box__button ${chosenVariant.secondaryBtnClass}`}
              onClick={buttons.secondary.clickHandler}
              data-testid="confirm-box-secondary-button"
            >
              {buttons.secondary.text}
            </button>
          )}
          {buttons.primary && (
            <button
              type="button"
              className={`button confirm-box__button ${chosenVariant.primaryBtnClass}`}
              onClick={buttons.primary.clickHandler}
              data-testid="confirm-box-primary-button"
            >
              {buttons.primary.text}
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

ConfirmBox.propTypes = {
  testId: PropTypes.string,
  variant: PropTypes.oneOf(VARIANTS.map((v) => v.name)).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttons: PropTypes.shape({
    primary: PropTypes.shape({
      text: PropTypes.string,
      clickHandler: PropTypes.func,
    }),
    secondary: PropTypes.shape({
      text: PropTypes.string,
      clickHandler: PropTypes.func,
    }),
  }).isRequired,
}

ConfirmBox.defaultProps = {
  testId: undefined,
}

export default ConfirmBox
