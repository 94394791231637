import * as yup from "yup"
import {
  passwordStrengthSchema,
  repeatedPasswordSchema,
} from "../../../lib/general"

/**
 * @readonly
 * @enum {string}
 */
export const UPDATING_USER_PASSWORD = {
  IDLE: "IDLE",
  STARTED: "STARTED",
  UPDATING: "UPDATING",
  UPDATED: "UPDATED",
}

export const informationStepSchema = yup.object({
  currentPassword: yup
    .string()
    .required("web_c_login_updatepasswordmodal_currentpassword_validation"),
  newPassword: passwordStrengthSchema,
  repeatedPassword: repeatedPasswordSchema,
})
