import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import {
  checkPhoneNumberExistence,
  fetchCurrentUser,
  getUsers,
} from "../../api/endpoints"
import {
  createAgent,
  deleteUser,
  getElevatedTokenByVerifyingPin,
  getInstanceOTPStatus,
  getPasswordStatus,
  getUserDetails,
  getPinStatus,
  sendOTP,
  setUserPin,
  updateUserPassword,
  verifyOTP,
  getAgentRole,
  activateAgentStatus,
  inactivateAgentStatus,
} from "../../api/endpoints/users"
import { httpStatusValidators } from "../../api/endpointsHelper"
import {
  getInstanceId,
  saveAuthenticationData,
} from "../../lib/authentication/auth-utils"
import Keycloak from "../../lib/authentication/keycloak"
import { getCurrentLocale } from "../../lib/i18n"
import {
  ACTIVE,
  GENERAL_ERROR,
  INACTIVE,
  IRAQ_DAILING_CODE,
} from "../../shared/constants/general"
import { ENGLISH_LOCALE } from "../../shared/constants/languages"
import {
  MAP_USER_ERROR_CODE_TO_MESSAGE,
  OTP_STATUS_VERIFIED,
  PASSWORD_CHANGE_REQUIRED,
  USER_GROUP_CORPORATE,
} from "../../shared/constants/user"
import { CHANGE_USER_ACTIVATION_STATUS_STATE } from "../../shared/constants/users/change-user-activation-status"
import { USER_CREATION_STATE } from "../../shared/constants/users/create-user"
import { USER_DELETION_STATE } from "../../shared/constants/users/delete-user"
import {
  INVALID_GRANT_ERROR,
  SETTING_AND_VERIFYING_USER_PIN_STATE,
  TEN_DAYS_IN_SECONDS,
} from "../../shared/constants/users/set-and-verify-user-pin"
import { UPDATING_USER_PASSWORD } from "../../shared/constants/users/update-user-password"

const SLICE_NAME = "user"

export const fetchMyDataPayloadCreator = async () => {
  const response = await fetchCurrentUser()
  const { data, status, statusText } = response
  return { data, status, statusText }
}

export const fetchMyData = createAsyncThunk(
  `${SLICE_NAME}/fetchMyData`,
  fetchMyDataPayloadCreator
)

export const userDeletion = createAsyncThunk(
  `${SLICE_NAME}/userDeletion`,
  async (_, thunkAPI) => {
    const { elevatedToken } = thunkAPI.getState().otac

    if (!elevatedToken)
      throw new Error("web_c_general_notauthorized_error_text")

    const { id } = thunkAPI.getState().user.userDeletion

    const { data, status, statusText } = await deleteUser(id, {
      headers: { Authorization: `Bearer ${elevatedToken}` },
    })
    return { data, status, statusText }
  }
)

export const userCreation = createAsyncThunk(
  `${SLICE_NAME}/userCreation`,
  async (_, thunkAPI) => {
    const { elevatedToken } = thunkAPI.getState().otac

    if (!elevatedToken) {
      throw new Error("web_c_general_notauthorized_error_text")
    }

    const { userConfig } = thunkAPI.getState().user.userCreation

    const {
      name,
      phoneNumber,
      password,
      role: { id },
    } = userConfig

    try {
      const response = await createAgent(
        {
          name,
          password,
          dialingCode: IRAQ_DAILING_CODE,
          phoneNumber,
          roleId: id,
        },
        {
          headers: { Authorization: `Bearer ${elevatedToken}` },
          validateStatus: httpStatusValidators.CREATED,
          signal: thunkAPI.signal,
        }
      )
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data)
    }
  }
)

export const userCreationCheckPhoneNumberExistence = createAsyncThunk(
  `${SLICE_NAME}/userCreationCheckingPhoneNumberExistence`,
  /**
   * @param {string} phoneNumber
   */
  async (phoneNumber, thunkAPI) => {
    try {
      const response = await checkPhoneNumberExistence(
        {
          dialingCode: IRAQ_DAILING_CODE,
          phoneNumber,
          userGroup: USER_GROUP_CORPORATE,
        },
        { validateStatus: httpStatusValidators.OK, signal: thunkAPI.signal }
      )

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data)
    }
  }
)

export const fetchUserDetails = createAsyncThunk(
  `${SLICE_NAME}/fetchUserDetails`,
  async (id) => {
    const { data, status, statusText } = await getUserDetails(id)
    return { data, status, statusText }
  }
)

export const fetchUsers = createAsyncThunk(
  `${SLICE_NAME}/fetchUsers`,
  async ({ size, page, withBlocked, searchQuery }) => {
    const { data, status, statusText } = await getUsers({
      size,
      page,
      withBlocked,
      searchQuery,
    })

    return { data, status, statusText }
  }
)

export const fetchAgentRole = createAsyncThunk(
  `${SLICE_NAME}/fetchAgentRole`,
  async (_, thunkAPI) => {
    try {
      const { data } = await getAgentRole({ signal: thunkAPI.signal })
      return { data }
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data)
    }
  }
)

export const requestingOTP = createAsyncThunk(
  `${SLICE_NAME}/requestingOTP`,
  async (_, thunkAPI) => {
    const { instanceId } = thunkAPI.getState().user
    try {
      await sendOTP(instanceId, { signal: thunkAPI.signal })
    } catch (error) {
      throw thunkAPI.rejectWithValue(error?.response?.data)
    }
  }
)

export const fetchInstanceOTPStatus = createAsyncThunk(
  `${SLICE_NAME}/fetchInstanceOTPStatus`,
  async (_, thunkAPI) => {
    const { instanceId } = thunkAPI.getState().user
    const { data } = await getInstanceOTPStatus(instanceId, {
      signal: thunkAPI.signal,
      validateStatus: httpStatusValidators.OK,
    })
    return { data }
  }
)

export const verifyingOTP = createAsyncThunk(
  `${SLICE_NAME}/verifyingOTP`,
  /**
   * @param {string} otpCode
   */
  async (otpCode, thunkAPI) => {
    // This instance ID which is created if there is no instance Id will be used here to be verified
    // during OTP Verification. Once verified it will be stored in Local Storage so that during refresh or opening another tab you're still verified and logged in.
    const { instanceId } = thunkAPI.getState().user
    try {
      const response = await verifyOTP(
        {
          instanceId,
          otpCode,
        },
        {
          signal: thunkAPI.signal,
          validateStatus: httpStatusValidators.OK,
        }
      )

      const { data } = response
      return { data }
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data)
    }
  }
)

export const checkingPasswordStatus = createAsyncThunk(
  `${SLICE_NAME}/checkingPasswordStatus`,
  async (_, thunkAPI) => {
    try {
      // We provide seconds as big as 10 days which is obviosuly
      // bigger than the access token expiry date, so keycloak will
      // update the token, because there is a chance that the roles
      // of the user has changed especially after Verify OTP.
      await Keycloak.getInstance().updateToken(TEN_DAYS_IN_SECONDS)
      saveAuthenticationData(Keycloak.getInstance())
      const { data } = await getPasswordStatus({ signal: thunkAPI.signal })
      return { data }
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data)
    }
  }
)

export const updatingUserPassword = createAsyncThunk(
  `${SLICE_NAME}/updatingUserPassword`,
  /**
   * @type {import("@reduxjs/toolkit").AsyncThunkPayloadCreator<>}
   * @returns
   */
  async ({ currentPassword, newPassword, confirmation }, thunkAPI) => {
    try {
      await updateUserPassword(
        { currentPassword, newPassword, confirmation },
        {
          signal: thunkAPI.signal,
        }
      )
    } catch (error) {
      const preparedError = {
        code: GENERAL_ERROR,
        message: "web_c_general_error_massage",
      }

      if (Array.isArray(error?.response?.data?.errors)) {
        const [firstError] = error.response.data.errors
        preparedError.code = firstError.code
        preparedError.message = firstError.detail
      }

      throw thunkAPI.rejectWithValue(preparedError)
    }
  }
)

export const checkingPinStatus = createAsyncThunk(
  `${SLICE_NAME}/checkingPinStatus`,
  async (_, thunkAPI) => {
    // This will check if the pin is already set or not, it will give you back and instance ID
    // which is different from the instance ID that is created and verified during OTP Verification.
    // This one is used to verify the pin when they login that an agent has already set. And it will be used to verify the pin that is required when performing sensitive actions.
    try {
      await Keycloak.getInstance().updateToken(TEN_DAYS_IN_SECONDS)
      saveAuthenticationData(Keycloak.getInstance())
      const { data } = await getPinStatus({ signal: thunkAPI.signal })
      return { data }
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data)
    }
  }
)

export const settingUserPin = createAsyncThunk(
  `${SLICE_NAME}/settingUserPin`,
  /**
   * @type {import("@reduxjs/toolkit").AsyncThunkPayloadCreator<>}
   * @returns
   */
  async ({ elevatedPassword }, thunkAPI) => {
    try {
      await setUserPin(
        { elevatedPassword },
        {
          signal: thunkAPI.signal,
        }
      )
    } catch (error) {
      const preparedError = {
        code: GENERAL_ERROR,
        message: "web_c_general_error_massage",
      }

      if (Array.isArray(error?.response?.data?.errors)) {
        const [firstError] = error.response.data.errors
        preparedError.code = firstError.code
        preparedError.message = firstError.detail
      }

      throw thunkAPI.rejectWithValue(preparedError)
    }
  }
)

export const fetchElevatedTokenByVerifyingPin = createAsyncThunk(
  `${SLICE_NAME}/fetchElevatedTokenByVerifyingPin`,
  /**
   * @type {import("@reduxjs/toolkit").AsyncThunkPayloadCreator<>}
   */
  async ({ instanceId, enteredPin: pin }, thunkAPI) => {
    try {
      const { data, status, statusText } = await getElevatedTokenByVerifyingPin(
        { instanceId, pin },
        {
          signal: thunkAPI.signal,
        }
      )
      return { data, status, statusText }
    } catch (error) {
      const preparedError = {
        code: GENERAL_ERROR,
        message: "web_c_general_error_massage",
      }
      if (error?.response?.data?.error) {
        preparedError.code = error.response.data.error
        preparedError.message = error.response.data.error_description
      }

      throw thunkAPI.rejectWithValue(preparedError)
    }
  }
)

export const changeUserActivationStatus = createAsyncThunk(
  `${SLICE_NAME}/changeUserActivationStatus`,
  async (_, { getState, signal, rejectWithValue }) => {
    const { elevatedToken } = getState().otac
    if (!elevatedToken) {
      throw new Error("web_c_general_notauthorized_error_text")
    }
    const { id, status } = getState().user.changeUserActivationStatus

    try {
      /**
       * @type {import("axios").AxiosRequestConfig}
       */
      const requestConfig = {
        signal,
        headers: { Authorization: `Bearer ${elevatedToken}` },
      }

      if (status === ACTIVE) {
        await inactivateAgentStatus(id, requestConfig)
      }
      if (status === INACTIVE) {
        await activateAgentStatus(id, requestConfig)
      }
    } catch (error) {
      throw rejectWithValue(error.response.data)
    }
  }
)

export const initialState = {
  /**
   * users is an array of objects that holds the
   * informations for each user
   * @type {import("../../api/endpoints").User[]}
   */
  users: undefined,

  /**
   * represents the loading state of the users
   * @type {boolean}
   */
  isUsersLoading: false,

  /**
   * represents the error state of users if any
   * @type {import("@reduxjs/toolkit").SerializedError}
   */
  usersError: undefined,

  /**
   * defined the current state of the table
   * and empowering the pagination of the page
   */
  isFirst: false,
  isLast: false,
  pageNumber: undefined,
  pageSize: undefined,
  totalElements: undefined,
  totalPages: undefined,

  name: undefined,
  role: undefined,
  iconUrl: undefined,
  businessCategory: undefined,
  isMainAccount: undefined,
  readableId: undefined,
  phoneNumber: undefined,
  dialingCode: undefined,
  locale: JSON.parse(getCurrentLocale()) || ENGLISH_LOCALE,
  isNavbarCollapsed: false,
  isOTPVerifying: false,
  isOTPVerified: undefined,
  checkingIsOTPVerified: false,
  instanceId: getInstanceId(),
  isOTPRequested: undefined,
  otpValidUntil: undefined,
  isRequestedOTPExpired: undefined,
  isRequestingOTP: false,
  remainingOTPTrials: undefined,
  otpError: undefined,
  isPasswordUpdateRequired: undefined,
  isPasswordStatusLoading: false,
  passwordStatusError: undefined,
  isSettingPinRequired: undefined,
  isPinVerified: undefined,
  isPinStatusLoading: undefined,
  pinStatusError: undefined,
  pinVerificationInstanceId: undefined,
  isAgentRoleLoading: false,
  agentRoleFeatures: undefined,
  agentRoleApprovalThresholds: undefined,
  agentRoleName: undefined,
  agentRoleError: undefined,

  /**
   * @typedef CreateUserState
   * @property {string} state
   * @property {object} userConfig
   * @property {string=} userConfig.phoneNumber
   * @property {string=} userConfig.name
   * @property {string=} userConfig.password
   * @property {string=} userConfig.repeatedPassword
   * @property {string=} userConfig.role
   * @property {boolean} isConfirmed - whether the process confirmed to be authorized or not.
   * @property {boolean} isPhoneNumberUsedLoading
   * @property {boolean=} isPhoneNumberUsed
   * @property {import("@reduxjs/toolkit").SerializedError} error
   */

  /**
   * @type {CreateUserState}
   */
  userCreation: {
    state: USER_CREATION_STATE.IDLE,
    userConfig: {
      phoneNumber: undefined,
      name: undefined,
      password: undefined,
      repeatedPassword: undefined,
      role: {
        id: undefined,
        text: undefined,
      },
    },
    isConfirmed: false,
    isPhoneNumberUsed: undefined,
    isPhoneNumberUsedLoading: false,
    error: undefined,
  },

  /**
   * @typedef UserDeletion
   * @property {import("../../shared/constants/users/delete-user").UserDeletionState} state
   * @property {string=} id
   * @property {string=} name
   * @property {import("@reduxjs/toolkit").SerializedError} error
   */

  /**
   * @type {UserDeletion}
   */
  userDeletion: {
    state: USER_DELETION_STATE.IDLE,
    id: undefined,
    name: undefined,
    error: undefined,
  },

  /**
   * @typedef ChangeUserActivationStatus
   * @property {import(
   *   "../../shared/constants/users/change-user-activation-status"
   * ).ChangeUserActivationStatusState} state
   * @property {string} id
   * @property {string} name
   * @property {"ACTIVE" | "INACTIVE"} status
   * @property {import("@reduxjs/toolkit").SerializedError} error
   */

  /**
   * @type {ChangeUserActivationStatus}
   */
  changeUserActivationStatus: {
    state: CHANGE_USER_ACTIVATION_STATUS_STATE.IDLE,
    id: undefined,
    name: undefined,
    status: undefined,
    error: undefined,
  },

  updatingUserPassword: {
    state: UPDATING_USER_PASSWORD.IDLE,
  },

  /**
   * @type { import("../../api/endpoints/users").GetUserDetails }
   */
  userDetails: undefined,

  /**
   * @type {boolean}
   */
  isUserDetailsLoading: false,

  /**
   * @type {import("@reduxjs/toolkit").SerializedError}
   */
  userDetailsError: undefined,
  settingAndVerifyingUserPin: {
    state: SETTING_AND_VERIFYING_USER_PIN_STATE.IDLE,
    pin: undefined,
  },
}

const counterSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    languageChangedTo: (state, action) => {
      state.locale = action.payload
    },
    navbarToggled: (state) => {
      state.isNavbarCollapsed = !state.isNavbarCollapsed
    },
    /**
     * @param {import("@reduxjs/toolkit").PayloadAction<boolean | null>} action
     */
    mainAccountSet: (state, action) => {
      state.isMainAccount = action.payload
    },
    /**
     * @param {import("@reduxjs/toolkit").PayloadAction<string>} action
     */
    instanceIdSet: (state, action) => {
      state.instanceId = action.payload
    },
    otpErrorCleared: (state) => {
      state.otpError = undefined
    },
    requestedOTPExpired: (state) => {
      state.isRequestedOTPExpired = true
    },
    // -------- delete user -------- \\
    /**
     * @param {import("@reduxjs/toolkit").PayloadAction<{
     *         id: string,
     *         name: string,
     * }>} action
     */
    userDeletionStarted: (state, action) => {
      state.userDeletion.state = USER_DELETION_STATE.STARTED
      state.userDeletion.id = action.payload.id
      state.userDeletion.name = action.payload.name
    },
    userDeletionRejected: (state) => {
      state.userDeletion.state = USER_DELETION_STATE.REJECTED
    },
    userDeletionAuthorizing: (state) => {
      state.userDeletion.state = USER_DELETION_STATE.AUTHORIZING
    },
    userDeletionAborting: (state) => {
      state.userDeletion.state = USER_DELETION_STATE.ABORTING
    },
    userDeletionFinished: (state) => {
      state.userDeletion = initialState.userDeletion
    },
    // -------- change user activation status -------- \\
    changeUserActivationStatusStarted: (state, action) => {
      state.changeUserActivationStatus.state =
        CHANGE_USER_ACTIVATION_STATUS_STATE.STARTED
      state.changeUserActivationStatus.id = action.payload.id
      state.changeUserActivationStatus.name = action.payload.name
      state.changeUserActivationStatus.status = action.payload.status
    },
    changeUserActivationStatusRejected: (state) => {
      state.changeUserActivationStatus.state =
        CHANGE_USER_ACTIVATION_STATUS_STATE.REJECTED
    },
    changeUserActivationStatusAuthorizing: (state) => {
      state.changeUserActivationStatus.state =
        CHANGE_USER_ACTIVATION_STATUS_STATE.AUTHORIZING
    },
    changeUserActivationStatusAborting: (state) => {
      state.changeUserActivationStatus.state =
        CHANGE_USER_ACTIVATION_STATUS_STATE.ABORTING
    },
    changeUserActivationStatusFinished: (state) => {
      state.changeUserActivationStatus = initialState.changeUserActivationStatus
    },
    // -------- create user -------- \\
    userCreationStarted: (state) => {
      state.userCreation.state = USER_CREATION_STATE.STARTED
    },
    /**
     * @typedef CreateUserInformationSet
     * @property {string} name
     * @property {string} password
     * @property {string} repeatedPassword
     * @property {string} phoneNumber
     * @property {string} role
     * @param {import("@reduxjs/toolkit").PayloadAction<CreateUserInformationSet>} action
     */
    userCreationInformationSet: (state, action) => {
      state.userCreation.state = USER_CREATION_STATE.INFORMATION_SET
      const { name, password, repeatedPassword, phoneNumber, role } =
        action.payload

      state.userCreation.userConfig.name = name
      state.userCreation.userConfig.password = password
      state.userCreation.userConfig.repeatedPassword = repeatedPassword
      state.userCreation.userConfig.phoneNumber = phoneNumber
      state.userCreation.userConfig.role = role
    },
    userCreationConfirmation: (state) => {
      state.userCreation.state = USER_CREATION_STATE.CONFIRMATION
      state.userCreation.isConfirmed = false
      state.userCreation.error = undefined
    },
    userCreationAuthorizing: (state) => {
      state.userCreation.state = USER_CREATION_STATE.AUTHORIZING
      state.userCreation.isConfirmed = true
    },
    userCreationAborting: (state) => {
      state.userCreation.state = USER_CREATION_STATE.ABORTING
    },
    userCreationRejected: (state) => {
      state.userCreation.state = USER_CREATION_STATE.REJECTED
    },
    userCreationFinished: (state) => {
      state.userCreation = initialState.userCreation
    },

    // -------- verifying OTP code -------- \\
    setOTPValidUntil: (state, action) => {
      state.otpValidUntil = action.payload.date
    },
    setIsOTPValidForNewRequest: (state, action) => {
      state.isOTPRequested = action.payload.isOTPRequested
      state.isRequestedOTPExpired = action.payload.isRequestedOTPExpired
    },

    // -------- updating user password -------- \\
    updatingUserPasswordStarted: (state) => {
      state.updatingUserPassword.state = UPDATING_USER_PASSWORD.STARTED
    },
    updatingUserPasswordFinished: (state) => {
      state.updatingUserPassword = initialState.updatingUserPassword
    },

    // -------- setting and verifying user pin -------- \\
    storeUserPin: (state, action) => {
      state.settingAndVerifyingUserPin.pin = action.payload
    },
    settingAndVerifyingUserPinConfirming: (state) => {
      state.settingAndVerifyingUserPin.state =
        SETTING_AND_VERIFYING_USER_PIN_STATE.CONFIRMING
    },

    settingAndVerifyingUserPinVerifying: (state) => {
      state.settingAndVerifyingUserPin.state =
        SETTING_AND_VERIFYING_USER_PIN_STATE.VERIFYING
    },

    settingAndVerifyingUserPinStarted: (state) => {
      state.settingAndVerifyingUserPin.state =
        SETTING_AND_VERIFYING_USER_PIN_STATE.STARTED
    },
    settingAndVerifyingUserPinFinished: (state) => {
      state.settingAndVerifyingUserPin = initialState.settingAndVerifyingUserPin
    },

    // -------- user details -------- \\
    resetUserDetail: (state) => {
      state.userDetails = initialState.userDetails
      state.userDetailsError = initialState.userDetailsError
    },
  },
  extraReducers: (builder) => {
    // -------- fetch users -------- \\
    builder.addCase(fetchUsers.pending, (state) => {
      state.isUsersLoading = true
    })

    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.isUsersLoading = false
      state.usersError = undefined
      state.users = action.payload.data.content
      state.isFirst = action.payload.data.first
      state.isLast = action.payload.data.last
      state.pageNumber = action.payload.data.pageNumber
      state.pageSize = action.payload.data.pageSize
      state.totalElements = action.payload.data.totalElements
      state.totalPages = action.payload.data.totalPages
    })

    builder.addCase(fetchUsers.rejected, (state, action) => {
      state.isUsersLoading = false
      state.usersError = action.error
    })

    // -------- fetch agent role -------- \\
    builder.addCase(fetchAgentRole.pending, (state) => {
      state.isAgentRoleLoading = true
    })
    builder.addCase(fetchAgentRole.fulfilled, (state, action) => {
      state.isAgentRoleLoading = false
      state.agentRoleFeatures = action.payload.data.features
      state.agentRoleApprovalThresholds = action.payload.data.approvalThresholds
      state.agentRoleName = action.payload.data.name
    })
    builder.addCase(fetchAgentRole.rejected, (state, action) => {
      state.isAgentRoleLoading = false

      const preparedError = {
        code: GENERAL_ERROR,
        message: "web_c_general_error_massage",
      }

      if (Array.isArray(action?.payload?.errors)) {
        const [firstError] = action.payload.errors
        preparedError.code = firstError.code
        preparedError.message = firstError.detail
      }

      state.agentRoleError = preparedError
    })

    // -------- otp procedures -------- \\

    builder.addCase(requestingOTP.pending, (state) => {
      state.isRequestingOTP = true
      state.otpValidUntil = undefined
      state.isRequestedOTPExpired = undefined
    })

    builder.addCase(requestingOTP.fulfilled, (state) => {
      state.isRequestingOTP = false
      state.isOTPRequested = true
      state.isRequestedOTPExpired = false
      state.otpValidUntil = Date.now() + 60 * 1000
      state.otpError = undefined
    })

    builder.addCase(requestingOTP.rejected, (state, action) => {
      state.isRequestingOTP = false

      const error = {
        code: GENERAL_ERROR,
        message: "web_c_general_error_massage",
      }

      if (action.meta.rejectedWithValue) {
        if (Array.isArray(action?.payload?.errors)) {
          const [firstError] = action.payload.errors
          const { code, detail } = firstError
          error.code = code
          error.message = detail
        }
      }

      state.isOTPRequested = false
      state.otpError = error
    })

    builder.addCase(fetchInstanceOTPStatus.pending, (state) => {
      state.checkingIsOTPVerified = true
    })

    builder.addCase(fetchInstanceOTPStatus.fulfilled, (state, action) => {
      state.checkingIsOTPVerified = false
      state.isOTPVerified = action.payload.data.status === OTP_STATUS_VERIFIED
    })

    builder.addCase(fetchInstanceOTPStatus.rejected, (state) => {
      state.checkingIsOTPVerified = false
      state.isOTPVerified = false
      state.otpError = {
        code: GENERAL_ERROR,
        message: "web_c_general_error_massage",
      }
    })

    builder.addCase(verifyingOTP.pending, (state) => {
      state.isOTPVerifying = true
    })

    builder.addCase(verifyingOTP.fulfilled, (state) => {
      state.isOTPVerifying = false
      state.otpError = undefined
    })

    builder.addCase(verifyingOTP.rejected, (state, action) => {
      const error = {
        code: GENERAL_ERROR,
        message: "web_c_general_error_massage",
      }

      if (Array.isArray(action?.payload?.errors)) {
        const [firstError] = action.payload.errors
        const { code, detail, additionalInformation } = firstError
        error.code = code
        error.message = detail

        const remainingOtpTrials = additionalInformation?.remainingOtpTrials

        if (Number.isInteger(remainingOtpTrials)) {
          state.remainingOTPTrials = remainingOtpTrials
        }

        if (code === "OTP_TRIALS_LIMIT_REACHED") {
          state.otpValidUntil = undefined
          state.isRequestedOTPExpired = true
        }
      }

      state.isOTPVerifying = false
      state.otpError = error
    })

    // -------- update password process -------- \\

    builder.addCase(checkingPasswordStatus.pending, (state) => {
      state.isPasswordStatusLoading = true
      state.isPasswordUpdateRequired = undefined
    })
    builder.addCase(checkingPasswordStatus.fulfilled, (state, action) => {
      const passwordAction = action.payload.data.action
      const isUpdateRequired = passwordAction === PASSWORD_CHANGE_REQUIRED

      state.isPasswordStatusLoading = false
      state.isPasswordUpdateRequired = isUpdateRequired

      if (isUpdateRequired) {
        state.updatingUserPassword.state = UPDATING_USER_PASSWORD.STARTED
      }
    })
    builder.addCase(checkingPasswordStatus.rejected, (state, action) => {
      const preparedError = {
        code: GENERAL_ERROR,
        message: "web_c_general_error_massage",
      }

      if (Array.isArray(action?.payload?.errors)) {
        const [firstError] = action.payload.errors
        preparedError.code = firstError.code
        preparedError.message = firstError.detail
      }

      state.isPasswordStatusLoading = false
      state.passwordStatusError = preparedError
    })

    builder.addCase(updatingUserPassword.pending, (state) => {
      state.updatingUserPassword.state = UPDATING_USER_PASSWORD.UPDATING
    })
    builder.addCase(updatingUserPassword.fulfilled, (state) => {
      state.updatingUserPassword.state = UPDATING_USER_PASSWORD.UPDATED
    })
    builder.addCase(updatingUserPassword.rejected, (state) => {
      state.updatingUserPassword.state = UPDATING_USER_PASSWORD.STARTED
    })

    builder.addCase(fetchMyData.pending, (state) => {
      state.name = undefined
      state.role = undefined
      state.iconUrl = undefined
      state.businessCategory = undefined
      state.readableId = undefined
      state.phoneNumber = undefined
      state.dialingCode = undefined
    })

    builder.addCase(fetchMyData.fulfilled, (state, action) => {
      state.name = action.payload.data.name
      // TODO currently there is no such thing as user role
      // so we'll be using user type instead, and will change
      // it to user role when support added by BE.
      state.role = action.payload.data.type
      state.iconUrl = action.payload.data.iconUrl
      state.businessCategory = action.payload.data.businessCategory
      state.readableId = action.payload.data.readableId
      state.phoneNumber = action.payload.data.phoneNumber
      state.dialingCode = action.payload.data.phoneDialingCode
    })

    builder.addCase(userDeletion.fulfilled, (state) => {
      state.userDeletion.state = USER_DELETION_STATE.DELETED
    })
    builder.addCase(userDeletion.rejected, (state, action) => {
      state.userDeletion.state = USER_DELETION_STATE.ERRORED
      state.userDeletion.error = action.error
    })

    // -------- set and verify pin process -------- \\

    builder.addCase(checkingPinStatus.pending, (state) => {
      state.isPinStatusLoading = true
    })
    builder.addCase(checkingPinStatus.fulfilled, (state, action) => {
      state.isSettingPinRequired = false
      state.isPinStatusLoading = false
      state.isPinVerified = false
      state.pinVerificationInstanceId = action.payload.data?.instanceId
      state.settingAndVerifyingUserPin.state =
        SETTING_AND_VERIFYING_USER_PIN_STATE.VERIFYING
    })
    builder.addCase(checkingPinStatus.rejected, (state, action) => {
      const preparedError = {
        code: GENERAL_ERROR,
        message: "web_c_general_error_massage",
      }

      if (Array.isArray(action?.payload?.errors)) {
        const [firstError] = action.payload.errors
        preparedError.code = firstError.code
        preparedError.message = firstError.detail
      }

      state.isPinStatusLoading = false
      state.isSettingPinRequired = true

      if (preparedError.code === "WEB_DEVICE_NOT_FOUND") {
        state.settingAndVerifyingUserPin.state =
          SETTING_AND_VERIFYING_USER_PIN_STATE.STARTED
      } else {
        state.pinStatusError = preparedError
        state.settingAndVerifyingUserPin.state =
          SETTING_AND_VERIFYING_USER_PIN_STATE.ERRORED
      }
    })

    builder.addCase(settingUserPin.pending, (state) => {
      state.settingAndVerifyingUserPin.state =
        SETTING_AND_VERIFYING_USER_PIN_STATE.SETTING
    })
    builder.addCase(settingUserPin.fulfilled, (state) => {
      state.settingAndVerifyingUserPin.state =
        SETTING_AND_VERIFYING_USER_PIN_STATE.SET
    })
    builder.addCase(settingUserPin.rejected, (state) => {
      state.settingAndVerifyingUserPin.state =
        SETTING_AND_VERIFYING_USER_PIN_STATE.ERRORED
    })

    builder.addCase(fetchElevatedTokenByVerifyingPin.pending, (state) => {
      state.pinStatusError = undefined
      state.settingAndVerifyingUserPin.state =
        SETTING_AND_VERIFYING_USER_PIN_STATE.LOADING
    })
    builder.addCase(fetchElevatedTokenByVerifyingPin.fulfilled, (state) => {
      state.pinStatusError = undefined
      state.isPinVerified = true
      state.settingAndVerifyingUserPin.state =
        SETTING_AND_VERIFYING_USER_PIN_STATE.VERIFIED
    })
    builder.addCase(
      fetchElevatedTokenByVerifyingPin.rejected,
      (state, action) => {
        state.pinStatusError = action.payload

        if (action.payload.code === INVALID_GRANT_ERROR) {
          state.isPinVerified = false
          state.settingAndVerifyingUserPin.state =
            SETTING_AND_VERIFYING_USER_PIN_STATE.VERIFYING
        } else {
          state.settingAndVerifyingUserPin.state =
            SETTING_AND_VERIFYING_USER_PIN_STATE.ERRORED
        }
      }
    )

    // -------- create user -------- \\
    builder.addCase(userCreation.pending, (state) => {
      state.userCreation.state = USER_CREATION_STATE.CREATING
    })
    builder.addCase(userCreation.fulfilled, (state) => {
      state.userCreation.state = USER_CREATION_STATE.CREATED
    })
    builder.addCase(userCreation.rejected, (state, action) => {
      if (action.meta.aborted) return

      const error = {
        code: action.error.code,
        name: action.error.name,
        message: action.error.message,
      }

      if (
        action.meta.rejectedWithValue &&
        Array.isArray(action?.payload?.errors)
      ) {
        const [firstError] = action.payload.errors
        error.code = firstError.code
        error.name = firstError.title
        // FIXME the error detail should always be returned
        // when Backend adds the detail this should be removed
        // and then -> error.message = firstError.detail
        const errorMessage =
          MAP_USER_ERROR_CODE_TO_MESSAGE[firstError.code] === undefined
            ? "web_c_general_error_massage"
            : MAP_USER_ERROR_CODE_TO_MESSAGE[firstError.code]

        error.message =
          firstError.detail === "" ? errorMessage : firstError.detail
      }

      state.userCreation.state = USER_CREATION_STATE.ERRORED
      state.userCreation.error = error
    })
    builder.addCase(userCreationCheckPhoneNumberExistence.pending, (state) => {
      state.userCreation.isPhoneNumberUsedLoading = true
      state.userCreation.isPhoneNumberUsed =
        initialState.userCreation.isPhoneNumberUsed
    })
    builder.addCase(
      userCreationCheckPhoneNumberExistence.fulfilled,
      (state, action) => {
        state.userCreation.isPhoneNumberUsedLoading = false
        state.userCreation.isPhoneNumberUsed = action.payload.exists
      }
    )
    builder.addCase(
      userCreationCheckPhoneNumberExistence.rejected,
      (state, action) => {
        state.userCreation.isPhoneNumberUsedLoading = false

        if (action.meta.aborted) return

        const error = {
          code: action?.error?.code,
          name: action?.error?.title,
          message: action?.error?.detail,
        }

        if (
          action.meta.rejectedWithValue &&
          Array.isArray(action.payload.errors)
        ) {
          const [firstError] = action.payload.errors
          error.message = firstError.detail
          error.name = firstError.title
          error.code = firstError.code
        }

        state.userCreation.error = error
      }
    )

    // ------- get user detail -------- \\
    builder.addCase(fetchUserDetails.pending, (state) => {
      state.isUserDetailsLoading = true
    })
    builder.addCase(fetchUserDetails.fulfilled, (state, action) => {
      state.isUserDetailsLoading = false
      state.userDetails = action.payload.data
    })
    builder.addCase(fetchUserDetails.rejected, (state, action) => {
      state.isUserDetailsLoading = false
      state.userDetailsError = action.error
    })
    // ------- change user activation status ------- \\
    builder.addCase(changeUserActivationStatus.fulfilled, (state) => {
      state.changeUserActivationStatus.state =
        CHANGE_USER_ACTIVATION_STATUS_STATE.CHANGED
    })
    builder.addCase(changeUserActivationStatus.rejected, (state, action) => {
      state.changeUserActivationStatus.state =
        CHANGE_USER_ACTIVATION_STATUS_STATE.ERRORED
      if (action.meta.aborted) return

      const preparedError = {
        code: GENERAL_ERROR,
        message: "web_c_general_error_massage",
      }

      if (Array.isArray(action.payload?.errors)) {
        const [firstError] = action.payload.errors
        preparedError.code = firstError.code
        preparedError.message = firstError.detail
      }

      state.changeUserActivationStatus.error = preparedError
    })
  },
})

export const {
  languageChangedTo,
  navbarToggled,
  mainAccountSet,
  instanceIdSet,
  otpErrorCleared,
  requestedOTPExpired,
  // -------- delete user -------- \\
  userDeletionStarted,
  userDeletionAborting,
  userDeletionRejected,
  userDeletionFinished,
  userDeletionAuthorizing,
  // -------- change user activation status -------- \\
  changeUserActivationStatusStarted,
  changeUserActivationStatusAborting,
  changeUserActivationStatusRejected,
  changeUserActivationStatusFinished,
  changeUserActivationStatusAuthorizing,
  // -------- create user -------- \\
  userCreationStarted,
  userCreationInformationSet,
  userCreationConfirmation,
  userCreationAuthorizing,
  userCreationAborting,
  userCreationRejected,
  userCreationFinished,
  // -------- verify OTP code -------- \\
  setOTPValidUntil,
  setIsOTPValidForNewRequest,
  // -------- update password process -------- \\
  updatingUserPasswordStarted,
  updatingUserPasswordFinished,
  // -------- user details -------- \\
  resetUserDetail,
  // -------- set and verify pin process -------- \\
  storeUserPin,
  settingAndVerifyingUserPinConfirming,
  settingAndVerifyingUserPinVerifying,
  settingAndVerifyingUserPinStarted,
  settingAndVerifyingUserPinFinished,
} = counterSlice.actions

export default counterSlice.reducer
