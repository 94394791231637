import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { USER_AVATAR_ICON_SIZE } from "../../shared/constants/icons"
import { fetchMyData } from "../../features/user/user-slice"
import {
  getLogoutURL,
  removeLoginVerifiedAt,
} from "../../lib/authentication/auth-utils"

import { ReactComponent as MenuIcon } from "../../assets/icons/menu.svg"
// import { ReactComponent as BellIcon } from "../../assets/icons/bell.svg"
import { ReactComponent as QRCodeIcon } from "../../assets/icons/qrcode.svg"
import Avatar from "../../assets/icons/avatar.svg"
import DetailsSidebar from "../detailsSidebar/DetailsSidebar"
import AccountSettings from "../sidebars/accountSettings/AccountSettings"
import ConfirmBox from "../confirmBox/ConfirmBox"
import { CONFIRM_BOX_VARIANT_WARNING } from "../../shared/constants/confirm-box"
import UserQRCodeDetails from "../sidebars/userQRCodeDetails/UserQRCodeDetails"

const Topbar = ({ toggleNavbar }) => {
  const { t } = useTranslation()
  const location = useLocation()
  const [isSettingsSidebarOpen, setIsSettingsSidebarOpen] = useState(false)
  const [isConfirmBoxOpen, setIsConfirmBoxOpen] = useState(false)
  const [isQRCodeSidebarOpen, setIsQRCodeSidebarOpen] = useState(false)
  const [
    name,
    role,
    iconUrl,
    businessCategory,
    readableId,
    phoneNumber,
    phoneDialingCode,
    agentRoleName,
    isMainAccount,
  ] = useSelector((state) => [
    state.user.name,
    state.user.role,
    state.user.iconUrl,
    state.user.businessCategory,
    state.user.readableId,
    state.user.phoneNumber,
    state.user.dialingCode,
    state.user.agentRoleName,
    state.user.isMainAccount,
  ])

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchMyData())
  }, [dispatch])

  useEffect(() => {
    setIsSettingsSidebarOpen(false)
  }, [location])

  const roleName = isMainAccount ? role : agentRoleName

  return (
    <header className="topbar" data-testid="topbar">
      <button
        type="button"
        onClick={toggleNavbar}
        data-testid="toggle-navbar-btn"
        className="button button--iconed icon-wrapper"
      >
        <MenuIcon className="menu-icon" aria-label="menu icon" />
      </button>

      <div className="user-box">
        <div className="user-box__icons">
          <button
            type="button"
            className="button button--iconed icon-wrapper"
            data-testid="qrcode-btn"
            onClick={() => setIsQRCodeSidebarOpen(true)}
          >
            <QRCodeIcon className="qrcode-icon" aria-label="qrcode icon" />
          </button>
          {/* FIXME: should be uncommented when there's notification feature added  */}
          {/* <button type="button" className="button button--iconed icon-wrapper">
            <BellIcon className="bell-icon" aria-label="bell icon" />
          </button> */}
        </div>
        <div className="user-info-box">
          <button
            type="button"
            className="button button--iconed user-info"
            data-testid="toggle-listbox-btn"
            onClick={() => setIsSettingsSidebarOpen(true)}
            title={`${name} - ${roleName}`}
          >
            <div className="user-avatar-wrapper">
              <img
                src={iconUrl || Avatar}
                alt="user avatar"
                width={USER_AVATAR_ICON_SIZE}
                height={USER_AVATAR_ICON_SIZE}
              />
            </div>

            <div>
              <span data-testid="user-info-name" className="user-info__name">
                {name}
              </span>
              <span data-testid="user-info-role" className="user-info__role">
                {roleName}
              </span>
            </div>
          </button>
          <DetailsSidebar
            sidebarTestId="account-settings-sidebar"
            closeBtnTestId="close-account-settings-sidebar-button"
            shouldAppear={isSettingsSidebarOpen}
            title={t("web_c_accsettingsidebar_title")}
            onClosed={() => {
              setIsSettingsSidebarOpen(false)
            }}
            rootClassName="settings-sidebar"
          >
            <AccountSettings
              userData={{ name, roleName, iconUrl }}
              setIsConfirmBoxOpen={setIsConfirmBoxOpen}
              setIsSettingsSidebarOpen={setIsSettingsSidebarOpen}
            />
          </DetailsSidebar>
          <DetailsSidebar
            sidebarTestId="qrcode-details-sidebar"
            closeBtnTestId="close-qrcode-details-sidebar-button"
            shouldAppear={isQRCodeSidebarOpen}
            title={t("web_c_staticqrcode_title")}
            onClosed={() => {
              setIsQRCodeSidebarOpen(false)
            }}
          >
            <UserQRCodeDetails
              userData={{
                name,
                role,
                iconUrl,
                businessCategory,
                readableId,
                phoneNumber,
                phoneDialingCode,
              }}
            />
          </DetailsSidebar>
          {!isSettingsSidebarOpen && isConfirmBoxOpen && (
            <ConfirmBox
              testId="logout-confirmBox"
              title={t("web_c_logoutpopup_title")}
              description={t("web_c_logoutpopup_description")}
              variant={CONFIRM_BOX_VARIANT_WARNING}
              buttons={{
                primary: {
                  text: t("web_c_logoutpopup_logout_button"),
                  clickHandler: () => {
                    removeLoginVerifiedAt()
                    window.location.href = getLogoutURL()
                  },
                },
                secondary: {
                  text: t("web_c_logoutpopup_cancel_button"),
                  clickHandler: () => {
                    setIsConfirmBoxOpen(false)
                  },
                },
              }}
            />
          )}
        </div>
      </div>
    </header>
  )
}
Topbar.propTypes = {
  toggleNavbar: PropTypes.func.isRequired,
}

export default Topbar
