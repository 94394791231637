/**
 * @readonly
 * @enum
 */
export const ADD_EMPLOYEE_STATE = {
  IDLE: "IDLE",
  STARTED: "STARTED",
  INFORMATION_SET: "INFORMATION_SET",
  AUTHORIZING: "AUTHORIZING",
  ABORTING: "ABORTING",
  REJECTED: "REJECTED",
  ADDED: "ADDED",
  ERRORED: "ERRORED",
}

export const getStepNumberFromAddEmployeeState = (employeeAdditionState) => {
  switch (employeeAdditionState) {
    case ADD_EMPLOYEE_STATE.STARTED:
    case ADD_EMPLOYEE_STATE.INFORMATION_SET:
      return 0
    case ADD_EMPLOYEE_STATE.AUTHORIZING:
    case ADD_EMPLOYEE_STATE.ABORTING:
    case ADD_EMPLOYEE_STATE.REJECTED:
      return 1
    default:
      return -1
  }
}
