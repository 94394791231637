import PropTypes from "prop-types"
import classNames from "classnames"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import { useMemo } from "react"
import NavlinkGroup from "../navlinkGroup/NavlinkGroup"
import NavbarLink from "../navbarLink/NavbarLink"
import routes from "../../routes/routeDefinitions"
import { ReactComponent as Logo } from "../../assets/images/navbar/FIBLogo.svg"
import { ReactComponent as ShortLogo } from "../../assets/images/navbar/FIBLogo_mini.svg"
import { ReactComponent as AnalyticsIcon } from "../../assets/icons/analytics.svg"
import { ReactComponent as TransactionIcon } from "../../assets/icons/transaction.svg"
import { ReactComponent as TerminalIcon } from "../../assets/icons/terminals.svg"
import { ReactComponent as UserIcon } from "../../assets/icons/user.svg"
import { ReactComponent as UserCircleIcon } from "../../assets/icons/user-circle.svg"
import { ReactComponent as ClockIcon } from "../../assets/icons/clock.svg"
import {
  agentRoleFeaturesSelector,
  isMainAccountSelector,
} from "../../features/user/user-selectors"
import {
  modifyGroupLinksByPermissions,
  shouldRenderNavLinkGroup,
  shouldRenderRoute,
} from "../../lib/agents/agent"
import {
  AGENT_FEATURES,
  analyticsLinks,
  employeesLinks,
  usersLinks,
} from "../../shared/constants/agent"

const Navbar = ({ isCollapsed }) => {
  const { t } = useTranslation()

  const isMainAccount = useSelector(isMainAccountSelector)
  const agentRoleFeatures = useSelector(agentRoleFeaturesSelector)

  const analyticsGroupLinks = useMemo(
    () =>
      // FIXME: Balance page is removed in Frontend only, needs to be checked later
      analyticsLinks
        .map((link) => ({ ...link, text: t(link.text) }))
        .filter(({ to }) => to !== routes.balance.path),
    [t]
  )

  const employeesGroupLinks = useMemo(
    () => employeesLinks.map((link) => ({ ...link, text: t(link.text) })),
    [t]
  )

  const usersGroupLinks = useMemo(
    () => usersLinks.map((link) => ({ ...link, text: t(link.text) })),
    [t]
  )
  const filteredAnalyticsGroupLinks = useMemo(() => {
    if (isMainAccount === false) {
      const filteredData = modifyGroupLinksByPermissions(
        agentRoleFeatures,
        analyticsGroupLinks
      )

      return filteredData
    }

    return analyticsGroupLinks
  }, [agentRoleFeatures, analyticsGroupLinks, isMainAccount])

  const filteredEmployeesGroupLinks = useMemo(() => {
    if (isMainAccount === false) {
      const filteredData = modifyGroupLinksByPermissions(
        agentRoleFeatures,
        employeesGroupLinks
      )

      return filteredData
    }

    return employeesGroupLinks
  }, [agentRoleFeatures, employeesGroupLinks, isMainAccount])

  const filteredUsersGroupLinks = useMemo(() => {
    if (isMainAccount === false) {
      const filteredData = modifyGroupLinksByPermissions(
        agentRoleFeatures,
        usersGroupLinks
      )

      return filteredData
    }

    return usersGroupLinks
  }, [agentRoleFeatures, isMainAccount, usersGroupLinks])

  const shouldIncludeTeamDetailsPageLink = shouldRenderRoute(
    isMainAccount,
    agentRoleFeatures,
    [AGENT_FEATURES.TEAMS]
  )

  return (
    <aside
      className={classNames("navbar", { "navbar--collapsed": isCollapsed })}
      data-testid="navbar"
      data-is-collapsed={isCollapsed}
    >
      <div className="logo-wrapper">
        <Link to={routes.home.path}>
          {isCollapsed ? (
            <ShortLogo className="app-logo" aria-label="small logo" />
          ) : (
            <Logo
              width={70}
              height={32}
              className="app-logo"
              aria-label="normal logo"
            />
          )}
        </Link>
      </div>
      <nav className="navigation">
        <small className="navigation-title">{t("web_c_menu_label")}</small>
        <div className="navlinks">
          {shouldRenderNavLinkGroup(
            isMainAccount,
            filteredAnalyticsGroupLinks
          ) && (
            <NavlinkGroup
              title={t("web_c_menu_analytics_label")}
              Icon={AnalyticsIcon}
              shouldCollapse={isCollapsed}
              links={filteredAnalyticsGroupLinks}
            />
          )}
          {shouldRenderNavLinkGroup(
            isMainAccount,
            filteredEmployeesGroupLinks
          ) && (
            <NavlinkGroup
              title={t("web_c_menu_employee_label")}
              Icon={UserIcon}
              shouldCollapse={isCollapsed}
              links={filteredEmployeesGroupLinks}
              additionalLinks={
                shouldIncludeTeamDetailsPageLink
                  ? [{ to: routes.teams.teamDetails.path }]
                  : []
              }
            />
          )}
          {shouldRenderRoute(isMainAccount, agentRoleFeatures, [
            AGENT_FEATURES.TERMINALS,
          ]) && (
            <NavbarLink
              icon={TerminalIcon}
              target={routes.terminals.path}
              title={t("web_c_menu_terminals_label")}
            />
          )}
          {shouldRenderRoute(isMainAccount, agentRoleFeatures, [
            AGENT_FEATURES.BANK_TRANSFERS,
            AGENT_FEATURES.RECEIVE_PAYMENTS,
          ]) && (
            <NavbarLink
              icon={TransactionIcon}
              target={routes.transfer.path}
              title={t("web_c_transfer_page_title")}
            />
          )}
          {shouldRenderNavLinkGroup(isMainAccount, filteredUsersGroupLinks) && (
            <NavlinkGroup
              title={t("web_c_menu_users_label")}
              Icon={UserCircleIcon}
              shouldCollapse={isCollapsed}
              links={filteredUsersGroupLinks}
            />
          )}
          {isMainAccount && (
            <NavbarLink
              icon={ClockIcon}
              target={routes.pendingApprovals.path}
              title={t("web_c_pendingapproval_page_title")}
            />
          )}
        </div>
      </nav>
    </aside>
  )
}

Navbar.propTypes = {
  isCollapsed: PropTypes.bool.isRequired,
}

export default Navbar
