import PropTypes from "prop-types"

const UserInfo = ({ name, iban, iconURL, initials }) => (
  <section className="details-sidebar-body__user-info">
    {iconURL && (
      <img
        className="details-sidebar-body__user-info-icon"
        src={iconURL}
        alt={initials}
        width={56}
        height={56}
        data-testid="details-sidebar-user-info-icon"
      />
    )}
    {!iconURL && (
      <div
        className="details-sidebar-body__user-info-initials"
        data-testid="details-sidebar-user-info-initials"
      >
        <span>{initials}</span>
      </div>
    )}
    <h4 className="details-sidebar-body__user-info-name">{name}</h4>
    <p className="details-sidebar-body__user-info-iban">{iban}</p>
  </section>
)

UserInfo.propTypes = {
  name: PropTypes.string,
  iban: PropTypes.string,
  iconURL: PropTypes.string,
  initials: PropTypes.string,
}

UserInfo.defaultProps = {
  name: undefined,
  iban: undefined,
  iconURL: undefined,
  initials: undefined,
}

export default UserInfo
