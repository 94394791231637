import {
  PASSWORD_ERROR_SHORT_PASSWORD,
  PASSWORD_ERROR_MISSING_LOWERCASE,
  PASSWORD_ERROR_MISSING_UPPERCASE,
  PASSWORD_ERROR_MISSING_SPECIAL_CHAR,
} from "../general"

export const MAP_CHANGE_TERMINAL_PASSWORD_ERROR_TO_LOKALISE_KEY = [
  {
    lokaliseKey: "web_c_terminals_changepasswordmodal_minchar_validation",
    errorType: PASSWORD_ERROR_SHORT_PASSWORD,
  },
  {
    lokaliseKey: "web_c_terminals_changepasswordmodal_uppercase_validation",
    errorType: PASSWORD_ERROR_MISSING_UPPERCASE,
  },
  {
    lokaliseKey: "web_c_terminals_changepasswordmodal_lowercase_validation",
    errorType: PASSWORD_ERROR_MISSING_LOWERCASE,
  },
  {
    lokaliseKey:
      "web_c_terminals_changepasswordmodal_shouldhavespecialchar_validation",
    errorType: PASSWORD_ERROR_MISSING_SPECIAL_CHAR,
  },
]

export const CHANGING_TERMINAL_PASSWORD_STATE = {
  IDLE: "IDLE",
  STARTED: "STARTED",
  PASSWORD_SET: "PASSWORD_SET",
  AUTHORIZING: "AUTHORIZING",
  ABORTING: "ABORTING",
  REJECTED: "REJECTED",
  AUTHORIZED: "AUTHORIZED",
  ERRORED: "ERRORED",
  CHANGED: "CHANGED",
}
