import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import {
  checkingIsOTPVerifiedSelector,
  instanceIdSelector,
  isMainAccountSelector,
  isOTPVerifiedSelector,
  isPasswordStatusLoadingSelector,
  isPasswordUpdateRequiredSelector,
  otpErrorSelector,
  passwordStatusErrorSelector,
  isSettingPinRequiredSelector,
  pinStatusErrorSelector,
  isPinVerifiedSelector,
} from "../../features/user/user-selectors"
import {
  checkingPasswordStatus,
  checkingPinStatus,
  fetchInstanceOTPStatus,
  instanceIdSet,
  mainAccountSet,
} from "../../features/user/user-slice"
import {
  generateUUID,
  getInstanceId,
  getParsedToken,
  isLoginVerificationValid,
  saveInstanceId,
} from "../../lib/authentication/auth-utils"
import routes from "../../routes/routeDefinitions"

const useAuthentication = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const isMainAccount = useSelector(isMainAccountSelector)
  const isOTPVerified = useSelector(isOTPVerifiedSelector)
  const checkingIsOTPVerified = useSelector(checkingIsOTPVerifiedSelector)
  const instanceId = useSelector(instanceIdSelector)
  const otpError = useSelector(otpErrorSelector)
  const isPasswordUpdateRequired = useSelector(isPasswordUpdateRequiredSelector)
  const isPasswordStatusLoading = useSelector(isPasswordStatusLoadingSelector)
  const passwordStatusError = useSelector(passwordStatusErrorSelector)
  const isSettingPinRequired = useSelector(isSettingPinRequiredSelector)
  const pinStatusError = useSelector(pinStatusErrorSelector)
  const isPinVerified = useSelector(isPinVerifiedSelector)

  useEffect(() => {
    // This will create an instance ID that we will have to verify using OTP Verification, once it's verified it will be stored in Local Storage so that during refresh or opening another tab you're still verified and logged in.
    if (isMainAccount === false && instanceId === null) {
      let savedInstanceId = getInstanceId()
      if (savedInstanceId === null) {
        const generatedInstanceId = generateUUID()
        saveInstanceId(generatedInstanceId)
        savedInstanceId = generatedInstanceId
      }

      dispatch(instanceIdSet(savedInstanceId))
    }
  }, [dispatch, instanceId, isMainAccount])

  useEffect(() => {
    if (isMainAccount === undefined) {
      const parsedToken = JSON.parse(getParsedToken())

      let isUserMainAccount = parsedToken.realm_access.roles.includes(
        "application_corporate_user"
      )

      if (
        parsedToken.realm_access.roles.includes(
          "application_corporate_terminal_user"
        ) ||
        parsedToken.realm_access.roles.includes(
          "application_unactivated_corporate_terminal_user"
        )
      ) {
        isUserMainAccount = null
      }

      dispatch(mainAccountSet(isUserMainAccount))
    }
  }, [dispatch, isMainAccount])

  useEffect(() => {
    if (
      isMainAccount === false &&
      isOTPVerified === undefined &&
      !checkingIsOTPVerified &&
      instanceId !== null &&
      otpError === undefined
    ) {
      dispatch(fetchInstanceOTPStatus())
    }
  }, [
    checkingIsOTPVerified,
    dispatch,
    instanceId,
    isMainAccount,
    isOTPVerified,
    otpError,
  ])

  useEffect(() => {
    if (
      isMainAccount === false &&
      isOTPVerified === true &&
      isPasswordUpdateRequired === undefined
    ) {
      dispatch(checkingPasswordStatus())
    } else if (
      isMainAccount === false &&
      isOTPVerified === true &&
      isPasswordUpdateRequired === false &&
      isSettingPinRequired === undefined
    ) {
      dispatch(checkingPinStatus())
    }
  }, [
    dispatch,
    isMainAccount,
    isOTPVerified,
    isPasswordUpdateRequired,
    isSettingPinRequired,
  ])

  useEffect(() => {
    if (isMainAccount !== undefined) {
      let isLoginVerified = isLoginVerificationValid()
      // If `isMainAccount === null` then the user is a terminal
      // we'll not allow null
      if (isMainAccount === null) {
        isLoginVerified = false
        if (location.pathname !== routes.notAllowedUsers.path) {
          navigate(routes.notAllowedUsers.path, { replace: true })
        }
      } else if (isMainAccount === false) {
        // TODO check if the agent has updated its password or not.
        // PasswordUpdate will be a process rendered in OTP verification
        // Screen, if the user have refreshed the page after verifying OTP
        // We'll disable the OTP verification form.
        // FIXME it should be tested comprehensively
        // istanbul ignore next
        if (
          isOTPVerified === false ||
          isPasswordUpdateRequired === true ||
          passwordStatusError !== undefined
        ) {
          isLoginVerified = false

          if (location.pathname !== routes.verifyOTP.path) {
            navigate(routes.verifyOTP.path, { replace: true })
          }
        } else if (
          isOTPVerified === true &&
          isPasswordUpdateRequired === undefined
        ) {
          isLoginVerified = false
        }
        // account for PINVerification as well
        else if (
          isOTPVerified === true &&
          isPasswordUpdateRequired === false &&
          isSettingPinRequired === undefined
        ) {
          isLoginVerified = false
        } else if (
          isOTPVerified === true &&
          isPasswordUpdateRequired === false &&
          isSettingPinRequired === true
        ) {
          isLoginVerified = false
        } else if (
          isOTPVerified === true &&
          isPasswordUpdateRequired === false &&
          isSettingPinRequired === false &&
          isPinVerified === undefined
        ) {
          isLoginVerified = false
        } else if (
          isOTPVerified === true &&
          isPasswordUpdateRequired === false &&
          isSettingPinRequired === false &&
          isPinVerified === false
        ) {
          isLoginVerified = false
        } else if (!isLoginVerified) {
          isLoginVerified = false

          if (location.pathname !== routes.verifyOTP.path) {
            navigate(routes.verifyOTP.path, { replace: true })
          }
        }
      } else if (!isLoginVerified) {
        isLoginVerified = false

        if (location.pathname !== routes.verifyQrcode.path) {
          navigate(routes.verifyQrcode.path, { replace: true })
        }
      }

      // TODO move it to route defintions
      // TODO or we should maybe redirect it to the path the user came to
      // in the first place, for example user comes to `/users`, when authorized
      // the should be redirected to that path, we can save the path in a search param
      // and redirect'em depending on that.
      const redirectPathsToHome = [
        routes.verifyQrcode.path,
        routes.verifyOTP.path,
      ]

      if (isLoginVerified && redirectPathsToHome.includes(location.pathname)) {
        navigate(routes.home.path, { replace: true })
      }
    }
  }, [
    dispatch,
    isMainAccount,
    isOTPVerified,
    isPasswordStatusLoading,
    isPasswordUpdateRequired,
    isPinVerified,
    isSettingPinRequired,
    location.pathname,
    navigate,
    passwordStatusError,
  ])

  if (process.env.NODE_ENV === "test") {
    if (global.useAuthenticationReturn) return global.useAuthenticationReturn
  }

  if (
    otpError !== undefined ||
    passwordStatusError !== undefined ||
    pinStatusError !== undefined
  ) {
    return false
  }

  return (
    (isMainAccount === true && isLoginVerificationValid()) ||
    (isMainAccount === false &&
      isOTPVerified === true &&
      isPasswordUpdateRequired === false &&
      isSettingPinRequired === false)
  )
}

export default useAuthentication
