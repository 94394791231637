import * as yup from "yup"

/**
 * @readonly
 * @enum {string}
 */
export const TEAM_SALARY_DISTRIBUTION = {
  IDLE: "IDLE",
  STARTED: "STARTED",
  INFORMATION_SET: "INFORMATION_SET",
  CONFIRMATION: "CONFIRMATION",
  CONFIRMED: "CONFIRMED",
  AUTHORIZING: "AUTHORIZING",
  ABORTING: "ABORTING",
  REJECTED: "REJECTED",
  DISTRIBUTING: "DISTRIBUTING",
  DISTRIBUTED: "DISTRIBUTED",
  ERRORED: "ERRORED",
}

export const ERROR_RECOVERABLE_STATES = [
  TEAM_SALARY_DISTRIBUTION.INFORMATION_SET,
  TEAM_SALARY_DISTRIBUTION.ERRORED,
]

export const getStepNumberFromTeamSalaryDistributionState = (
  shiftMoneyState
) => {
  switch (shiftMoneyState) {
    case TEAM_SALARY_DISTRIBUTION.STARTED:
    case TEAM_SALARY_DISTRIBUTION.INFORMATION_SET:
      return 0
    case TEAM_SALARY_DISTRIBUTION.CONFIRMATION:
    case TEAM_SALARY_DISTRIBUTION.CONFIRMED:
    case TEAM_SALARY_DISTRIBUTION.AUTHORIZING:
    case TEAM_SALARY_DISTRIBUTION.ABORTING:
    case TEAM_SALARY_DISTRIBUTION.REJECTED:
    case TEAM_SALARY_DISTRIBUTION.DISTRIBUTING:
    case TEAM_SALARY_DISTRIBUTION.DISTRIBUTED:
    case TEAM_SALARY_DISTRIBUTION.ERRORED:
      return 1
    case TEAM_SALARY_DISTRIBUTION.IDLE:
    default:
      return -1
  }
}

export const TEAM_RECOVERABLE_ERROR_CODES = [
  "BALANCE_TOO_LOW",
  "PAYEES_ALREADY_PAID",
]

export const teamSalaryDistributionSchema = yup.object({
  date: yup.date().required(),
  sourceAccount: yup
    .object({
      id: yup.string().required(),
      text: yup.string().required(),
    })
    .required(),
})
