import { createSelector } from "@reduxjs/toolkit"

export const teamsSelector = (state) => state.teams.teams

export const isTeamsLoadingSelector = (state) => state.teams.isTeamsLoading

export const teamsErrorSelector = (state) => state.teams.teamsError

export const teamsAndEmployeesSelector = (state) =>
  state.teams.teamsAndEmployees
export const isTeamsAndEmployeesLoadingSelector = (state) =>
  state.teams.isTeamsAndEmployeesLoading

// --------- Team Deletion ---------- \\
export const teamDeletionStateSelector = (state) =>
  state.teams.teamDeletion.state

export const teamDeletionNameSelector = (state) => state.teams.teamDeletion.name

// -------- Add Team -------- \\

export const addTeamStateSelector = (state) => state.teams.addTeam.state

export const addTeamErrorSelector = (state) => state.teams.addTeam.error

// -------- Editing Team -------- \\

export const editingTeamStateSelector = (state) => state.teams.editingTeam.state

export const editingTeamNameSelector = (state) => state.teams.editingTeam.name

export const editingTeamIdSelector = (state) => state.teams.editingTeam.id

export const editingTeamCurrentTeamMembers = createSelector(
  (state) => state.teams.editingTeam.teamMembers,
  (teamMembers) => {
    if (!Array.isArray(teamMembers)) return []
    return teamMembers.map(({ id, name }) => ({ id, name }))
  }
)

export const editingTeamIsTeamDetailsLoadingSelector = (state) =>
  state.teams.editingTeam.isTeamDetailsLoading

export const editingTeamErrorSelector = (state) => state.teams.editingTeam.error

// -------- Team Details -------- \\
export const teamDetailsSelector = (state) => state.teams.teamDetails.teamInfo
export const isTeamDetailsLoadingSelector = (state) =>
  state.teams.teamDetails.isTeamDetailsLoading
export const teamDetailsErrorSelector = (state) =>
  state.teams.teamDetails.teamDetailsError
export const teamMembersErrorSelector = (state) =>
  state.teams.teamDetails.teamMembersError

// -------- Team Members -------- \\
export const teamMembersSelector = (state) =>
  state.teams.teamDetails.teamMembers
export const isFirstPageSelector = (state) => state.teams.teamDetails.isFirst
export const isLastPageSelector = (state) => state.teams.teamDetails.isLast
export const pageNumberSelector = (state) => state.teams.teamDetails.pageNumber
export const pageSizeSelector = (state) => state.teams.teamDetails.pageSize
export const totalElementsSelector = (state) =>
  state.teams.teamDetails.totalElements
export const totalPagesSelector = (state) => state.teams.teamDetails.totalPages
export const isTeamMembersLoadingSelector = (state) =>
  state.teams.teamDetails.isTeamMembersLoading

// -------- Team Member Deletion -------- \\
export const teamMemberDeletionStateSelector = (state) =>
  state.teams.teamMemberDeletion.state

export const teamMemberDeletionNameSelector = (state) =>
  state.teams.teamMemberDeletion.name
