import { useCallback, useEffect } from "react"

/**
 *
 *
 * @typedef {import("react").MutableRefObject<Element} UCA_Ref - to be used to refer to the parent element.
 * @typedef {*} UCA_State - current state: initialy have `initialValue`
 * @typedef {import("react").Dispatch<*>} UCA_SetState - state updater: to update current state.
 *
 *
 * Custom hook enables a piece of UI to observe when get's clicked
 * outside of a desired element. Returns an array of 3 elements:
 *
 * @param {*} initialValue
 * @param {*} clickedAwayValue
 */

const useClickAwayListener = (ref, exceptRef, handler) => {
  const listener = useCallback(
    (e) => {
      if (
        ref.current &&
        ref.current !== e.target &&
        exceptRef.current !== e.target &&
        !ref.current.contains(e.target)
      ) {
        handler(e)
      }
    },
    [exceptRef, handler, ref]
  )

  useEffect(() => {
    document.addEventListener("mouseup", listener)
    document.addEventListener("touchstart", listener)

    return () => {
      document.removeEventListener("mouseup", listener)
      document.removeEventListener("touchstart", listener)
    }
  }, [listener])
}

export default useClickAwayListener
