import { useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg"

const KEY_CODE_ENTER = "Enter"

const SearchField = ({
  placeholderLabel,
  buttonLabel,
  searchText,
  disabled,
  onChange,
  onEnter,
  onSearchClick,
}) => {
  const [keyword, setKeyword] = useState(searchText)

  useEffect(() => {
    setKeyword(searchText)
  }, [searchText])

  useEffect(() => {
    if (keyword === "" && onSearchClick) onSearchClick(keyword)
  }, [keyword, onSearchClick])

  const onChangeHandler = useCallback(
    (e) => {
      setKeyword(e.target.value)
      if (onChange) onChange(e.target.value)
    },
    [onChange, setKeyword]
  )

  const onEnterHandler = useCallback(
    (e) => {
      if (
        searchText !== keyword &&
        onEnter &&
        (e.key === KEY_CODE_ENTER || e.code === KEY_CODE_ENTER)
      ) {
        e.preventDefault()
        onEnter(e.target.value)
      }
    },
    [keyword, onEnter, searchText]
  )

  const onSearchClickHandler = useCallback(() => {
    if (onSearchClick && searchText !== keyword) onSearchClick(keyword)
  }, [onSearchClick, searchText, keyword])

  return (
    <label className="search-field">
      <div className="search-field__icon" data-testid="search-icon-container">
        <SearchIcon width={18} height={18} />
      </div>
      <input
        type="search"
        data-testid="search-input"
        value={keyword}
        disabled={disabled}
        onChange={onChangeHandler}
        onKeyDown={onEnterHandler}
        className="search-field__input"
        placeholder={placeholderLabel}
      />
      {buttonLabel && (
        <button
          type="submit"
          data-testid="search-button"
          disabled={
            disabled || searchText.trim().length + keyword.trim().length === 0
          }
          className="button button--primary search-field__btn"
          onClick={onSearchClickHandler}
        >
          {buttonLabel}
        </button>
      )}
    </label>
  )
}

SearchField.propTypes = {
  placeholderLabel: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string,
  searchText: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onEnter: PropTypes.func,
  onSearchClick: PropTypes.func,
}

SearchField.defaultProps = {
  searchText: "",
  disabled: false,
  onChange: undefined,
  onEnter: undefined,
  onSearchClick: undefined,
  buttonLabel: undefined,
}

export default SearchField
