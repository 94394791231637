/**
 * @readonly
 * @enum {string}
 */
export const SETTING_AND_VERIFYING_USER_PIN_STATE = {
  IDLE: "IDLE",
  STARTED: "STARTED",
  CONFIRMING: "CONFIRMING",
  ERRORED: "ERRORED",
  SETTING: "SETTING",
  SET: "SET",
  VERIFYING: "VERIFYING",
  LOADING: "LOADING",
  VERIFIED: "VERIFIED",
}

export const getStepNumberSettingAndVerifyingUserPinState = (setPinState) => {
  switch (setPinState) {
    case SETTING_AND_VERIFYING_USER_PIN_STATE.STARTED:
      return 0
    case SETTING_AND_VERIFYING_USER_PIN_STATE.CONFIRMING:
    case SETTING_AND_VERIFYING_USER_PIN_STATE.SETTING:
      return 1
    case SETTING_AND_VERIFYING_USER_PIN_STATE.VERIFYING:
    case SETTING_AND_VERIFYING_USER_PIN_STATE.LOADING:
      return 2
    default:
      return -1
  }
}

export const TEN_DAYS_IN_SECONDS = 60 * 60 * 24 * 10

// This error is returned if you enter the wrong 4-digit pin credentials when verifying or authorizing
export const INVALID_GRANT_ERROR = "invalid_grant"
