import { createAsyncThunk, createSlice, createAction } from "@reduxjs/toolkit"
import {
  authorizeTeamSalaryDistribution,
  createTeamSalaryDistribution,
  getSalaryDistributionDetails,
  getSalaryDistributions,
  fileSalaryDistributionUploader,
  authorizeFileSalaryDistribution,
} from "../../api/endpoints/payroll"
import { hashItems } from "../../lib/general"
import {
  TEAM_RECOVERABLE_ERROR_CODES,
  TEAM_SALARY_DISTRIBUTION,
} from "../../shared/constants/payroll/team-salary-distribution"
import { formatSalaryDistributions } from "./payroll-helpers"
import {
  FILE_SALARY_DISTRIBUTION,
  FILE_RECOVERABLE_ERROR_CODES,
  MAP_PAYROLL_CSV_ERROR_CODE_TO_MESSAGE,
} from "../../shared/constants/payroll/file-salary-distribution"
import { onUploadProgressBuilder } from "../../lib/payroll/payroll"

const SLICE_NAME = "payroll"

export const fetchPayrollsPayloadCreator = async ({
  size,
  page,
  type,
  teamIds,
  dateRange,
  periodYear,
  periodMonth,
  searchQuery,
}) => {
  const response = await getSalaryDistributions({
    size,
    page,
    type,
    teamIds,
    dateRange,
    periodYear,
    periodMonth,
    searchQuery,
  })
  const { data, status, statusText } = response
  return { data, status, statusText }
}

export const fetchPayrolls = createAsyncThunk(
  `${SLICE_NAME}/fetchPayrolls`,
  fetchPayrollsPayloadCreator
)

export const initiateTeamSalaryDistribution = createAsyncThunk(
  `${SLICE_NAME}/initiateTeamSalaryDistribution`,
  async (_, thunkAPI) => {
    const { sourceAccountId, selectedTeamsAndEmployees, period } =
      thunkAPI.getState().payroll.teamSalaryDistribution

    try {
      const { data, status, statusText } = await createTeamSalaryDistribution({
        period,
        sourceAccountId,
        teams: selectedTeamsAndEmployees,
      })

      return { data, status, statusText }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

export const fetchSalaryDistributionDetails = createAsyncThunk(
  `${SLICE_NAME}/fetchSalaryDistributionDetails`,
  /**
   * @param {string} salaryDistributionId
   */
  async (salaryDistributionId) => {
    const { data, status, statusText } = await getSalaryDistributionDetails(
      salaryDistributionId
    )
    return { data, status, statusText }
  },
  {
    condition: (salaryDistributionId, { getState }) => {
      const { currentSalaryDistributionDetails } = getState().payroll
      return (
        currentSalaryDistributionDetails === undefined ||
        currentSalaryDistributionDetails.id !== salaryDistributionId
      )
    },
  }
)

export const teamSalaryDistributionAuthorization = createAsyncThunk(
  `${SLICE_NAME}/teamSalaryDistributionAuthorization`,
  async (_, thunkAPI) => {
    const { elevatedToken } = thunkAPI.getState().otac

    if (!elevatedToken) {
      throw thunkAPI.rejectWithValue({
        message: "web_c_general_notauthorized_error_text",
      })
    }

    const { salaryDistributionId } =
      thunkAPI.getState().payroll.teamSalaryDistribution

    try {
      await authorizeTeamSalaryDistribution(salaryDistributionId, {
        headers: { Authorization: `Bearer ${elevatedToken}` },
      })
    } catch (error) {
      throw thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

export const receiveUploadFileSalaryDistributionProgress = createAction(
  `${SLICE_NAME}/receiveUploadFileSalaryDistributionProgress`
)

export const uploadFileSalaryDistribution = createAsyncThunk(
  `${SLICE_NAME}/uploadFileSalaryDistribution`,
  /**
   *
   * @param {object} params
   * @param {object} params.file
   * @param {string} params.period
   * @returns
   */
  async (
    { sourceAccountId, period, file },
    { dispatch, rejectWithValue, signal }
  ) => {
    try {
      const fileUploadRes = await fileSalaryDistributionUploader(
        { sourceAccountId, period, file },
        {
          onUploadProgress: onUploadProgressBuilder((precentage) => {
            dispatch(receiveUploadFileSalaryDistributionProgress(precentage))
          }),
          signal,
        }
      )

      const { data } = await getSalaryDistributionDetails(fileUploadRes.data.id)
      return { data, fileName: file.name }
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const fileSalaryDistributionAuthorization = createAsyncThunk(
  `${SLICE_NAME}/fileSalaryDistributionAuthorization`,
  async (_, thunkAPI) => {
    const { elevatedToken } = thunkAPI.getState().otac

    if (!elevatedToken) {
      throw thunkAPI.rejectWithValue({
        message: "web_c_general_notauthorized_error_text",
      })
    }

    const { id } = thunkAPI.getState().payroll.currentSalaryDistributionDetails

    try {
      await authorizeFileSalaryDistribution(id, {
        headers: { Authorization: `Bearer ${elevatedToken}` },
      })
    } catch (error) {
      throw thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

export const initialState = {
  /**
   * payrolls is an array of objects that gives information
   * about each payroll
   * @type {object[]}
   */
  payrolls: undefined,

  /**
   * represents the state of loading the payrolls
   * @type {boolean}
   */
  isPayrollsLoading: false,

  /**
   * represents error of payrolls if any
   * @type {object}
   */
  payrollsError: undefined,

  /**
   * defined the current state of the table
   * and empowering the pagination of the page
   */
  isFirst: false,
  isLast: false,
  pageNumber: undefined,
  pageSize: undefined,
  totalElements: undefined,
  totalPages: undefined,

  /**
   * it holds the distribute salary by csv process
   * @type {object}
   */
  fileSalaryDistribution: {
    /**
     * @type {FILE_SALARY_DISTRIBUTION}
     */
    state: FILE_SALARY_DISTRIBUTION.IDLE,
    /**
     * This state will hold the file upload precentage
     * @type {number}
     */
    uploadPrecentage: 0,
    /**
     * error happened during distributing salary by csv
     *
     * @type {import("@reduxjs/toolkit").SerializedError}
     */
    error: undefined,
    /**
     * indicates whether the user confirmed the transaction
     *
     * @type {boolean}
     */
    isConfirmed: false,
  },

  /**
   * represents the current salary distribution details
   * @type {import("../../api/endpoints/payroll").SalaryDistributionDetailsResponse}
   */
  currentSalaryDistributionDetails: undefined,

  /**
   * represents the loading state of
   * current salary distribution details
   * @type {boolean}
   */
  isCurrentSalaryDistributionDetailsLoading: false,

  /**
   * represents the error for current salary distribution details
   * @type {import("@reduxjs/toolkit").SerializedError}
   */
  currentSalaryDistributionDetailsError: undefined,

  /**
   * State for tracking distributing salary for teams
   */
  teamSalaryDistribution: {
    /**
     * @type { TEAM_SALARY_DISTRIBUTION }
     */
    state: TEAM_SALARY_DISTRIBUTION.IDLE,

    /**
     * The terminal or main account ID from which salaries are funded
     *
     * @type {string}
     */
    sourceAccountId: undefined,

    /**
     *
     * @typedef {object[]} SelectedTeamsAndEmployees
     * @property {string} id
     * @property {object[]} employees
     * @property {string} employees.id
     *
     * @type {SelectedTeamsAndEmployees}
     */
    selectedTeamsAndEmployees: [],
    /**
     * the representation of the year-month of the
     * salary distribution
     *
     * @type {string}
     */
    period: undefined,
    /**
     * represents the salary distribution information that
     * currently set, its used to not generate a new transaction
     * if the user already has a transaction with same configuration.
     * The property is created and calculated on frontend and its part
     * of frontend housekeeping only.
     *
     * @type {string}
     */
    configurationHash: undefined,
    /**
     * indicates whether the user confirmed the transaction
     *
     * @type {boolean}
     */
    isConfirmed: false,
    /**
     * id of the initiated salary distribution
     *
     * @type {boolean}
     */
    isInitiatingSalaryDistribution: false,
    /**
     * id of the initiated salary distribution
     *
     * @type {string}
     */
    salaryDistributionId: undefined,
    /**
     * error happened during distributing salary to team
     *
     * @type { ErrorWithMessage }
     */
    error: undefined,
  },
}

const payrollSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    // -------- File Salary Distribution -------- \\
    fileSalaryDistributionStartedIdle: (state) => {
      state.fileSalaryDistribution.state = FILE_SALARY_DISTRIBUTION.IDLE
    },
    fileSalaryDistributionStarted: (state) => {
      const startingState = {
        ...initialState.fileSalaryDistribution,
        state: FILE_SALARY_DISTRIBUTION.STARTED,
      }
      state.fileSalaryDistribution = startingState
    },
    fileSalaryDistributionUploaded: (state) => {
      state.fileSalaryDistribution.state = FILE_SALARY_DISTRIBUTION.UPLOADED
    },
    fileSalaryDistributionConfirmation: (state) => {
      state.fileSalaryDistribution.state = FILE_SALARY_DISTRIBUTION.CONFIRMATION
    },
    fileSalaryDistributionConfirmed: (state) => {
      state.fileSalaryDistribution.state = FILE_SALARY_DISTRIBUTION.CONFIRMED
      state.fileSalaryDistribution.isConfirmed = true
    },
    fileSalaryDistributionUploadAbortFile: (state) => {
      state.fileSalaryDistribution.state =
        FILE_SALARY_DISTRIBUTION.ABORT_UPLOADED
    },
    fileSalaryDistributionFinished: (state) => {
      state.fileSalaryDistribution = initialState.fileSalaryDistribution
    },
    fileSalaryDistributionRejected: (state) => {
      state.fileSalaryDistribution.state = FILE_SALARY_DISTRIBUTION.REJECTED
    },
    fileSalaryDistributionAbortAuthorization: (state) => {
      state.fileSalaryDistribution.state =
        FILE_SALARY_DISTRIBUTION.ABORT_AUTHORIZATION
    },

    // -------- Current Salary distribution Details -------- \\
    resetCurrentSalaryDistributionDetails: (state) => {
      state.currentSalaryDistributionDetails =
        initialState.currentSalaryDistributionDetails
      state.currentSalaryDistributionDetailsError =
        initialState.currentSalaryDistributionDetailsError
      state.isCurrentSalaryDistributionDetailsLoading =
        initialState.isCurrentSalaryDistributionDetailsLoading
    },
    // -------- Team Salary Distribution -------- \\
    teamSalaryDistributionStarted: (state) => {
      state.teamSalaryDistribution.state = TEAM_SALARY_DISTRIBUTION.STARTED
      state.teamSalaryDistribution.error = undefined
    },
    /**
     * @param {object} action
     * @param {object} action.payload
     * @param {string} action.payload.period
     * @param {object[]} action.payload.selectedTeamsAndEmployees
     * @param {string} action.payload.selectedTeamsAndEmployees.id
     * @param {object[]} action.payload.selectedTeamsAndEmployees.employees
     * @param {string} action.payload.selectedTeamsAndEmployees.employees.id
     */
    teamSalaryDistributionInformationSet: (state, action) => {
      const { sourceAccountId, selectedTeamsAndEmployees, period } =
        action.payload
      state.teamSalaryDistribution.state =
        TEAM_SALARY_DISTRIBUTION.INFORMATION_SET
      state.teamSalaryDistribution.period = period
      state.teamSalaryDistribution.sourceAccountId = sourceAccountId
      state.teamSalaryDistribution.selectedTeamsAndEmployees =
        selectedTeamsAndEmployees
    },
    teamSalaryDistributionConfirmation: (state) => {
      state.teamSalaryDistribution.state = TEAM_SALARY_DISTRIBUTION.CONFIRMATION
      state.teamSalaryDistribution.isConfirmed = false
      state.teamSalaryDistribution.error = undefined
    },
    teamSalaryDistributionAuthorizing: (state) => {
      state.teamSalaryDistribution.state = TEAM_SALARY_DISTRIBUTION.AUTHORIZING
      state.teamSalaryDistribution.isConfirmed = true
    },
    teamSalaryDistributionAborting: (state) => {
      state.teamSalaryDistribution.state = TEAM_SALARY_DISTRIBUTION.ABORTING
    },
    teamSalaryDistributionRejected: (state) => {
      state.teamSalaryDistribution.state = TEAM_SALARY_DISTRIBUTION.REJECTED
    },
    teamSalaryDistributionFinished: (state) => {
      state.teamSalaryDistribution = initialState.teamSalaryDistribution
    },
  },
  extraReducers: (builder) => {
    // -------- Fetch Payrolls -------- \\
    builder.addCase(fetchPayrolls.pending, (state) => {
      state.isPayrollsLoading = true
    })

    builder.addCase(fetchPayrolls.fulfilled, (state, action) => {
      state.isPayrollsLoading = false
      state.payrollsError = undefined
      state.payrolls = formatSalaryDistributions(action.payload.data.content)
      state.isFirst = action.payload.data.first
      state.isLast = action.payload.data.last
      state.pageNumber = action.payload.data.pageNumber
      state.pageSize = action.payload.data.pageSize
      state.totalElements = action.payload.data.totalElements
      state.totalPages = action.payload.data.totalPages
    })

    builder.addCase(fetchPayrolls.rejected, (state, action) => {
      state.isPayrollsLoading = false
      state.payrollsError = {
        name: action.error.name,
        message: action.error.message,
        code: action.error.code,
      }
    })

    // -------- File Salary Distribution -------- \\
    builder.addCase(uploadFileSalaryDistribution.pending, (state) => {
      state.fileSalaryDistribution.state = FILE_SALARY_DISTRIBUTION.UPLOADING
    })
    builder.addCase(uploadFileSalaryDistribution.fulfilled, (state, action) => {
      state.fileSalaryDistribution.state = FILE_SALARY_DISTRIBUTION.UPLOADED
      state.fileSalaryDistribution.uploadPrecentage = 0
      state.currentSalaryDistributionDetails = {
        createdAt: action.payload.data.createdAt,
        sourceAccountName: action.payload.data.sourceAccountName,
        employeesCount: action.payload.data.employeesCount,
        fee: action.payload.data.fee,
        id: action.payload.data.id,
        payees: action.payload.data.payees,
        period: action.payload.data.period,
        status: action.payload.data.status,
        totalSalary: action.payload.data.totalSalary,
        totalValue: action.payload.data.totalValue,
        type: action.payload.data.type,
        fileName: action.payload.fileName,
      }
    })
    builder.addCase(uploadFileSalaryDistribution.rejected, (state, action) => {
      state.fileSalaryDistribution.uploadPrecentage = 0

      if (action.meta.aborted) {
        state.fileSalaryDistribution.error = undefined
        state.fileSalaryDistribution.state = FILE_SALARY_DISTRIBUTION.STARTED
      } else {
        let error = { message: "web_c_general_error_massage" }

        if (action.meta.rejectedWithValue) {
          if (Array.isArray(action?.payload?.errors)) {
            const [firstError] = action.payload.errors
            // FIXME the error detail should always be returned
            // when Backend adds the detail this should be removed
            // and then -> error={...firstError,recoverable:---}
            const errorMessage =
              MAP_PAYROLL_CSV_ERROR_CODE_TO_MESSAGE[firstError.code] ===
              undefined
                ? "web_c_general_error_massage"
                : MAP_PAYROLL_CSV_ERROR_CODE_TO_MESSAGE[firstError.code]

            error = {
              ...firstError,
              detail:
                firstError.detail === "" ? errorMessage : firstError.detail,
              recoverable: FILE_RECOVERABLE_ERROR_CODES.includes(
                firstError.code
              ),
            }
          }
        }

        state.fileSalaryDistribution.error = error
        state.fileSalaryDistribution.state =
          FILE_SALARY_DISTRIBUTION.UPLOAD_ERRORED
      }
    })

    builder.addCase(
      receiveUploadFileSalaryDistributionProgress,
      (state, action) => {
        state.fileSalaryDistribution.uploadPrecentage = action.payload
      }
    )

    // -------- File Salary Distribution Authorization -------- \\
    builder.addCase(fileSalaryDistributionAuthorization.pending, (state) => {
      state.fileSalaryDistribution.state = FILE_SALARY_DISTRIBUTION.DISTRIBUTING
    })

    builder.addCase(fileSalaryDistributionAuthorization.fulfilled, (state) => {
      state.fileSalaryDistribution.state = FILE_SALARY_DISTRIBUTION.DISTRIBUTED
    })

    builder.addCase(
      fileSalaryDistributionAuthorization.rejected,
      (state, action) => {
        let error = { message: "web_c_general_error_massage" }

        if (action.meta.rejectedWithValue) {
          if (Array.isArray(action?.payload?.errors)) {
            const [firstError] = action.payload.errors
            error = {
              ...firstError,
              recoverable: FILE_RECOVERABLE_ERROR_CODES.includes(
                firstError.code
              ),
            }
          }
        }

        state.fileSalaryDistribution.error = error
        state.fileSalaryDistribution.isConfirmed = false
        state.fileSalaryDistribution.state =
          FILE_SALARY_DISTRIBUTION.AUTHORIZATION_ERROR
      }
    )

    // -------- Team Salary Distribution -------- \\
    builder.addCase(initiateTeamSalaryDistribution.pending, (state) => {
      state.teamSalaryDistribution.isInitiatingSalaryDistribution = true
    })

    builder.addCase(
      initiateTeamSalaryDistribution.fulfilled,
      (state, action) => {
        const { id } = action.payload.data
        state.teamSalaryDistribution.state =
          TEAM_SALARY_DISTRIBUTION.CONFIRMATION
        state.teamSalaryDistribution.isInitiatingSalaryDistribution = false
        state.teamSalaryDistribution.salaryDistributionId = id
        state.teamSalaryDistribution.configurationHash = hashItems(
          state.teamSalaryDistribution.selectedTeamsAndEmployees,
          state.teamSalaryDistribution.sourceAccountId,
          state.teamSalaryDistribution.period,
          id
        )
      }
    )

    builder.addCase(
      initiateTeamSalaryDistribution.rejected,
      (state, action) => {
        let error = {
          name: action.error.name,
          message: action.error.message,
          code: action.error.code,
        }

        if (
          action.meta.rejectedWithValue &&
          Array.isArray(action?.payload?.errors)
        ) {
          const [firstError] = action.payload.errors

          error = {
            ...firstError,
            recoverable: TEAM_RECOVERABLE_ERROR_CODES.includes(firstError.code),
          }
        }

        state.teamSalaryDistribution.isInitiatingSalaryDistribution = false
        state.teamSalaryDistribution.error = error
      }
    )

    builder.addCase(fetchSalaryDistributionDetails.pending, (state) => {
      state.isCurrentSalaryDistributionDetailsLoading = true
    })

    builder.addCase(
      fetchSalaryDistributionDetails.fulfilled,
      (state, action) => {
        state.isCurrentSalaryDistributionDetailsLoading = false
        state.currentSalaryDistributionDetails = action.payload.data
      }
    )

    builder.addCase(
      fetchSalaryDistributionDetails.rejected,
      (state, action) => {
        state.isCurrentSalaryDistributionDetailsLoading = false
        state.currentSalaryDistributionDetailsError = {
          name: action.error.name,
          message: action.error.message,
          code: action.error.code,
        }
      }
    )

    builder.addCase(teamSalaryDistributionAuthorization.pending, (state) => {
      state.teamSalaryDistribution.state = TEAM_SALARY_DISTRIBUTION.DISTRIBUTING
    })

    builder.addCase(teamSalaryDistributionAuthorization.fulfilled, (state) => {
      state.teamSalaryDistribution.state = TEAM_SALARY_DISTRIBUTION.DISTRIBUTED
    })

    builder.addCase(
      teamSalaryDistributionAuthorization.rejected,
      (state, action) => {
        let error = { message: "web_c_general_error_massage" }

        if (action.meta.rejectedWithValue) {
          if (typeof action?.payload?.message === "string") {
            error.message = action?.payload?.message
          }

          if (Array.isArray(action?.payload?.errors)) {
            const [firstError] = action.payload.errors
            error = {
              ...firstError,
              recoverable: TEAM_RECOVERABLE_ERROR_CODES.includes(
                firstError.code
              ),
              message: firstError.detail,
            }
          }
        }

        state.teamSalaryDistribution.state = TEAM_SALARY_DISTRIBUTION.ERRORED
        state.teamSalaryDistribution.error = error
      }
    )
  },
})

export const {
  // -------- File Salary Distribution -------- \\
  fileSalaryDistributionStartedIdle,
  fileSalaryDistributionStarted,
  fileSalaryDistributionUploaded,
  fileSalaryDistributionFinished,
  fileSalaryDistributionUploadAbortFile,
  fileSalaryDistributionConfirmation,
  fileSalaryDistributionConfirmed,
  fileSalaryDistributionRejected,
  fileSalaryDistributionAbortAuthorization,
  // -------- Current Salary Distribution Details -------- \\
  resetCurrentSalaryDistributionDetails,
  // -------- Team Salary Distribution -------- \\
  teamSalaryDistributionStarted,
  teamSalaryDistributionInformationSet,
  teamSalaryDistributionConfirmation,
  teamSalaryDistributionAuthorizing,
  teamSalaryDistributionAborting,
  teamSalaryDistributionRejected,
  teamSalaryDistributionFinished,
} = payrollSlice.actions

export default payrollSlice.reducer
