/**
 * @typedef UserDeletionState
 * @property {string} IDLE
 * @property {string} STARTED
 * @property {string} AUTHORIZING
 * @property {string} ABORTING
 * @property {string} REJECTED
 * @property {string} DELETED
 * @property {string} ERRORED
 */

/**
 * @type {UserDeletionState}
 * @enum {string}
 */
export const USER_DELETION_STATE = {
  IDLE: "IDLE",
  STARTED: "STARTED",
  AUTHORIZING: "AUTHORIZING",
  ABORTING: "ABORTING",
  REJECTED: "REJECTED",
  DELETED: "DELETED",
  ERRORED: "ERRORED",
}
