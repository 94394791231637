import PropTypes from "prop-types"

const SectionLink = ({ id, name, icon, onClick }) => (
  <button
    type="button"
    data-testid={id}
    onClick={onClick}
    className="export-button details-sidebar-body__section-link"
  >
    {icon} {name}
  </button>
)

SectionLink.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  icon: PropTypes.node,
}

SectionLink.defaultProps = {
  id: "",
  onClick: undefined,
  icon: "",
}

export default SectionLink
