import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import classNames from "classnames"
import { useTranslation } from "react-i18next"

import { ReactComponent as ChevronDownIcon } from "../../assets/icons/chevron.svg"
import useDir from "../../shared/hooks/useDir"

const Breadcrumbs = ({ title, breadcrumbsList, children, classes }) => {
  const { t } = useTranslation()
  const { isRTL } = useDir()

  return (
    <nav className={classNames("breadcrumbs-navbar", classes)}>
      <div className="breadcrumbs-navbar__container">
        <h1
          className="breadcrumbs-navbar__title"
          data-testid="breadcrumbs-navbar-title"
        >
          {t(title)}
        </h1>
        <ul className="breadcrumbs-navbar__breadcrumbs-wrapper">
          {breadcrumbsList?.map((breadcrumb, index) => {
            const isLast = index === breadcrumbsList.length - 1
            return breadcrumb.href ? (
              <Link
                to={breadcrumb.href}
                key={breadcrumb.text}
                data-testid={breadcrumb.text}
                className={classNames("breadcrumbs-navbar__breadcrumbs-item", {
                  "breadcrumbs-navbar__breadcrumbs-item--active": isLast,
                })}
              >
                {t(breadcrumb.text)}
                {!isLast && (
                  <ChevronDownIcon
                    className={classNames(
                      "breadcrumbs-navbar__breadcrumbs-arrow",
                      {
                        "breadcrumbs-navbar__breadcrumbs-arrow--rotated": isRTL,
                      }
                    )}
                  />
                )}
              </Link>
            ) : (
              <li
                key={breadcrumb.text}
                data-testid={breadcrumb.text}
                className={classNames("breadcrumbs-navbar__breadcrumbs-item", {
                  "breadcrumbs-navbar__breadcrumbs-item--active": isLast,
                })}
              >
                {t(breadcrumb.text)}
                {!isLast && (
                  <ChevronDownIcon
                    className={classNames(
                      "breadcrumbs-navbar__breadcrumbs-arrow",
                      {
                        "breadcrumbs-navbar__breadcrumbs-arrow--rotated": isRTL,
                      }
                    )}
                    width={16}
                    height={16}
                  />
                )}
              </li>
            )
          })}
        </ul>
      </div>
      <div className="action-buttons">{children}</div>
    </nav>
  )
}

Breadcrumbs.defaultProps = {
  children: undefined,
  classes: undefined,
}

Breadcrumbs.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbsList: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      href: PropTypes.string,
    })
  ).isRequired,
  children: PropTypes.node,
  classes: PropTypes.string,
}

export default Breadcrumbs
