/**
 *
 * @param { import("../../api/endpoints/payroll").SalaryDistribution[] } [salaryDistributions=[]]
 *
 */
export const formatSalaryDistributions = (salaryDistributions = []) =>
  salaryDistributions.map((salaryDistribution) => {
    const [year, month] = salaryDistribution.period.split("-")

    return {
      ...salaryDistribution,
      year: Number.parseInt(year, 10),
      month: Number.parseInt(month, 10),
    }
  })
