import * as yup from "yup"
import {
  passwordStrengthSchema,
  repeatedPasswordSchema,
} from "../../../lib/general"
import {
  ALPHABETICAL_NAME_VALIDATOR_REGEX,
  PHONE_NUMBER_REGEX,
} from "../general"

/**
 * @enum {string}
 */
export const USER_CREATION_STATE = {
  IDLE: "IDLE",
  STARTED: "STARTED",
  INFORMATION_SET: "INFORMATION_SET",
  CONFIRMATION: "CONFIRMATION",
  CONFIRMED: "CONFIRMED",
  AUTHORIZING: "AUTHORIZING",
  ABORTING: "ABORTING",
  REJECTED: "REJECTED",
  CREATING: "CREATING",
  CREATED: "CREATED",
  ERRORED: "ERRORED",
}
export const getStepNumberFromCreateUserState = (state) => {
  switch (state) {
    case USER_CREATION_STATE.STARTED:
    case USER_CREATION_STATE.INFORMATION_SET:
      return 0
    case USER_CREATION_STATE.CONFIRMATION:
    case USER_CREATION_STATE.CONFIRMED:
    case USER_CREATION_STATE.AUTHORIZING:
    case USER_CREATION_STATE.ABORTING:
    case USER_CREATION_STATE.REJECTED:
    case USER_CREATION_STATE.CREATING:
    case USER_CREATION_STATE.CREATED:
    case USER_CREATION_STATE.ERRORED:
      return 1
    case USER_CREATION_STATE.IDLE:
    default:
      return -1
  }
}

export const PHONE_NUMBER_USED_BEFORE = "PHONE_NUMBER_USED_BEFORE"

export const createUserSchema = yup.object({
  phoneNumber: yup
    .string()
    .required("web_c_users_addusermodal_phonenumber_validation1")
    .matches(
      PHONE_NUMBER_REGEX,
      "web_c_users_addusermodal_phonenumber_validation2"
    )
    .test(
      "isn't used before",
      (_, ctx) =>
        ctx.options.context.isPhoneNumberUsed === false ||
        ctx.createError({
          type: PHONE_NUMBER_USED_BEFORE,
          message: "web_c_users_addusermodal_phonenumber_validation3",
        })
    ),
  name: yup
    .string()
    .required("web_c_users_addusermodal_name_validation4")
    .min(2, "web_c_users_addusermodal_name_validation2")
    .max(30, "web_c_users_addusermodal_name_validation3")
    .matches(
      ALPHABETICAL_NAME_VALIDATOR_REGEX,
      "web_c_users_addusermodal_name_validation1"
    ),
  newPassword: passwordStrengthSchema,
  repeatedPassword: repeatedPasswordSchema,
  role: yup
    .object({ id: yup.string().required() })
    .strict(true)
    .required("web_c_users_addusermodal_role_validation1"),
})
