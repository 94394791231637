import React, { useState, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import SelectBox from "../../selectBox/SelectBox"
import useDir from "../../../shared/hooks/useDir"
import Avatar from "../../../assets/icons/avatar.svg"
import { ReactComponent as LogoutIcon } from "../../../assets/icons/logout.svg"
import { ReactComponent as UserIcon } from "../../../assets/icons/user.svg"
import { ReactComponent as LockIcon } from "../../../assets/icons/lock-closed.svg"
import { ReactComponent as TranslateIcon } from "../../../assets/icons/translate.svg"
import { LANGUAGE_SELECT_OPTIONS } from "../../../shared/constants/user"
import { getCurrentLocale } from "../../../lib/i18n"

const AccountSettings = ({
  userData,
  setIsSettingsSidebarOpen,
  setIsConfirmBoxOpen,
}) => {
  const { i18n, t } = useTranslation()

  const preparedLanguageOptions = useMemo(
    () =>
      LANGUAGE_SELECT_OPTIONS.map(({ id, text, lang }) => ({
        id: id.toString(),
        text: t(text),
        lang,
      })),
    [t]
  )

  const [selectedLanguage, setSelectedLanguage] = useState(
    preparedLanguageOptions[0]
  )
  const { isRTL } = useDir()

  const dropdownHandler = (current) => {
    if (current !== undefined) {
      i18n.changeLanguage(current.lang)
    }
  }

  useEffect(() => {
    const newLanguage = preparedLanguageOptions.find(
      (option) => option.lang === JSON.parse(getCurrentLocale())?.lang
    )
    setSelectedLanguage(newLanguage)
  }, [preparedLanguageOptions, t])

  return (
    <div className="account-settings" data-testid="account-settings">
      <div className="account-settings__header">
        <img
          className="account-settings__icon"
          src={userData.iconUrl || Avatar}
          alt="user"
          width={56}
          height={56}
          data-testid="user-icon"
        />
        <h4
          data-testid="account-settings-user-name"
          className="account-settings__user-name"
        >
          {userData?.name}
        </h4>
        <p
          data-testid="account-settings-user-role"
          className="account-settings__user-role"
        >
          {userData?.roleName}
        </p>
      </div>
      <div className="account-settings__actions">
        <p className="account-settings__section-header">
          {t("web_c_accsettingsidebar_account_label")}
        </p>
        <div className="account-settings__action-list">
          {/* first two buttons are hidden they will be used in future tasks */}
          <div hidden>
            <button
              type="button"
              className="account-settings__action button button--invisible"
            >
              <UserIcon width={18} height={18} />
              <p>{t("web_c_accsettingsidebar_myaccount_button")}</p>
            </button>
            <button
              type="button"
              className="account-settings__action button button--invisible"
            >
              <LockIcon width={18} height={18} />
              <p>{t("web_c_accsettingsidebar_lockscreen_button")}</p>
            </button>
          </div>
          <button
            type="button"
            className="account-settings__action button button--invisible"
            data-testid="account-settings-action-logout"
            onClick={() => {
              setIsSettingsSidebarOpen(false)
              setIsConfirmBoxOpen(true)
            }}
          >
            <LogoutIcon
              className="account-settings__logout-icon"
              width={18}
              height={18}
            />
            <p>{t("web_c_accsettingsidebar_logout_button")}</p>
          </button>
        </div>
      </div>
      <div className="account-settings__settings">
        <p className="account-settings__settings-header">
          {t("web_c_accsettingsidebar_setting_label")}
        </p>
        <div className="account-settings__settings-list">
          <div role="presentation" className="account-settings__setting">
            <TranslateIcon width={18} height={18} />
            <p>{t("web_c_accsettingsidebar_language_label")}</p>
          </div>
          <div className="account-settings__settings-lang">
            <SelectBox
              id="account-settings-language-options"
              label=""
              selectedItems={selectedLanguage}
              setSelectedItems={dropdownHandler}
              options={preparedLanguageOptions}
              isRTL={isRTL}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

AccountSettings.propTypes = {
  userData: PropTypes.shape({
    iconUrl: PropTypes.string,
    name: PropTypes.string,
    roleName: PropTypes.string,
  }).isRequired,
  setIsSettingsSidebarOpen: PropTypes.func.isRequired,
  setIsConfirmBoxOpen: PropTypes.func.isRequired,
}

export default AccountSettings
