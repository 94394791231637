import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import {
  checkingIfEmployeeHasFIB,
  addEmployee,
  getEmployees,
  deleteEmployee,
  editEmployee,
  getEmployeeDetails,
} from "../../api/endpoints/employees"
import { ADD_EMPLOYEE_STATE } from "../../shared/constants/employees/add-employee"
import { EDIT_EMPLOYEE_STATE } from "../../shared/constants/employees/edit-employee"
import {
  GENERAL_ERROR,
  IRAQ_DAILING_CODE,
} from "../../shared/constants/general"
import { EMPLOYEE_DELETION_STATE } from "../../shared/constants/employees/delete-employee"

const SLICE_NAME = "employees"

export const fetchEmployeesPayloadCreator = async ({
  size,
  page,
  teamId,
  searchQuery,
  teamlessOnly,
}) => {
  const response = await getEmployees({
    size,
    page,
    teamId,
    searchQuery,
    teamlessOnly,
  })
  const { data, status, statusText } = response
  return { data, status, statusText }
}

export const fetchEmployees = createAsyncThunk(
  `${SLICE_NAME}/fetchEmployees`,
  fetchEmployeesPayloadCreator
)

/** get single employee detail */

export const fetchEmployeeDetailsPayloadCreator = async (employeeId) => {
  const response = await getEmployeeDetails(employeeId)
  const { data, status, statusText } = response
  return { data, status, statusText }
}

export const fetchEmployeeDetails = createAsyncThunk(
  `${SLICE_NAME}/fetchEmployeeDetails`,
  fetchEmployeeDetailsPayloadCreator
)

/**
 *
 * @param {object} params
 * @param {number} params.size
 * @param {number} params.page
 * @param {string} [params.teamId]
 * @param {string} [params.searchQuery]
 * @returns
 */
export const fetchTeamlessEmployeesPayloadCreator = async ({
  size,
  page,
  teamId,
  searchQuery,
}) =>
  fetchEmployeesPayloadCreator({
    size,
    page,
    teamId,
    searchQuery,
    teamlessOnly: true,
  })

export const fetchTeamlessEmployees = createAsyncThunk(
  `${SLICE_NAME}/fetchTeamlessEmployees`,
  fetchTeamlessEmployeesPayloadCreator
)

export const checkIfEmployeeHasFIBPayloadCreator = async (
  { phoneNumber, dateOfBirth },
  { rejectWithValue }
) => {
  try {
    const response = await checkingIfEmployeeHasFIB({
      dialingCode: IRAQ_DAILING_CODE,
      phoneNumber,
      dateOfBirth,
    })
    const { data, status, statusText } = response
    return { data, status, statusText }
  } catch (error) {
    return rejectWithValue(error.response.data)
  }
}

export const checkIfEmployeeHasFIB = createAsyncThunk(
  `${SLICE_NAME}/checkEmployeeExistance`,
  checkIfEmployeeHasFIBPayloadCreator
)

export const employeeAddition = createAsyncThunk(
  `${SLICE_NAME}/employeeAddition`,
  async (_, thunkAPI) => {
    const { elevatedToken } = thunkAPI.getState().otac

    if (!elevatedToken) {
      throw new Error("web_c_general_notauthorized_error_text")
    }
    try {
      const {
        addOrEditEmployeeInfo: { userId },
        employeeAddition: process,
      } = thunkAPI.getState().employees
      const { dateOfBirth, name, phoneNumber, position, salary, team } = process

      const response = await addEmployee(
        {
          dateOfBirth,
          name,
          phoneNumber: `${IRAQ_DAILING_CODE}${phoneNumber}`,
          position,
          salary,
          teamId: team?.id,
          userId,
        },
        {
          headers: { Authorization: `Bearer ${elevatedToken}` },
        }
      )
      const { data, status, statusText } = response
      return { data, status, statusText }
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data)
    }
  }
)

export const employeeDeletion = createAsyncThunk(
  `${SLICE_NAME}/employeeDeletion`,
  async (_, thunkAPI) => {
    const { elevatedToken } = thunkAPI.getState().otac

    if (!elevatedToken) {
      throw new Error("web_c_general_notauthorized_error_text")
    }

    const { employeeId } = thunkAPI.getState().employees.employeeDeletion

    const response = await deleteEmployee(employeeId, {
      headers: {
        Authorization: `Bearer ${elevatedToken}`,
      },
    })
    const { data, status, statusText } = response
    return { data, status, statusText }
  }
)

export const editingEmployee = createAsyncThunk(
  `${SLICE_NAME}/editingEmployee`,
  async (_, thunkAPI) => {
    const { elevatedToken } = thunkAPI.getState().otac

    if (!elevatedToken) {
      throw new Error("web_c_general_notauthorized_error_text")
    }
    try {
      const {
        addOrEditEmployeeInfo: { userId },
        editingEmployee: process,
      } = thunkAPI.getState().employees
      const {
        dateOfBirth,
        name,
        phoneNumber,
        position,
        salary,
        team,
        employeeId,
      } = process

      const response = await editEmployee(
        {
          employeeId,
          dateOfBirth,
          name,
          phoneNumber: `${IRAQ_DAILING_CODE}${phoneNumber}`,
          position,
          salary,
          teamId: team?.id,
          userId,
        },
        {
          headers: { Authorization: `Bearer ${elevatedToken}` },
        }
      )
      const { data, status, statusText } = response
      return { data, status, statusText }
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data)
    }
  }
)

export const initialState = {
  /**
   * teamlessEmployees is an array of objects that gives information
   * about each employee that isn't attached to any team.
   * @type {object[]}
   */
  teamlessEmployees: undefined,

  /**
   * employees is an array of objects that gives information
   * about each employee in employee page
   * @type {object[]}
   */
  employees: undefined,

  /**
   * represents the state of loading the employees
   * @type {boolean}
   */
  isEmployeesLoading: false,

  /**
   * represents error of employees if any
   * @type {object}
   */
  employeesError: undefined,

  /**
   * responsible to hold add employee process state
   * @type {object}
   */
  employeeAddition: {
    /**
     * represents the state of the add employee process
     * @type { ADD_EMPLOYEE_STATE }
     */
    state: ADD_EMPLOYEE_STATE.IDLE,

    /**
     * the employee's the date of birth
     * @type {string}
     */
    dateOfBirth: undefined,

    /**
     * the name of employee
     * @type {string}
     */
    name: undefined,

    /**
     * the phone number of employee
     * @type {string}
     */
    phoneNumber: undefined,

    /**
     * the position of employee
     * @type {string}
     */
    position: undefined,

    /**
     * the salary of employee
     * @type {object}
     * @property {number} amount
     * @property {string} currency
     */
    salary: undefined,

    /**
     * the team ID that the employee will belong
     * @type {object}
     * @property {string}
     * @property {string}
     */
    team: undefined,

    /**
     * the error if occurred while adding the employee
     * @type {object}
     */
    error: undefined,
  },

  /**
   * common informations between
   * add and edit employee process
   * @type {object}
   */
  addOrEditEmployeeInfo: {
    /**
     * represents the state of loading
     * for check FIB account existance
     * @type {boolean}
     */
    isEmployeeHasFIBLoading: false,

    /**
     * the account IBAN of the user corresponding to
     * the phoneNumber linked to the FIB account
     * @type {string}
     */
    accountIban: undefined,

    /**
     * an object of error description
     * @type {Object[]}
     */
    accountIbanErrors: undefined,

    /**
     * whether the dateOfBirth has matched the corresponding
     * FIB user's dateOfBirth or not.
     * @type {boolean}
     */
    isDateOfBirthMatched: undefined,

    /**
     * the ID of the FIB account corresponding to the
     * phoneNumber & IBAN.
     * @type {string}
     */
    userId: undefined,
  },

  /**
   * responsible to hold edit employee process state
   * @type {object}
   */
  editingEmployee: {
    /**
     * represents the state of the edit employee process
     * @type { EDIT_EMPLOYEE_STATE }
     */
    state: EDIT_EMPLOYEE_STATE.IDLE,

    /**
     * the employees id
     * @type {string}
     */
    employeeId: undefined,

    /**
     * the employees the date of birth
     * @type {string}
     */
    dateOfBirth: undefined,

    /**
     * the name of employee
     * @type {string}
     */
    name: undefined,

    /**
     * the phone number of employee
     * @type {string}
     */
    phoneNumber: undefined,

    /**
     * the position of employee
     * @type {string}
     */
    position: undefined,

    /**
     * the salary of employee
     * @type {object}
     * @property {number} amount
     * @property {string} currency
     */
    salary: undefined,

    /**
     * the team ID that the employee will belong
     * @type {object}
     * @property {stirng}
     * @property {string}
     */
    team: undefined,

    /**
     * the error if occurred while adding the employee
     * @type {object}
     */
    error: undefined,
  },

  /**
   * State for tracking employee deletion process
   */
  employeeDeletion: {
    /**
     * @type { EMPLOYEE_DELETION_STATE.IDLE | EMPLOYEE_DELETION_STATE.STARTED }
     */
    state: EMPLOYEE_DELETION_STATE.IDLE,

    /**
     * @type {string}
     */
    employeeId: undefined,

    /**
     * @type {string}
     */
    name: undefined,

    /**
     * @type {import("@reduxjs/toolkit").SerializedError}
     */
    error: undefined,
  },

  /**
   * defined the current state of the table
   * and empowering the pagination of the page
   */
  isFirst: false,
  isLast: false,
  pageNumber: undefined,
  pageSize: undefined,
  totalElements: undefined,
  totalPages: undefined,

  /**
   * Whether the employee details loading or not.
   */
  isEmployeeDetailsLoading: false,
  /**
   * The detail of one employee.
   */
  employeeDetails: undefined,
  /**
   * Error that happened while loading employee details.
   */
  employeeDetailsLoadingError: undefined,
}

const employeesSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    //  Add Employee  \\
    employeeAdditionStarted: (state) => {
      state.employeeAddition.state = ADD_EMPLOYEE_STATE.STARTED
    },
    employeeAdditionInformationSet: (state, action) => {
      state.employeeAddition.state = ADD_EMPLOYEE_STATE.INFORMATION_SET
      state.employeeAddition = {
        ...state.employeeAddition,
        name: action.payload.name,
        dateOfBirth: action.payload.dateOfBirth,
        phoneNumber: action.payload.phoneNumber,
        position: action.payload.position,
        salary: action.payload.salary,
        team: action.payload.team,
      }
    },
    employeeAdditionAuthorizing: (state) => {
      state.employeeAddition.state = ADD_EMPLOYEE_STATE.AUTHORIZING
    },
    employeeAdditionAborting: (state) => {
      state.employeeAddition.state = ADD_EMPLOYEE_STATE.ABORTING
    },
    employeeAdditionRejected: (state) => {
      state.employeeAddition.state = ADD_EMPLOYEE_STATE.REJECTED
    },
    employeeAdditionFinished: (state) => {
      state.employeeAddition = initialState.employeeAddition
      state.addOrEditEmployeeInfo = initialState.addOrEditEmployeeInfo
    },
    // -------- Employee Deletion -------- \\
    /**
     * @typedef DeleteEmployeeStartedPayloadAction
     * @property {string} id - employee id
     * @property {string} name - employee name
     *
     *
     * @param { import("@reduxjs/toolkit").PayloadAction<DeleteEmployeeStartedPayloadAction> } action
     */

    employeeDeletionStarted: (state, action) => {
      state.employeeDeletion.state = EMPLOYEE_DELETION_STATE.STARTED
      state.employeeDeletion.employeeId = action.payload.id
      state.employeeDeletion.name = action.payload.name
    },

    employeeDeletionAuthorizing: (state) => {
      state.employeeDeletion.state = EMPLOYEE_DELETION_STATE.AUTHORIZING
    },

    employeeDeletionAborting: (state) => {
      state.employeeDeletion.state = EMPLOYEE_DELETION_STATE.ABORTING
    },

    employeeDeletionRejected: (state) => {
      state.employeeDeletion.state = EMPLOYEE_DELETION_STATE.REJECTED
    },

    employeeDeletionAuthorized: (state) => {
      state.employeeDeletion.state = EMPLOYEE_DELETION_STATE.AUTHORIZED
    },

    employeeDeletionFinished: (state) => {
      state.employeeDeletion = initialState.employeeDeletion
    },
    // -------- Edit Employee -------- \\
    editingEmployeeStarted: (state, action) => {
      state.editingEmployee.state = EDIT_EMPLOYEE_STATE.STARTED
      state.editingEmployee = {
        ...state.editingEmployee,
        employeeId: action.payload.id,
        name: action.payload.name,
        dateOfBirth: action.payload.dateOfBirth,
        phoneNumber: action.payload.phoneNumber,
        position: action.payload.position,
        salary: action.payload.salary,
        team: action.payload.team,
      }
    },
    editingEmployeeInformationSet: (state, action) => {
      state.editingEmployee.state = EDIT_EMPLOYEE_STATE.INFORMATION_SET
      state.editingEmployee = {
        ...state.editingEmployee,
        name: action.payload.name,
        dateOfBirth: action.payload.dateOfBirth,
        phoneNumber: action.payload.phoneNumber,
        position: action.payload.position,
        salary: action.payload.salary,
        team: action.payload.team,
      }
    },
    editingEmployeeAuthorizing: (state) => {
      state.editingEmployee.state = EDIT_EMPLOYEE_STATE.AUTHORIZING
    },
    editingEmployeeAborting: (state) => {
      state.editingEmployee.state = EDIT_EMPLOYEE_STATE.ABORTING
    },
    editingEmployeeRejected: (state) => {
      state.editingEmployee.state = EDIT_EMPLOYEE_STATE.REJECTED
    },
    editingEmployeeFinished: (state) => {
      state.editingEmployee = initialState.editingEmployee
      state.addOrEditEmployeeInfo = initialState.addOrEditEmployeeInfo
    },
    // -------- Add and Edit Employee -------- \\
    checkAccountExistanceEmployeeReset: (state) => {
      state.addOrEditEmployeeInfo = initialState.addOrEditEmployeeInfo
    },
    // --------- Dismiss Employee Details ------ \\
    dismissEmployeeDetails: (state) => {
      state.isEmployeeDetailsLoading = false
      state.employeeDetails = undefined
      state.employeeDetailsLoadingError = undefined
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEmployees.pending, (state) => {
      state.isEmployeesLoading = true
    })
    builder.addCase(fetchEmployees.fulfilled, (state, action) => {
      state.isEmployeesLoading = false
      state.employees = action.payload.data.content
      state.isFirst = action.payload.data.first
      state.isLast = action.payload.data.last
      state.pageNumber = action.payload.data.pageNumber
      state.pageSize = action.payload.data.pageSize
      state.totalElements = action.payload.data.totalElements
      state.totalPages = action.payload.data.totalPages
    })
    builder.addCase(fetchEmployees.rejected, (state, action) => {
      state.isEmployeesLoading = false
      state.employeesError = {
        name: action.error.name,
        message: action.error.message,
        code: action.error.code,
      }
    })
    builder.addCase(fetchTeamlessEmployees.fulfilled, (state, action) => {
      state.teamlessEmployees = action.payload.data.content
    })
    builder.addCase(fetchTeamlessEmployees.rejected, (state, action) => {
      state.employeesError = {
        name: action.error.name,
        message: action.error.message,
        code: action.error.code,
      }
    })

    //  Add Employee  \\
    builder.addCase(checkIfEmployeeHasFIB.pending, (state) => {
      state.addOrEditEmployeeInfo.isEmployeeHasFIBLoading = true
    })
    builder.addCase(checkIfEmployeeHasFIB.fulfilled, (state, action) => {
      state.addOrEditEmployeeInfo.isEmployeeHasFIBLoading = false
      state.addOrEditEmployeeInfo.accountIbanErrors = undefined
      state.addOrEditEmployeeInfo.userId = action.payload.data.userId
      state.addOrEditEmployeeInfo.accountIban = action.payload.data.accountIban
      state.addOrEditEmployeeInfo.isDateOfBirthMatched =
        action.payload.data.dateOfBirthMatched
    })
    builder.addCase(checkIfEmployeeHasFIB.rejected, (state, action) => {
      state.addOrEditEmployeeInfo.isEmployeeHasFIBLoading = false
      state.addOrEditEmployeeInfo.accountIban = undefined
      state.addOrEditEmployeeInfo.accountIbanErrors = action.payload.errors
    })
    // -------- Add Employee --------- \\
    builder.addCase(employeeAddition.fulfilled, (state) => {
      state.employeeAddition.state = ADD_EMPLOYEE_STATE.ADDED
    })
    builder.addCase(employeeAddition.rejected, (state, action) => {
      state.employeeAddition.state = ADD_EMPLOYEE_STATE.ERRORED

      const preparedError = {
        code: GENERAL_ERROR,
        message: "web_c_general_error_massage",
      }

      if (Array.isArray(action.payload?.errors)) {
        const [firstError] = action.payload.errors
        preparedError.code = firstError.code
        preparedError.message = firstError.detail
      } else if (action.error.message !== "Rejected") {
        preparedError.message = action.error.message
      }

      state.employeeAddition.error = preparedError
    })

    // -------- Employee Deletion -------- \\
    builder.addCase(employeeDeletion.pending, (state) => {
      state.employeeDeletion.state = EMPLOYEE_DELETION_STATE.DELETING
    })

    builder.addCase(employeeDeletion.fulfilled, (state) => {
      state.employeeDeletion.state = EMPLOYEE_DELETION_STATE.DELETED
    })

    builder.addCase(employeeDeletion.rejected, (state, action) => {
      state.employeeDeletion.state = EMPLOYEE_DELETION_STATE.ERRORED
      state.employeeDeletion.error = {
        name: action.error.name,
        message: action.error.message,
      }
    })
    // -------- Edit Employee -------- \\
    builder.addCase(editingEmployee.fulfilled, (state) => {
      state.editingEmployee.state = EDIT_EMPLOYEE_STATE.EDITED
    })
    builder.addCase(editingEmployee.rejected, (state, action) => {
      state.editingEmployee.state = EDIT_EMPLOYEE_STATE.ERRORED

      const preparedError = {
        code: GENERAL_ERROR,
        message: "web_c_general_error_massage",
      }

      if (Array.isArray(action.payload?.errors)) {
        const [firstError] = action.payload.errors

        preparedError.code = firstError.code
        preparedError.message = firstError.detail
      } else if (action.error.message !== "Rejected") {
        preparedError.message = action.error.message
      }

      state.editingEmployee.error = preparedError
    })
    // -------- get Single Employee Details -------- \\
    builder.addCase(fetchEmployeeDetails.pending, (state) => {
      state.isEmployeeDetailsLoading = true
    })

    builder.addCase(fetchEmployeeDetails.fulfilled, (state, action) => {
      state.isEmployeeDetailsLoading = false
      state.employeeDetails = action.payload.data
    })

    builder.addCase(fetchEmployeeDetails.rejected, (state, action) => {
      state.isEmployeeDetailsLoading = false
      state.employeeDetailsLoadingError = {
        name: action.error.name,
        message: action.error.message,
      }
    })
  },
})

export const {
  //  Add Employee  \\
  employeeAdditionStarted,
  employeeAdditionInformationSet,
  employeeAdditionAuthorizing,
  employeeAdditionAborting,
  employeeAdditionRejected,
  employeeAdditionFinished,
  // -------- Employee Deletion -------- \\
  employeeDeletionStarted,
  employeeDeletionAuthorizing,
  employeeDeletionAborting,
  employeeDeletionRejected,
  employeeDeletionAuthorized,
  employeeDeletionFinished,
  //  Edit Employee  \\
  editingEmployeeStarted,
  editingEmployeeInformationSet,
  editingEmployeeAuthorizing,
  editingEmployeeAborting,
  editingEmployeeRejected,
  editingEmployeeFinished,
  // -------- Add and Edit Employee -------- \\
  checkAccountExistanceEmployeeReset,
  // -------- get and reset Single Employee Details -------- \\
  dismissEmployeeDetails,
} = employeesSlice.actions

export default employeesSlice.reducer
