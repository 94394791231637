import createKeycloakInstance from "keycloak-js"

export default class Keycloak {
  /**
   * @param {import("keycloak-js").KeycloakConfig} config
   */
  static getInstance(config) {
    if (!this.instance) {
      this.instance = createKeycloakInstance(config)
    }

    return this.instance
  }
}
