export const userNameSelector = (state) => state.user.name
export const userIconUrlSelector = (state) => state.user.iconUrl
export const userPhoneNumberSelector = (state) => state.user.phoneNumber
export const userPhoneDialingCodeSelector = (state) => state.user.dialingCode
export const isMainAccountSelector = (state) => state.user.isMainAccount

export const isNavbarCollapsedSelector = (state) => state.user.isNavbarCollapsed

// -------- Users -------- \\
export const usersSelector = (state) => state.user.users
export const isUsersLoadingSelector = (state) => state.user.isUsersLoading
export const isFirstPageSelector = (state) => state.user.isFirst
export const isLastPageSelector = (state) => state.user.isLast
export const pageNumberSelector = (state) => state.user.pageNumber
export const pageSizeSelector = (state) => state.user.pageSize
export const totalElementsSelector = (state) => state.user.totalElements
export const totalPagesSelector = (state) => state.user.totalPages
export const usersErrorSelector = (state) => state.user.usersError

// -------- User Detail -------- \\
export const userDetailsSelector = (state) => state.user.userDetails
export const userDetailsErrorSelector = (state) => state.user.userDetailsError
export const isUserDetailsLoadingSelector = (state) =>
  state.user.isUserDetailsLoading

// -----------  Delete User  ----------- //
/**
 * @returns {import("../../shared/constants/users/delete-user").UserDeletionState}
 */
export const userDeletionStateSelector = (state) =>
  state.user.userDeletion.state

/**
 * @returns {string=}
 */
export const userDeletionNameSelector = (state) => state.user.userDeletion.name

// -----------  change user activation status  ----------- //
/**
 * @returns {import("../../shared/constants/users/change-user-activation-status").ChangeUserActivationStatusState}
 */
export const changeUserActivationStatusStateSelector = (state) =>
  state.user.changeUserActivationStatus.state

/**
 * @returns {string}
 */
export const changeUserActivationStatusNameSelector = (state) =>
  state.user.changeUserActivationStatus.name
export const changeUserActivationStatusStatusSelector = (state) =>
  state.user.changeUserActivationStatus.status
export const changeUserActivationStatusErrorSelector = (state) =>
  state.user.changeUserActivationStatus.error

// Create User
export const userCreationStateSelector = (state) =>
  state.user.userCreation.state

export const userCreationConfigSelector = (state) =>
  state.user.userCreation.userConfig

export const userCreationIsConfirmedSelector = (state) =>
  state.user.userCreation.isConfirmed

export const userCreationErrorSelector = (state) =>
  state.user.userCreation.error

export const userCreationIsPhoneNumberUsedSelector = (state) =>
  state.user.userCreation.isPhoneNumberUsed

export const userCreationIsPhoneNumberUsedLoadingSelector = (state) =>
  state.user.userCreation.isPhoneNumberUsedLoading

// -----------  OTP & Devices  ----------- //

export const isOTPVerifiedSelector = (state) => state.user.isOTPVerified
export const checkingIsOTPVerifiedSelector = (state) =>
  state.user.checkingIsOTPVerified
export const instanceIdSelector = (state) => state.user.instanceId
export const isOTPRequestedSelector = (state) => state.user.isOTPRequested
export const isRequestingOTPSelector = (state) => state.user.isRequestingOTP
export const otpErrorSelector = (state) => state.user.otpError
export const remainingOTPTrialsSelector = (state) =>
  state.user.remainingOTPTrials
export const otpValidUntilSelector = (state) => state.user.otpValidUntil
export const isRequestedOTPExpiredSelector = (state) =>
  state.user.isRequestedOTPExpired
export const isOTPVerifyingSelector = (state) => state.user.isOTPVerifying

// -------- Password Status -------- \\

export const isPasswordUpdateRequiredSelector = (state) =>
  state.user.isPasswordUpdateRequired
export const isPasswordStatusLoadingSelector = (state) =>
  state.user.isPasswordStatusLoading
export const passwordStatusErrorSelector = (state) =>
  state.user.passwordStatusError

// -------- Update Password Process -------- \\

export const updatingUserPasswordStateSelector = (state) =>
  state.user.updatingUserPassword.state

// -------- Set And Verify Pin Process -------- \\

export const settingAndVerifyingUserPinStateSelector = (state) =>
  state.user.settingAndVerifyingUserPin.state

export const isSettingPinRequiredSelector = (state) =>
  state.user.isSettingPinRequired

export const pinStatusErrorSelector = (state) => state.user.pinStatusError

export const storedUserPinSelector = (state) =>
  state.user.settingAndVerifyingUserPin.pin

export const isPinVerifiedSelector = (state) => state.user.isPinVerified

export const pinVerificationInstanceIdSelector = (state) =>
  state.user.pinVerificationInstanceId

// -------- Agent Role -------- \\

export const agentRoleFeaturesSelector = (state) => state.user.agentRoleFeatures

export const agentRoleApprovalThresholdsSelector = (state) =>
  state.user.agentRoleApprovalThresholds

export const agentRoleNameSelector = (state) => state.user.agentRoleName

export const agentRoleErrorSelector = (state) => state.user.agentRoleError
