import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import {
  approvePendingApproval,
  getPendingApprovals,
  rejectPendingApproval,
} from "../../api/endpoints/pending-approvals"
import { PROCESS_APPROVAL_STATE } from "../../shared/constants/pending-approvals/approve-process"
import { PROCESS_REJECTION_STATE } from "../../shared/constants/pending-approvals/reject-process"

const SLICE_NAME = "pendingApprovals"

export const fetchPendingApprovals = createAsyncThunk(
  `${SLICE_NAME}/fetchPendingApprovals`,
  async ({ page, size, type, dateRange, searchQuery }) => {
    const { data, status, statusText } = await getPendingApprovals({
      searchQuery,
      dateRange,
      type,
      page,
      size,
    })
    return { data, status, statusText }
  }
)

export const processApproval = createAsyncThunk(
  `${SLICE_NAME}/processApproval`,
  async (_, thunkAPI) => {
    const { elevatedToken } = thunkAPI.getState().otac

    if (!elevatedToken)
      throw new Error("web_c_general_notauthorized_error_text")

    const { id } = thunkAPI.getState().pendingApprovals.processApproval

    const { data, status, statusText } = await approvePendingApproval(id, {
      headers: { Authorization: `Bearer ${elevatedToken}` },
    })
    return { data, status, statusText }
  }
)

export const processRejection = createAsyncThunk(
  `${SLICE_NAME}/processRejection`,
  async (_, thunkAPI) => {
    const { elevatedToken } = thunkAPI.getState().otac

    if (!elevatedToken)
      throw new Error("web_c_general_notauthorized_error_text")

    const { id } = thunkAPI.getState().pendingApprovals.processRejection

    const { data, status, statusText } = await rejectPendingApproval(id, {
      headers: { Authorization: `Bearer ${elevatedToken}` },
    })
    return { data, status, statusText }
  }
)

/**
 * @typedef ProcessApproval
 * @property {import("../../shared/constants/pending-approvals/approve-process").ProcessApprovalState} state
 * @property {string=} id
 * @property {string=} referenceId
 * @property {string=} type
 * @property {import("@reduxjs/toolkit").SerializedError} error
 *
 * @typedef ProcessRejection
 * @property {import("../../shared/constants/pending-approvals/reject-process").ProcessRejectionState} state
 * @property {string=} id
 * @property {string=} referenceId
 * @property {string=} type
 * @property {import("@reduxjs/toolkit").SerializedError} error
 *
 * @typedef PendingApprovalsInitialState
 * @property {object[]} pendingApprovals
 * @property {boolean} isPendingApprovalsLoading
 * @property {ProcessApproval} processApproval
 * @property {ProcessRejection} processRejection
 * @property {import("@reduxjs/toolkit").SerializedError} pendingApprovalsError
 * @property {boolean} isFirst
 * @property {boolean} isLast
 * @property {number} pageNumber
 * @property {number} pageSize
 * @property {number} totalElements
 * @property {number} totalPages
 */

/**
 * @type {PendingApprovalsInitialState}
 */
export const initialState = {
  pendingApprovals: undefined,
  isPendingApprovalsLoading: false,
  pendingApprovalsError: undefined,
  isFirst: false,
  isLast: false,
  pageNumber: undefined,
  pageSize: undefined,
  totalElements: undefined,
  totalPages: undefined,

  processApproval: {
    state: PROCESS_APPROVAL_STATE.IDLE,
    id: undefined,
    referenceId: undefined,
    type: undefined,
    isConfirmed: false,
    error: undefined,
  },

  processRejection: {
    state: PROCESS_REJECTION_STATE.IDLE,
    id: undefined,
    referenceId: undefined,
    type: undefined,
    isConfirmed: false,
    error: undefined,
  },
}

const pendingApprovalsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    // -------- approve process -------- \\
    /**
     * @param {import("@reduxjs/toolkit").PayloadAction<{
     *         id: string,
     *         referenceId:string,
     *         type: string,
     * }>} action
     */
    processApprovalStarted: (state, action) => {
      state.processApproval.state = PROCESS_APPROVAL_STATE.STARTED
      state.processApproval.id = action.payload.id
      state.processApproval.referenceId = action.payload.referenceId
      state.processApproval.type = action.payload.type
    },
    processApprovalRejected: (state) => {
      state.processApproval.state = PROCESS_APPROVAL_STATE.REJECTED
    },
    processApprovalConfirmation: (state) => {
      state.processApproval.state = PROCESS_APPROVAL_STATE.CONFIRMATION
      state.processApproval.isConfirmed = false
      state.processApproval.error = undefined
    },
    processApprovalAuthorizing: (state) => {
      state.processApproval.state = PROCESS_APPROVAL_STATE.AUTHORIZING
      state.processApproval.isConfirmed = true
    },
    processApprovalAborting: (state) => {
      state.processApproval.state = PROCESS_APPROVAL_STATE.ABORTING
    },
    processApprovalFinished: (state) => {
      state.processApproval = initialState.processApproval
    },
    // -------- reject process -------- \\
    /**
     * @param {import("@reduxjs/toolkit").PayloadAction<{
     *         id:string
     *         referenceId: string,
     *         type: string,
     * }>} action
     */
    processRejectionStarted: (state, action) => {
      state.processRejection.state = PROCESS_REJECTION_STATE.STARTED
      state.processRejection.id = action.payload.id
      state.processRejection.referenceId = action.payload.referenceId
      state.processRejection.type = action.payload.type
    },
    processRejectionRejected: (state) => {
      state.processRejection.state = PROCESS_REJECTION_STATE.REJECTED
    },
    processRejectionConfirmation: (state) => {
      state.processRejection.state = PROCESS_APPROVAL_STATE.CONFIRMATION
      state.processRejection.isConfirmed = false
      state.processRejection.error = undefined
    },
    processRejectionAuthorizing: (state) => {
      state.processRejection.state = PROCESS_REJECTION_STATE.AUTHORIZING
      state.processRejection.isConfirmed = true
    },
    processRejectionAborting: (state) => {
      state.processRejection.state = PROCESS_REJECTION_STATE.ABORTING
    },
    processRejectionFinished: (state) => {
      state.processRejection = initialState.processRejection
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPendingApprovals.pending, (state) => {
      state.isPendingApprovalsLoading = true
    })
    builder.addCase(fetchPendingApprovals.fulfilled, (state, action) => {
      state.isPendingApprovalsLoading = false
      state.pendingApprovals = action.payload.data.content
      state.isFirst = action.payload.data.first
      state.isLast = action.payload.data.last
      state.pageNumber = action.payload.data.pageNumber
      state.pageSize = action.payload.data.pageSize
      state.totalElements = action.payload.data.totalElements
      state.totalPages = action.payload.data.totalPages
    })
    builder.addCase(fetchPendingApprovals.rejected, (state, action) => {
      state.isPendingApprovalsLoading = false
      state.pendingApprovalsError = action.error
    })
    // -------- approve process -------- \\
    builder.addCase(processApproval.pending, (state) => {
      state.processApproval.state = PROCESS_APPROVAL_STATE.APPROVING
    })
    builder.addCase(processApproval.fulfilled, (state) => {
      state.processApproval.state = PROCESS_APPROVAL_STATE.APPROVED
    })
    builder.addCase(processApproval.rejected, (state, action) => {
      state.processApproval.state = PROCESS_APPROVAL_STATE.ERRORED
      state.processApproval.error = action.error
    })
    // -------- reject process -------- \\
    builder.addCase(processRejection.pending, (state) => {
      state.processApproval.state = PROCESS_REJECTION_STATE.REJECTING
    })
    builder.addCase(processRejection.fulfilled, (state) => {
      state.processRejection.state = PROCESS_REJECTION_STATE.SUCCESS
    })
    builder.addCase(processRejection.rejected, (state, action) => {
      state.processRejection.state = PROCESS_REJECTION_STATE.ERRORED
      state.processRejection.error = action.error
    })
  },
})

export const {
  // -------- approve process -------- \\
  processApprovalStarted,
  processApprovalAborting,
  processApprovalRejected,
  processApprovalFinished,
  processApprovalConfirmation,
  processApprovalAuthorizing,
  // -------- reject process -------- \\
  processRejectionStarted,
  processRejectionAborting,
  processRejectionRejected,
  processRejectionFinished,
  processRejectionConfirmation,
  processRejectionAuthorizing,
} = pendingApprovalsSlice.actions

export default pendingApprovalsSlice.reducer
