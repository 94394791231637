/**
 * @readonly
 * @enum {string}
 */
export const TERMINAL_DELETION_STATE = {
  IDLE: "IDLE",
  STARTED: "STARTED",
  AUTHORIZING: "AUTHORIZING",
  ABORTING: "ABORTING",
  REJECTED: "REJECTED",
  AUTHORIZED: "AUTHORIZED",
  DELETING: "DELETING",
  DELETED: "DELETED",
  ERRORED: "ERRORED",
}
