import PropTypes from "prop-types"
import { Component, createRef } from "react"
import { withTranslation } from "react-i18next"

import { ReactComponent as BigErrorIcon } from "../../assets/icons/errorIllustration.svg"
import { NOT_FOUND } from "../../shared/constants/general"
import NotFoundPage from "../../pages/404"

class RootErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { error: undefined }
    this.iconRef = createRef(null)
    this.timeouRef = createRef(null)
  }

  componentDidUpdate() {
    if (this.iconRef.current) {
      this.timeouRef.current = setTimeout(() => {
        this.iconRef.current.classList.add(
          "root-error-boundary__illustration--active"
        )
      }, 50)
    }
  }

  componentDidCatch(error) {
    this.setState((prevState) => ({ ...prevState, error }))
  }

  componentWillUnmount() {
    clearTimeout(this.timeouRef.current)
  }

  render() {
    const { error } = this.state
    const { t, children, pathname } = this.props

    if (error === undefined) return children

    if (error.code === NOT_FOUND) return <NotFoundPage />

    return (
      <div className="root-error-boundary" data-testid="root-error-boundary">
        <BigErrorIcon
          ref={this.iconRef}
          className="root-error-boundary__illustration"
          data-testid="root-error-boundary-illustration"
        />
        <h1 className="root-error-boundary__title">
          {t("web_c_somethingwentwrong_title")}
        </h1>
        <p className="root-error-boundary__description">
          {t("web_c_somethingwentwrong_text")}
        </p>
        <a
          className="button button--primary root-error-boundary__action-link"
          href={pathname}
        >
          {t("web_c_somethingwentwrong_refresh_button")}
        </a>
      </div>
    )
  }
}

RootErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  t: PropTypes.func.isRequired,
  pathname: PropTypes.string.isRequired,
}

export default withTranslation()(RootErrorBoundary)
