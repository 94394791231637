import dayjs from "dayjs"
import { FOUR_YEAR_THRESHOLD } from "../../shared/constants/analytics/balance-page"

/**
 * @typedef {object} BalanceHistoryItemWithId
 * @property {string} accountId
 * @property {boolean} hasValue
 * @property {import("./bankAccounts-slice.js").MonetaryValue} monetaryValue
 * @property {number} timestamp
 *
 *
 * @param {import("./bankAccounts-slice").AggregatedBalanceHistory[]} data
 * @returns {BalanceHistoryItemWithId[][]}
 */
export const mergeAccountIdAndBalanceHistory = (data) =>
  data.map(({ accountId, values }) =>
    values.map((val) => ({ accountId, ...val }))
  )

/**
 * @param {BalanceHistoryItemWithId} balanceHistoryItemWithId
 *
 * @typedef {object} BalanceHistoryItemIdMappedToBalance
 * @property {[x: string]: number}
 * @property {number} timestamp
 *
 * @returns {BalanceHistoryItemIdMappedToBalance}
 */
export const prepareAggregatedBalanceItem = ({
  timestamp,
  accountId,
  // hasValue,
  monetaryValue,
}) => ({
  // FIXME the correct normal check is commented out because of a bug
  // where backend sends `hasValue` true while `monetaryValue` is null
  // [accountId]: hasValue ? monetaryValue.amount : 0,
  [accountId]: monetaryValue !== null ? monetaryValue.amount : 0,
  timestamp,
})

/**
 * @param {BalanceHistoryItemIdMappedToBalance} param
 * @returns {boolean}
 */
export const isDateWithFirstDayOfMonth = ({ timestamp }) =>
  dayjs(timestamp).date() === 1

/**
 *
 * @param {BalanceHistoryItemIdMappedToBalance[][]} nonFirstDataSets
 * @returns
 */
export const buildMergeDataSetsMapper =
  (nonFirstDataSets) =>
  /**
   *
   * @param {BalanceHistoryItemIdMappedToBalance} value
   * @param {number} i
   */
  (value, i) => {
    const otherAggregatedDataSet = nonFirstDataSets
      .map((nonFirstDataSet) => nonFirstDataSet[i])
      .reduce(
        (accumulator, current) => ({
          ...accumulator,
          ...current,
        }),
        {}
      )

    return {
      ...value,
      ...otherAggregatedDataSet,
    }
  }

export const prepareAggregatedBalanceHistory = (
  rawDataSets,
  startDate,
  endDate
) => {
  const preparedData = rawDataSets.map((dataSet) =>
    dataSet.map(prepareAggregatedBalanceItem)
  )

  let aggregatedBalanceDataSet = preparedData[0]

  if (preparedData.length > 1) {
    aggregatedBalanceDataSet = aggregatedBalanceDataSet.map(
      buildMergeDataSetsMapper(preparedData.slice(1))
    )
  }

  const difference = dayjs(endDate).diff(dayjs(startDate), "days")

  if (difference > FOUR_YEAR_THRESHOLD) {
    aggregatedBalanceDataSet = aggregatedBalanceDataSet.filter(
      isDateWithFirstDayOfMonth
    )
  }

  return aggregatedBalanceDataSet
}

/**
 * @param {BalanceHistoryItemWithId[][]} rawDataSets
 */
export const prepareBalancesInRange = (rawDataSets) => {
  const allBalanceInRange = rawDataSets
    .map((preparedDataItem) =>
      preparedDataItem
        .map(({ monetaryValue }) => monetaryValue?.amount || 0)
        .filter((amount) => amount !== undefined)
    )
    .flat(1)

  if (allBalanceInRange.length === 0) allBalanceInRange.push(0)

  return allBalanceInRange
}
