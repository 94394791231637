import AxiosInstance from "../axios"

/**
 * @typedef {import("../../features/bankAccounts/bankAccounts-slice").MonetaryValue} TotalSalary
 *
 * @typedef SalaryDistribution
 * @property {string} id
 * @property {import("../../shared/constants/payroll/main").SalaryDistributionType} type
 * @property {string} period
 * @property {number} createdAt
 * @property {number} employeesCount
 * @property {{name: string}[]} payees
 * @property {TotalSalary} totalSalary
 *
 * @typedef SalaryDistributionsResponse
 * @property {SalaryDistribution[]} content
 * @property {number} first
 * @property {number} last
 * @property {number} pageSize
 * @property {number} totalElements
 * @property {number} totalPages
 * @property {number} pageNumber
 *
 * @param {object} params
 * @param {number} params.page
 * @param {number} params.size
 * @param {import("../../shared/constants/payroll/main").SalaryDistributionType} params.type
 * @param {string[]} params.teamIds
 * @param {object} params.dateRange
 * @param {boolean} params.dateRange.initial
 * @param {Date} params.dateRange.startDate
 * @param {Date} params.dateRange.endDate
 * @param {string} [params.periodYear]
 * @param {string} [params.periodMonth]
 * @param {string} params.searchQuery
 *
 * @returns { Promise<import("axios").AxiosResponse<SalaryDistributionsResponse>> }
 */
export const getSalaryDistributions = ({
  page,
  size,
  type,
  teamIds,
  dateRange,
  periodYear,
  periodMonth,
  searchQuery,
}) => {
  const params = new URLSearchParams()

  params.set("sort", "createdAt,desc")
  params.set("page", page)
  params.set("size", size)
  if (type) params.set("type", type)
  if (Array.isArray(teamIds)) {
    teamIds.forEach((teamId) => params.append("teamId", teamId))
  }
  if (!dateRange.initial) {
    params.set("startDate", dateRange.startDate.valueOf())
    params.set("endDate", dateRange.endDate.valueOf())
  }
  if (periodYear) params.set("periodYear", periodYear)
  if (periodMonth) params.set("periodMonth", periodMonth)
  params.set("searchQuery", searchQuery)

  return AxiosInstance.get(
    `/v1/corporate-users/me/salary-distributions?${params}`
  )
}

export const getSalaryDistributionsCSV = () =>
  AxiosInstance.get("/v1/corporate-users/me/salary-distributions/csv", {
    responseType: "blob",
  })

/**
 * @typedef { ("DRAFT" | "AUTHORIZED" | "PROCESSING" | "PROCESSED") } SalaryDistributionStatus
 *
 * @typedef SalaryDistributionDetails
 * @property {SalaryDistributionStatus} status
 * @property {string} sourceAccountName
 * @property {{name: string, type: string, payees: TotalSalary[]}[]} payees
 *
 * @typedef {SalaryDistribution & SalaryDistributionDetails} SalaryDistributionDetailsResponse
 *
 * @param {string} salaryDistributionId
 * @returns {Promise<import("axios").AxiosResponse<SalaryDistributionDetailsResponse>>}
 */
export const getSalaryDistributionDetails = (salaryDistributionId) =>
  AxiosInstance.get(
    `/v1/corporate-users/me/salary-distributions/${salaryDistributionId}`
  )

/**
 * @typedef CreateTeamSalaryDistribution
 * @property {string} id
 *
 * @param {object} params
 * @param {string} params.period
 * @param {object[]} params.teams
 * @param {string} params.teams.id
 * @param {object[]} params.teams.employees
 * @param {string} params.teams.employees.id
 *
 * @returns { Promise<import("axios").AxiosResponse<CreateTeamSalaryDistribution>> }
 */
export const createTeamSalaryDistribution = ({
  sourceAccountId,
  period,
  teams,
}) =>
  AxiosInstance.post("/v1/corporate-users/me/salary-distributions/teams", {
    sourceAccountId,
    period,
    teams,
  })

export const authorizeTeamSalaryDistributionValidateStatus = (status) =>
  status === 202

/**
 * @param {string} salaryDistributionId
 * @param { import("axios").AxiosRequestConfig } config
 *
 * @returns { Promise<import("axios").AxiosResponse<undefined>> }
 */
export const authorizeTeamSalaryDistribution = (salaryDistributionId, config) =>
  AxiosInstance.post(
    `/v1/corporate-users/me/salary-distributions/${salaryDistributionId}/authorize`,
    null,
    { ...config, validateStatus: authorizeTeamSalaryDistributionValidateStatus }
  )

/**
 * @param {string} salaryDistributionId
 * @returns {Promise<import("axios").AxiosResponse<string>>}
 */
export const getSalaryDistributionStatementPDF = (salaryDistributionId) =>
  AxiosInstance.get(
    `/v1/corporate-users/me/salary-distributions/${salaryDistributionId}/statement/pdf`,
    {
      responseType: "blob",
    }
  )

/**
 * @param {string} salaryDistributionId
 * @returns {Promise<import("axios").AxiosResponse<string>>}
 */
export const getSalaryDistributionPayslipPDF = (salaryDistributionId) =>
  AxiosInstance.get(
    `/v1/corporate-users/me/salary-distributions/${salaryDistributionId}/payslip/pdf`,
    {
      responseType: "blob",
    }
  )

/**
 *
 * @param {object} params
 * @param {object} params.file
 * @param {string} params.period
 * @param {import("axios").AxiosRequestConfig} config
 *
 * @returns { Promise<import("axios").AxiosResponse<undefined>> }
 */
export const fileSalaryDistributionUploader = (
  { sourceAccountId, period, file },
  config
) => {
  const searchParams = new URLSearchParams({
    sourceAccountId,
    period,
  })
  const formData = new FormData()
  formData.append("file", file)

  return AxiosInstance.post(
    `/v1/corporate-users/me/salary-distributions/file?${searchParams.toString()}`,
    formData,
    config
  )
}

export const getSalaryDistributionCsvSample = () =>
  AxiosInstance.get("/v1/corporate-users/me/salary-distributions/sample/csv", {
    responseType: "blob",
  })

export const authorizeFileSalaryDistributionValidateStatus = (status) =>
  status === 202

/**
 * @param {string} salaryDistributionId
 * @param { import("axios").AxiosRequestConfig } config
 *
 * @returns { Promise<import("axios").AxiosResponse<undefined>> }
 */
export const authorizeFileSalaryDistribution = (salaryDistributionId, config) =>
  AxiosInstance.post(
    `/v1/corporate-users/me/salary-distributions/${salaryDistributionId}/authorize`,
    null,
    { ...config, validateStatus: authorizeFileSalaryDistributionValidateStatus }
  )
