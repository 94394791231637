import { useEffect } from "react"
import { Provider } from "react-redux"
import { ToastContainer } from "react-toastify"

import store from "./shared/store/store"
import Routes from "./routes/Routes"
import { getCurrentLocale } from "./lib/i18n"
import useDir from "./shared/hooks/useDir"
import { logoutWhenElevatedTokenRemoved } from "./lib/authentication/auth-utils"

const App = () => {
  const { isRTL } = useDir()

  useEffect(() => {
    const currentLocale = getCurrentLocale()
    if (currentLocale) {
      document.dir = JSON.parse(currentLocale)?.dir
    }
  }, [])

  useEffect(() => {
    const listener = logoutWhenElevatedTokenRemoved

    window.addEventListener("storage", listener)
    return () => window.removeEventListener("storage", listener)
  }, [])

  return (
    <Provider store={store}>
      <Routes />
      <ToastContainer
        position="top-left"
        autoClose={5000}
        hideProgressBar
        closeButton={false}
        rtl={isRTL}
      />
    </Provider>
  )
}

export default App
