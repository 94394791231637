import PropTypes from "prop-types"
import { NavLink } from "react-router-dom"
import { NAVBAR_NAV_ICON_SIZE } from "../../shared/constants/icons"
import { activeNavlinkClassNameBuilder } from "../../lib/general"

const NavbarLink = ({ icon: Icon, target, title }) => (
  <NavLink
    to={target}
    title={title}
    className={activeNavlinkClassNameBuilder(
      "navbar-link__button navbar-link",
      "navlink"
    )}
    data-testid="navbar-link"
  >
    <Icon
      className="navbar-link__icon"
      data-testid="navbar-link__icon"
      width={NAVBAR_NAV_ICON_SIZE}
      height={NAVBAR_NAV_ICON_SIZE}
      aria-label={title}
    />
    <span className="navbar-link__title" data-testid="navbar-link__title">
      {title}
    </span>
  </NavLink>
)

NavbarLink.propTypes = {
  icon: PropTypes.elementType.isRequired,
  target: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default NavbarLink
