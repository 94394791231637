import * as yup from "yup"
import { NAME_VALIDATOR_REGEX } from "../general"

/**
 * @typedef RoleAdditionState
 * @property {string} IDLE
 * @property {string} STARTED
 * @property {string} PERMISSION_SET
 * @property {string} AUTHORIZING
 * @property {string} ABORTING
 * @property {string} REJECTED
 * @property {string} ADDED
 * @property {string} ERRORED
 * @property {string} CONFIRMATION
 */

/**
 * @type {RoleAdditionState}
 * @enum {string}
 */
export const ROLE_ADDITION_STATE = {
  IDLE: "IDLE",
  STARTED: "STARTED",
  PERMISSION_SET: "PERMISSION_SET",
  AUTHORIZING: "AUTHORIZING",
  ABORTING: "ABORTING",
  REJECTED: "REJECTED",
  ADDED: "ADDED",
  ERRORED: "ERRORED",
  CONFIRMATION: "CONFIRMATION",
}

export const getStepNumberFromRoleAdditionState = (roleAdditionState) => {
  switch (roleAdditionState) {
    case ROLE_ADDITION_STATE.STARTED:
      return 0
    case ROLE_ADDITION_STATE.PERMISSION_SET:
      return 1
    case ROLE_ADDITION_STATE.AUTHORIZING:
    case ROLE_ADDITION_STATE.ABORTING:
    case ROLE_ADDITION_STATE.REJECTED:
    case ROLE_ADDITION_STATE.ERRORED:
    case ROLE_ADDITION_STATE.ADDED:
    case ROLE_ADDITION_STATE.CONFIRMATION:
      return 2
    default:
      return -1
  }
}

export const roleAdditionPermissionStepSchema = yup.object({
  name: yup
    .string()
    .required("web_c_users_addrolemodal_rolename_validation1")
    .matches(
      NAME_VALIDATOR_REGEX,
      "web_c_users_addrolemodal_rolename_validation2"
    )
    .min(3, "web_c_users_addrolemodal_rolename_validation3")
    .max(30, "web_c_users_addrolemodal_rolename_validation4"),
  selectedPermissionsLength: yup
    .number()
    .moreThan(0, "web_c_users_addrolemodal_permissions_validation1"),
})

const approvalThresholdSchemaBuilder = (type) =>
  yup.number().when(type, {
    is: true,
    then: yup
      .number()
      .nullable()
      .transform((value) => (Number.isNaN(value) || value ? null : value)),
    otherwise: yup
      .number()
      .when(`$${type}`, {
        is: undefined,
        then: yup
          .number()
          .typeError("web_c_users_roles_addrolemodal_step2_validation1"),
      })
      .when("$limitations", (limitations, schema) => {
        const minAmount = limitations
          .find((thresholds) => `${thresholds.type}_CHECKBOX` === type)
          .byCurrency.find((c) => c).minimumValue.amount

        return schema.min(
          minAmount,
          "web_c_users_roles_addrolemodal_step2_validation2"
        )
      }),
  })

export const addRoleLimitationSchema = (fields) =>
  yup.object({
    name: yup
      .string()
      .required("web_c_users_addrolemodal_rolename_validation1")
      .matches(
        NAME_VALIDATOR_REGEX,
        "web_c_users_addrolemodal_rolename_validation2"
      )
      .min(3, "web_c_users_addrolemodal_rolename_validation3")
      .max(30, "web_c_users_addrolemodal_rolename_validation4"),

    ...fields?.reduce((acc, field) => {
      acc[field] = approvalThresholdSchemaBuilder(`${field}_CHECKBOX`)
      return acc
    }, {}),
  })
