import {
  getSalaryDistributionsCSV,
  getSalaryDistributionPayslipPDF,
  getSalaryDistributionStatementPDF,
  getSalaryDistributionCsvSample,
} from "../../api/endpoints/payroll"
import { downloadFile } from "../downloadFile"

export const downloadPayrollsCSV = async () => {
  const response = await getSalaryDistributionsCSV()
  downloadFile(response.data, "payrolls")
}

/**
 * @param {string} id
 */
export const downloadPayrollStatementPDF = async (id) => {
  const response = await getSalaryDistributionStatementPDF(id)
  downloadFile(response.data, "payroll-statement")
}

/**
 * @param {string} id
 */
export const downloadPayrollPayslipPDF = async (id) => {
  const response = await getSalaryDistributionPayslipPDF(id)
  downloadFile(response.data, "payroll-payslip")
}

/**
 *
 * @param {Function} updateProgressBar
 * @returns
 */
export const onUploadProgressBuilder =
  (updateProgressBar) => (progressEvent) => {
    const toPrecentage = parseInt(
      Math.round((progressEvent.loaded * 100) / progressEvent.total),
      10
    )
    updateProgressBar(toPrecentage)
  }

export const downloadSalaryDistributionCsvSample = async () => {
  const response = await getSalaryDistributionCsvSample()
  downloadFile(response.data, "template-sample")
}
