import dayjs from "dayjs"
import { v4 as uuid } from "uuid"
import { LOGIN_VERIFIED } from "../../shared/constants/auth"

/**
 * Returns the value of {@link LOGIN_VERIFIED} in the
 * `localStorage` or null of it doesn't exist.
 */
export const getLoginVerifiedAt = () => localStorage.getItem(LOGIN_VERIFIED)

/**
 * Removes the value of {@link LOGIN_VERIFIED} in the
 * `localStorage`.
 */
export const removeLoginVerifiedAt = () =>
  localStorage.removeItem(LOGIN_VERIFIED)

/**
 * Saves a `timestamp` as {@link LOGIN_VERIFIED} which
 * indicates the time user verified, inside `localStorage`.
 */
export const saveLoginVerifiedAt = () => {
  localStorage.setItem(LOGIN_VERIFIED, Date.now().toString())
}

/**
 * Determines whether the login with the QR Code or PIN
 * is still valid by checking if it's exists and
 * if it's, then if it's younger than 7 days before.
 */
export const isLoginVerificationValid = () => {
  const verifiedAt = getLoginVerifiedAt()
  if (!verifiedAt) return false

  const parsedVerifiedAt = Number.parseInt(verifiedAt, 10)
  return dayjs(parsedVerifiedAt).isBefore(dayjs().add(7, "day"))
}

export const getToken = () => sessionStorage.getItem("token")
export const getParsedToken = () => sessionStorage.getItem("parsedToken")
export const getRefreshToken = () => sessionStorage.getItem("refreshToken")
export const getLogoutURL = () => sessionStorage.getItem("logoutURL")
export const getClientId = () => sessionStorage.getItem("clientId")

/**
 * This will be used as a listener to `storage` events, so
 * when the user logs out from one tab, the other tabs will
 * logout as well.
 *
 * @param {StorageEvent} e
 */
export const logoutWhenElevatedTokenRemoved = (e) => {
  if (e.key === LOGIN_VERIFIED && e.newValue === null) {
    window.location.href = getLogoutURL()
  }
}

/**
 * Saves informations from Keycloak to sessionStorage which will be
 * used across the application.
 * @param {import("keycloak-js").KeycloakInstance} kc
 */
export const saveAuthenticationData = (kc) => {
  sessionStorage.setItem("token", kc.token)
  sessionStorage.setItem("parsedToken", JSON.stringify(kc.tokenParsed))
  sessionStorage.setItem("refreshToken", kc.refreshToken)
  sessionStorage.setItem("logoutURL", kc.createLogoutUrl())
  sessionStorage.setItem("clientId", kc.clientId)
}

export const generateUUID = () => uuid()

export const saveInstanceId = (instanceId) => {
  localStorage.setItem("instanceId", instanceId)
}

export const getInstanceId = () => localStorage.getItem("instanceId")

// This is to check how much OTP valid time is left during refresh and if it's expired or not
export const saveLastOTPRequestedTime = (time) =>
  localStorage.setItem("LAST_OTP_REQUESTED_TIME", time)

export const getLastOTPRequestedTime = () =>
  localStorage.getItem("LAST_OTP_REQUESTED_TIME")

export const removeLastOTPRequestedTime = () =>
  localStorage.removeItem("LAST_OTP_REQUESTED_TIME")
