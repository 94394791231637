/**
 *
 * @param {object} sortBy
 * @param {string} sortBy.field - the field name
 * @param {boolean} sortBy.ascending - whether the sorting is ascending or descending
 * @param {()=>void} setSortBy
 * @returns
 */
export function setSortingBuilder(sortBy, setSortBy) {
  return (newSortByField) => () => {
    setSortBy({
      field: newSortByField,
      ascending: newSortByField === sortBy.field ? !sortBy.ascending : true,
    })
  }
}

/**
 *
 * @param {string} baseClassName
 * @param {object} sortBy
 * @param {string} sortBy.field - the field name currently sorted by
 * @param {boolean} sortBy.ascending - whether the sorting is ascending or descending
 * @param {string} fieldName - the new field to sort by
 * @returns
 */
export const buildSortingClasses = (baseClassName, sortBy, fieldName) => {
  if (sortBy === undefined) return {}

  return {
    [`${baseClassName}--sorted-ascending`]: sortBy.field === fieldName,
    [`${baseClassName}--sorted-descending`]: sortBy.field !== fieldName,
  }
}
