import { ACTIVE, INACTIVE } from "../general"
import { OTAC_CONTEXT_BLOCK_AGENT, OTAC_CONTEXT_UNBLOCK_AGENT } from "../otac"

/**
 * @typedef ChangeUserActivationStatusState
 * @property {string} IDLE
 * @property {string} STARTED
 * @property {string} AUTHORIZING
 * @property {string} ABORTING
 * @property {string} REJECTED
 * @property {string} CHANGED
 * @property {string} ERRORED
 */

/**
 * @type {ChangeUserActivationStatusState}
 * @enum {string}
 */
export const CHANGE_USER_ACTIVATION_STATUS_STATE = {
  IDLE: "IDLE",
  STARTED: "STARTED",
  AUTHORIZING: "AUTHORIZING",
  ABORTING: "ABORTING",
  REJECTED: "REJECTED",
  CHANGED: "CHANGED",
  ERRORED: "ERRORED",
}

export const MAP_CONFIRM_BOX_BLOCKING_DESCRIPTIONS = {
  [ACTIVE]: "web_c_useractivation_users_confirmationpopup_blocking_description",
  [INACTIVE]:
    "web_c_useractivation_users_confirmationpopup_unblocking_description",
}

export const MAP_AGENT_STATUS_CONTEXT = {
  [INACTIVE]: OTAC_CONTEXT_UNBLOCK_AGENT,
  [ACTIVE]: OTAC_CONTEXT_BLOCK_AGENT,
}
