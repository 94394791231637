import React from "react"
import PropTypes from "prop-types"

const VARIANTS = [
  { name: "small", size: 20, thickness: 3 },
  { name: "medium", size: 40, thickness: 5 },
  { name: "large", size: 60, thickness: 7 },
  { name: "xlarge", size: 80, thickness: 9 },
]

function Spinner({ variant, dark, rootStyle }) {
  const chosenVariant = VARIANTS.find(({ name }) => name === variant)

  return (
    <div
      data-testid="spinner"
      className="spinner"
      style={{
        width: chosenVariant.size,
        height: chosenVariant.size,
        ...rootStyle,
      }}
      role="progressbar"
    >
      <svg
        className="spinner"
        viewBox={`${chosenVariant.size / 2} ${chosenVariant.size / 2} ${
          chosenVariant.size
        } ${chosenVariant.size}`}
      >
        <circle
          cx={chosenVariant.size}
          cy={chosenVariant.size}
          r={(chosenVariant.size - chosenVariant.thickness) / 2}
          fill="none"
          strokeWidth={chosenVariant.thickness}
          stroke={dark ? "#ffffff64" : "#00000014"}
        />
        <circle
          edgeMode="rounded"
          style={{
            strokeDashoffset: 0,
            strokeDasharray: `${chosenVariant.size * 0.6}px, ${
              chosenVariant.size * 4
            }px`,
          }}
          cx={chosenVariant.size}
          cy={chosenVariant.size}
          r={(chosenVariant.size - chosenVariant.thickness) / 2}
          fill="none"
          strokeWidth={chosenVariant.thickness}
          stroke={dark ? "#fff" : "#000000aa"}
        />
      </svg>
    </div>
  )
}

Spinner.defaultProps = {
  variant: VARIANTS[3].name,
  rootStyle: {},
  dark: false,
}

Spinner.propTypes = {
  variant: PropTypes.oneOf(VARIANTS.map((v) => v.name)),
  rootStyle: PropTypes.shape({}),
  dark: PropTypes.bool,
}

export default Spinner
