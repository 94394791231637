import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import {
  getTerminalUsers,
  getTerminalInfo,
  createTerminalUser,
  deleteTerminalUser,
  checkPhoneNumberExistence,
  editTerminalName,
  getTransactionHistory,
  getTerminalTransferConfiguration,
  changeTerminalPassword,
  getAllBankAccounts,
  fetchTerminalUsers,
  hideTerminalForAgents,
  showTerminalForAgents,
} from "../../api/endpoints"
import {
  initiateTransaction,
  confirmTransaction,
  authorizeConfirmedTransaction,
  getTransactionDetails,
} from "../../api/endpoints/transactions"
import { EDITING_TERMINAL_NAME_STATE } from "../../shared/constants/terminals/edit-terminal-name"
import {
  PHONE_NUMBER_USAGE_CHECK_FAILED,
  TERMINAL_CREATION_STATE,
} from "../../shared/constants/terminals/create-terminal"
import { TERMINAL_DELETION_STATE } from "../../shared/constants/terminals/delete-terminal"
import { transformTerminalTransactionData } from "./terminals-helper"
import {
  SHIFT_MONEY_STATE,
  ACCOUNT_TO_BE_UPDATED,
} from "../../shared/constants/terminals/shift-money"
import { CHANGING_TERMINAL_PASSWORD_STATE } from "../../shared/constants/terminals/change-terminal-password"
import {
  GENERAL_ERROR,
  IRAQ_DAILING_CODE,
} from "../../shared/constants/general"
import { USER_GROUP_CORPORATE } from "../../shared/constants/user"
import { TOGGLE_TERMINAL_VISIBILITY_FOR_AGENTS_STATE } from "../../shared/constants/terminals/toggle-terminal-visibility-for-agents"

const SLICE_NAME = "terminals"

/**
 * @deprecated
 */
export const fetchMyTerminalUsersPayloadCreator = async ({
  withDeleted,
  searchQuery,
}) => {
  const response = await getTerminalUsers({
    withDeleted,
    searchQuery,
  })
  const { data, status, statusText } = response
  return { data, status, statusText }
}

// FIXME: This payload creator is used for the {Terminals} page, {Terminals} page should have used {fetchMyTerminalUsersPayloadCreator} but since it is used in other place so I created this new one to not affect others until other places are adjusted accordingly
export const fetchTerminalUsersPayloadCreator = async ({
  size,
  page,
  searchQuery,
  withBalance,
}) => {
  const response = await fetchTerminalUsers({
    size,
    page,
    searchQuery,
    withBalance,
  })
  const { data, status, statusText } = response
  return { data, status, statusText }
}

export const fetchShiftMoneyTerminalUsersPayloadCreator = async ({
  size,
  page,
  searchQuery,
  withBalance,
  accountToBeUpdated,
}) => {
  const response = await fetchTerminalUsers({
    page,
    size,
    searchQuery,
    withBalance,
  })
  const { data, status, statusText } = response
  return { data, status, statusText, accountToBeUpdated }
}

export const fetchMyTerminalTransactionsPayloadCreator = async ({
  searchQuery,
  page,
  size,
  selectedAccountIds,
  type,
  dateRange,
}) => {
  const response = await getTransactionHistory({
    searchQuery,
    page,
    size,
    selectedAccountIds,
    type,
    dateRange,
  })
  const { data, status, statusText } = response
  return { data, status, statusText }
}

export const fetchMyTerminalUsers = createAsyncThunk(
  `${SLICE_NAME}/fetchMyTerminalUsers`,
  ({ searchQuery, page, size, withBalance }) =>
    fetchTerminalUsersPayloadCreator({ searchQuery, page, size, withBalance })
)

export const fetchShiftMoneyTerminalUsers = createAsyncThunk(
  `${SLICE_NAME}/fetchShiftMoneyTerminalUsers`,
  ({ searchQuery, page, size, withBalance, accountToBeUpdated }) =>
    fetchShiftMoneyTerminalUsersPayloadCreator({
      searchQuery,
      page,
      size,
      withBalance,
      accountToBeUpdated,
    })
)

export const fetchTerminalUsersIncludingDeleted = createAsyncThunk(
  `${SLICE_NAME}/fetchTerminalUsersIncludingDeleted`,
  (searchQuery) =>
    fetchMyTerminalUsersPayloadCreator({ withDeleted: true, searchQuery })
)

export const fetchTerminalInfo = createAsyncThunk(
  `${SLICE_NAME}/fetchMyTerminalDetails`,
  async (terminalId, thunkAPI) => {
    try {
      const { data, status, statusText } = await getTerminalInfo(terminalId)
      return { data, status, statusText }
    } catch (error) {
      const { data, status } = error.response
      return thunkAPI.rejectWithValue({ data, status })
    }
  }
)

export const fetchTerminalTransferConfigurationPayloadCreator = async (
  sourceAccountId
) => {
  const response = await getTerminalTransferConfiguration(sourceAccountId)
  const { data, status, statusText } = response
  return { data, status, statusText }
}

export const fetchTerminalTransferConfiguration = createAsyncThunk(
  `${SLICE_NAME}/getTerminalTransferConfiguration`,
  fetchTerminalTransferConfigurationPayloadCreator
)

export const fetchTerminalTransferTransactionDetails = createAsyncThunk(
  `${SLICE_NAME}/fetchTerminalTransferTransactionDetails`,
  async (terminalTransferId) => {
    const { data, status, statusText } = await getTransactionDetails(
      terminalTransferId
    )

    const accountsData = await getAllBankAccounts()
    const isSourceAccountMain = accountsData.data.some(
      (acc) => acc.id === data.sourceAccountId && acc.main === true
    )
    const isTargetAccountMain = accountsData.data.some(
      (acc) => acc.id === data.targetAccountId && acc.main === true
    )

    const terminalsData = await getTerminalUsers({ withDeleted: true })

    let sourceAccount
    let targetAccount

    if (isSourceAccountMain) {
      sourceAccount = "web_c_terminals_shiftmoneymodal_acc_mainaccount_label"
    } else {
      sourceAccount = terminalsData.data.terminals.find(
        (terminal) => terminal.accountId === data.sourceAccountId
      ).name
    }

    if (isTargetAccountMain) {
      targetAccount = "web_c_terminals_shiftmoneymodal_acc_mainaccount_label"
    } else {
      targetAccount = terminalsData.data.terminals.find(
        (terminal) => terminal.accountId === data.targetAccountId
      ).name
    }
    const newData = { ...data, sourceAccount, targetAccount }

    return { data: newData, status, statusText }
  }
)

export const transactionInitiation = createAsyncThunk(
  `${SLICE_NAME}/transactionInitiation`,
  async (_, thunkAPI) => {
    const {
      amount,
      sourceAccount: { id: sourceAccountId },
      targetAccount: { id: targetAccountId },
      senderBalance: { currency },
    } = thunkAPI.getState().terminals.shiftMoney

    try {
      const response = await initiateTransaction({
        sourceAccountId,
        targetAccountId,
        monetaryValue: {
          amount,
          currency,
        },
        type: "TERMINAL_TRANSFER",
      })
      const { data, status, statusText } = response
      return { data, status, statusText }
    } catch (error) {
      throw thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

export const transactionConfirmation = createAsyncThunk(
  `${SLICE_NAME}/transactionConfirmation`,
  async (_, thunkAPI) => {
    const {
      sourceAccount: { id: sourceAccountId },
      transactionInitiation: { transactionId },
    } = thunkAPI.getState().terminals.shiftMoney

    const response = await confirmTransaction(transactionId, {
      sourceAccountId,
    })
    const { data, status, statusText } = response
    return { data, status, statusText }
  }
)

export const confirmedTransactionAuthorization = createAsyncThunk(
  `${SLICE_NAME}/confirmedTransactionAuthorization`,
  async (_, thunkAPI) => {
    const { elevatedToken } = thunkAPI.getState().otac

    if (!elevatedToken)
      throw new Error("web_c_general_notauthorized_error_text")

    const { transactionId } =
      thunkAPI.getState().terminals.shiftMoney.transactionInitiation

    try {
      const response = await authorizeConfirmedTransaction(transactionId, {
        headers: { Authorization: `Bearer ${elevatedToken}` },
      })
      const { data, status, statusText } = response
      return { data, status, statusText }
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data)
    }
  }
)

export const terminalCreationSavingTerminal = createAsyncThunk(
  `${SLICE_NAME}/createTerminal`,
  async (_, thunkAPI) => {
    const { elevatedToken } = thunkAPI.getState().otac

    if (!elevatedToken) {
      throw new Error("web_c_general_notauthorized_error_text")
    }

    const { name, displayName, phoneNumber, password } =
      thunkAPI.getState().terminals.terminalCreation

    const response = await createTerminalUser(
      {
        dialingCode: IRAQ_DAILING_CODE,
        name,
        displayName,
        phoneNumber,
        password,
      },
      { headers: { Authorization: `Bearer ${elevatedToken}` } }
    )

    const { data, status, statusText } = response
    return { data, status, statusText }
  }
)

export const editingTerminalName = createAsyncThunk(
  `${SLICE_NAME}/editingTerminalName`,
  async (_, thunkAPI) => {
    const {
      terminalId,
      newName: name,
      newDisplayName: displayName,
    } = thunkAPI.getState().terminals.editingTerminalName

    const response = await editTerminalName({ terminalId, name, displayName })
    const { data, status, statusText } = response
    return { data, status, statusText }
  }
)

export const deleteTerminal = createAsyncThunk(
  `${SLICE_NAME}/deleteTerminal`,
  async (_, thunkAPI) => {
    const { elevatedToken } = thunkAPI.getState().otac

    if (!elevatedToken) {
      throw new Error("web_c_general_notauthorized_error_text")
    }

    const { terminalId } = thunkAPI.getState().terminals.terminalDeletion

    const response = await deleteTerminalUser(terminalId, {
      headers: {
        Authorization: `Bearer ${elevatedToken}`,
      },
    })
    const { data, status, statusText } = response
    return { data, status, statusText }
  }
)

export const toggleTerminalVisibilityForAgents = createAsyncThunk(
  `${SLICE_NAME}/toggleTerminalVisibilityForAgents`,
  async (_, thunkAPI) => {
    const { elevatedToken } = thunkAPI.getState().otac

    if (!elevatedToken) {
      throw new Error("web_c_general_notauthorized_error_text")
    }

    const { terminalId, hiddenForAgents } =
      thunkAPI.getState().terminals.toggleTerminalVisibilityForAgents

    const fetchRequest = hiddenForAgents
      ? showTerminalForAgents
      : hideTerminalForAgents
    const response = await fetchRequest(terminalId, {
      headers: {
        Authorization: `Bearer ${elevatedToken}`,
      },
    })

    const { data, status, statusText } = response
    return { data, status, statusText }
  }
)

export const changingTerminalPassword = createAsyncThunk(
  `${SLICE_NAME}/changingTerminalPassword`,
  async (_, thunkAPI) => {
    const { elevatedToken } = thunkAPI.getState().otac

    if (!elevatedToken)
      throw new Error("web_c_general_notauthorized_error_text")

    const { terminalId, password } =
      thunkAPI.getState().terminals.changingTerminalPassword

    try {
      const response = await changeTerminalPassword(
        { terminalId, password },
        { headers: { Authorization: `Bearer ${elevatedToken}` } }
      )
      const { data, status, statusText } = response
      return { data, status, statusText }
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data)
    }
  }
)

export const terminalCreationCheckPhoneNumberExistence = createAsyncThunk(
  `${SLICE_NAME}/terminalCreationCheckPhoneNumberExistence`,
  /**
   * @param {string} phoneNumber
   */
  async (phoneNumber) => {
    const response = await checkPhoneNumberExistence({
      dialingCode: IRAQ_DAILING_CODE,
      phoneNumber,
      userGroup: USER_GROUP_CORPORATE,
    })

    const { data, status, statusText } = response
    return { data, status, statusText }
  }
)

export const fetchTerminalTransactionHistory = createAsyncThunk(
  `${SLICE_NAME}/fetchTerminalTransactionHistory`,
  fetchMyTerminalTransactionsPayloadCreator
)

export const initialState = {
  /**
   * terminals is an array of objects that gives information
   * about each terminal in terminal page
   * @type {object[]}
   */
  terminalUsers: undefined,

  /**
   * represents the state of loading the terminals
   * @type {boolean}
   */
  isTerminalUsersLoading: false,

  /**
   * describes the state of the terminals page
   */
  isFirst: false,
  isLast: false,
  pageNumber: undefined,
  pageSize: undefined,
  totalElements: undefined,
  totalPages: undefined,

  /**
   * terminals is an array of objects that gives information
   * about each terminal in terminal page including the
   * deleted terminals.
   * @type {object[]}
   */
  terminalUsersIncludingDeleted: undefined,

  /**
   * represents the state of loading the terminals
   * including the deleted terminals
   * @type {boolean}
   */
  isTerminalUsersIncludingDeletedLoading: false,

  /**
   * represents the state of error for the terminals
   * including the deleted terminals
   * @type {object}
   */
  terminalUsersIncludingDeletedError: undefined,

  /**
   * represents the state of error for the terminals
   * @type {object}
   */
  terminalUsersError: undefined,

  /**
   * terminal details is an object that has information
   * about each terminal details in terminal details page
   * @type {object}
   */
  terminalDetails: {
    /**
     * transactions hast the data to show on the transaction
     * for each terminal
     * @type {object[]}
     */
    transactions: undefined,

    /**
     * represents the state of loading the terminal details
     * @type {boolean}
     */
    isTerminalTransactionsLoading: false,

    /**
     * represents the state of error for the terminal details
     * @type {object}
     */
    terminalTransactionError: undefined,

    /**
     * contains datas related to a particular terminal
     * @type {object}
     */
    terminalInfo: undefined,

    /**
     * represents the state of loading the terminal info
     * @type {boolean}
     */
    isTerminalInfoLoading: false,

    /**
     * represents the error of the terminal info if exists
     * @type {boolean}
     */
    terminalInfoError: undefined,

    /**
     * describes the state of the terminal details page
     */
    isFirst: false,
    isLast: false,
    pageNumber: undefined,
    pageSize: undefined,
    totalElements: undefined,
    totalPages: undefined,
  },

  changingTerminalPassword: {
    /**
     * @enum
     * @type { CHANGING_TERMINAL_PASSWORD_STATE }
     */
    state: CHANGING_TERMINAL_PASSWORD_STATE.IDLE,

    /**
     * @type {string}
     */
    terminalId: undefined,

    /**
     * @type {string}
     */
    password: undefined,

    /**
     * @type {object}
     */
    error: undefined,
  },

  /**
   * State for tracking terminal creation process
   */
  terminalCreation: {
    /**
     * @type { TERMINAL_CREATION_STATE }
     */
    state: TERMINAL_CREATION_STATE.IDLE,

    /**
     * @type {string}
     */
    createdTerminalId: undefined,

    /**
     * @type {string}
     */
    name: undefined,

    /**
     * @type {string}
     */
    displayName: undefined,

    /**
     * @type {string}
     */
    phoneNumber: undefined,

    /**
     * @type {string}
     */
    isCheckingPhoneNumberUsage: false,

    /**
     * @type {string}
     */
    isPhoneNumberUsedBefore: undefined,

    /**
     * @type {string}
     */
    password: undefined,

    /**
     * @type {import("@reduxjs/toolkit").SerializedError}
     */
    error: undefined,
  },

  /**
   * State for tracking terminal creation process
   */
  editingTerminalName: {
    /**
     * @type { EDITING_TERMINAL_NAME_STATE }
     */
    state: EDITING_TERMINAL_NAME_STATE.IDLE,

    /**
     * @type {string}
     */
    terminalId: undefined,

    /**
     * @type {string}
     */
    oldName: undefined,

    /**
     * @type {string}
     */
    newName: undefined,

    /**
     * @type {string}
     */
    oldDisplayName: undefined,

    /**
     * @type {string}
     */
    newDisplayName: undefined,

    /**
     * @type {import("@reduxjs/toolkit").SerializedError}
     */
    error: undefined,
  },

  /**
   * State for tracking terminal deletion process
   */
  terminalDeletion: {
    /**
     * @type { DELETING_TERMINAL_STATE }
     */
    state: TERMINAL_DELETION_STATE.IDLE,

    /**
     * @type {string}
     */
    terminalId: undefined,

    /**
     * @type {string}
     */
    name: undefined,

    /**
     * @type {import("@reduxjs/toolkit").SerializedError}
     */
    error: undefined,
  },

  /**
   * State for tracking toggle terminal visibility for agents process
   */
  toggleTerminalVisibilityForAgents: {
    /**
     * @type { TOGGLE_TERMINAL_VISIBILITY_FOR_AGENTS_STATE }
     */
    state: TOGGLE_TERMINAL_VISIBILITY_FOR_AGENTS_STATE.IDLE,

    /**
     * @type {string}
     */
    terminalId: undefined,

    /**
     * @type {string}
     */
    name: undefined,

    /**
     * @type {boolean}
     */
    hiddenForAgents: false,

    /**
     * @type {import("@reduxjs/toolkit").SerializedError}
     */
    error: undefined,
  },

  /**
   * State for tracking shift money
   */
  shiftMoney: {
    /**
     * @type { EDITING_TERMINAL_NAME_STATE }
     */
    state: SHIFT_MONEY_STATE.IDLE,

    /**
     * @typedef {Object} FormattedAccount
     * @property {string} FormattedAccount.id
     * @property {string} FormattedAccount.name
     *
     *
     * @type {FormattedAccount}
     */
    sourceAccount: undefined,

    /**
     * These states are for getting the terminals for the shift money selectBox as chunks by {page} instead of getting all of them together
     */
    /**
     * @type {object[]}
     */
    sourceTerminalUsers: [],
    isSourceTerminalUsersLoading: false,
    hasMoreSourceTerminalUsers: false,
    sourceTerminalUsersPageNumber: 0,

    /**
     * @type {object[]}
     */
    targetTerminalUsers: [],
    isTargetTerminalUsersLoading: false,
    hasMoreTargetTerminalUsers: false,
    targetTerminalUsersPageNumber: 0,

    /**
     * @type {number}
     */
    amount: undefined,

    /**
     * @type {number}
     */
    createdAt: undefined,

    /**
     * @type {object}
     */
    possibleAmountRange: undefined,

    /**
     * @type {object}
     */
    senderBalance: undefined,

    /**
     * @type {import("@reduxjs/toolkit").SerializedError}
     */
    configurationError: undefined,

    /**
     * @type {FormattedAccount}
     */
    targetAccount: undefined,

    /**
     * @type {object}
     * @property {string} transactionId
     * @property {string} readableId
     * @property {number} expiresAt
     *
     */
    transactionInitiation: undefined,

    /**
     * @type {boolean}
     */
    isTransactionConfirmed: false,
    /**
     * @type {boolean}
     */
    isTransactionConfirmedLoading: false,

    /**
     * @type {import("@reduxjs/toolkit").SerializedError}
     */
    error: undefined,
  },
}

const terminalsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    // -------- Terminals Page -------- \\
    resetTerminalUsersError: (state) => {
      state.terminalUsersError = undefined
    },

    setTerminalUsers: (state, action) => {
      state.terminalUsers = action.payload
    },

    resetTerminalUsers: (state) => {
      state.terminalUsers = initialState.terminalUsers
    },

    // -------- Terminal Details -------- \\

    resetTerminalDetails: (state) => {
      state.terminalDetails = initialState.terminalDetails
    },
    // -------- Terminal Creation -------- \\

    terminalCreationStarted: (state) => {
      state.terminalCreation.state = TERMINAL_CREATION_STATE.STARTED
      state.terminalCreation.name = initialState.terminalCreation.name
      state.terminalCreation.phoneNumber =
        initialState.terminalCreation.phoneNumber
    },
    /**
     * @param {object} action
     * @param {object} action.payload
     * @param {string} action.payload.name
     * @param {string} action.payload.phoneNumber
     */
    terminalCreationInformationSet: (state, action) => {
      state.terminalCreation.state = TERMINAL_CREATION_STATE.INFORMATION_SET
      state.terminalCreation.name = action.payload.name
      state.terminalCreation.phoneNumber = action.payload.phoneNumber
      if (action.payload.displayName)
        state.terminalCreation.displayName = action.payload.displayName
    },
    /**
     * @param {object} action
     * @param {object} action.payload
     * @param {string} action.payload.password
     */
    terminalCreationPasswordSet: (state, action) => {
      state.terminalCreation.state = TERMINAL_CREATION_STATE.PASSWORD_SET
      state.terminalCreation.password = action.payload.password
    },
    terminalCreationAuthorizing: (state) => {
      state.terminalCreation.state = TERMINAL_CREATION_STATE.AUTHORIZING
    },
    terminalCreationAborting: (state) => {
      state.terminalCreation.state = TERMINAL_CREATION_STATE.ABORTING
    },
    terminalCreationRejected: (state) => {
      state.terminalCreation.state = TERMINAL_CREATION_STATE.REJECTED
    },
    terminalCreationAuthorized: (state) => {
      state.terminalCreation.state = TERMINAL_CREATION_STATE.AUTHORIZED
    },
    terminalCreationFinished: (state) => {
      state.terminalCreation = initialState.terminalCreation
    },

    // -------- Edit Terminal Name -------- \\
    /**
     * @typedef EditNameStartedPayloadAction
     * @property {string} id - terminal id
     * @property {string} name - terminal name
     *
     *
     * @param { import("@reduxjs/toolkit").PayloadAction<EditNameStartedPayloadAction> } action
     */
    editingTerminalNameStarted: (state, action) => {
      state.editingTerminalName.state = EDITING_TERMINAL_NAME_STATE.STARTED
      state.editingTerminalName.terminalId = action.payload.id
      state.editingTerminalName.oldName = action.payload.name
      if (action.payload.displayName)
        state.editingTerminalName.oldDisplayName = action.payload.displayName
    },

    editingTerminalNameIdled: (state) => {
      state.editingTerminalName = initialState.editingTerminalName
    },

    /**
     * @typedef InformationSetPayloadAction
     * @property {string} name
     *
     *
     * @param { import("@reduxjs/toolkit").PayloadAction<InformationSetPayloadAction> } action
     */

    editingTerminalNameSetName: (state, action) => {
      state.editingTerminalName.state = EDITING_TERMINAL_NAME_STATE.NAME_SET
      state.editingTerminalName.newName = action.payload.name
      if (action.payload.displayName)
        state.editingTerminalName.newDisplayName = action.payload.displayName
    },

    // -------- Terminal Deletion -------- \\

    /**
     * @typedef TerminalDeletionStartedPayloadAction
     * @property {string} id - terminal id
     * @property {string} name - terminal name
     *
     *
     * @param { import("@reduxjs/toolkit").PayloadAction<TerminalDeletionStartedPayloadAction> } action
     */
    terminalDeletionStarted: (state, action) => {
      state.terminalDeletion.state = TERMINAL_DELETION_STATE.STARTED
      state.terminalDeletion.terminalId = action.payload.id
      state.terminalDeletion.name = action.payload.name
    },

    terminalDeletionAuthorizing: (state) => {
      state.terminalDeletion.state = TERMINAL_DELETION_STATE.AUTHORIZING
    },

    terminalDeletionAborting: (state) => {
      state.terminalDeletion.state = TERMINAL_DELETION_STATE.ABORTING
    },

    terminalDeletionRejected: (state) => {
      state.terminalDeletion.state = TERMINAL_DELETION_STATE.REJECTED
    },

    terminalDeletionAuthorized: (state) => {
      state.terminalDeletion.state = TERMINAL_DELETION_STATE.AUTHORIZED
    },

    terminalDeletionFinished: (state) => {
      state.terminalDeletion = initialState.terminalDeletion
    },

    // -------- Toggle Terminal Visibility For Agents  -------- \\

    /**
     * @typedef ToggleTerminalVisibilityForAgentsStartedPayloadAction
     * @property {string} id - terminal id
     * @property {string} name - terminal name
     *
     *
     * @param { import("@reduxjs/toolkit").PayloadAction<ToggleTerminalVisibilityForAgentsStartedPayloadAction> } action
     */
    toggleTerminalVisibilityForAgentsStarted: (state, action) => {
      state.toggleTerminalVisibilityForAgents.state =
        TOGGLE_TERMINAL_VISIBILITY_FOR_AGENTS_STATE.STARTED
      state.toggleTerminalVisibilityForAgents.terminalId = action.payload.id
      state.toggleTerminalVisibilityForAgents.name = action.payload.name
      state.toggleTerminalVisibilityForAgents.hiddenForAgents =
        action.payload.hiddenForAgents
    },

    toggleTerminalVisibilityForAgentsAuthorizing: (state) => {
      state.toggleTerminalVisibilityForAgents.state =
        TOGGLE_TERMINAL_VISIBILITY_FOR_AGENTS_STATE.AUTHORIZING
    },

    toggleTerminalVisibilityForAgentsAborting: (state) => {
      state.toggleTerminalVisibilityForAgents.state =
        TOGGLE_TERMINAL_VISIBILITY_FOR_AGENTS_STATE.ABORTING
    },

    toggleTerminalVisibilityForAgentsRejected: (state) => {
      state.toggleTerminalVisibilityForAgents.state =
        TOGGLE_TERMINAL_VISIBILITY_FOR_AGENTS_STATE.REJECTED
    },

    toggleTerminalVisibilityForAgentsAuthorized: (state) => {
      state.toggleTerminalVisibilityForAgents.state =
        TOGGLE_TERMINAL_VISIBILITY_FOR_AGENTS_STATE.AUTHORIZED
    },

    toggleTerminalVisibilityForAgentsFinished: (state) => {
      state.toggleTerminalVisibilityForAgents =
        initialState.toggleTerminalVisibilityForAgents
    },

    // -------- Shift Money -------- \\

    shiftMoneyStarted: (state) => {
      state.shiftMoney.state = SHIFT_MONEY_STATE.STARTED
    },
    /**
     * @param {object} action
     * @param {object} action.payload
     * @param {string} action.payload.sourceAccount
     * @param {number} action.payload.amount
     * @param {string} action.payload.targetAccount
     */
    shiftMoneyInformationSet: (state, action) => {
      state.shiftMoney.state = SHIFT_MONEY_STATE.INFORMATION_SET
      state.shiftMoney.sourceAccount = action.payload.sourceAccount
      state.shiftMoney.amount = action.payload.amount
      state.shiftMoney.targetAccount = action.payload.targetAccount
    },
    shiftMoneyAuthorizing: (state) => {
      state.shiftMoney.state = SHIFT_MONEY_STATE.AUTHORIZING
    },
    shiftMoneyAborting: (state) => {
      state.shiftMoney.state = SHIFT_MONEY_STATE.ABORTING
    },
    shiftMoneyRejected: (state) => {
      state.shiftMoney.state = SHIFT_MONEY_STATE.REJECTED
    },
    shiftMoneyConfirmation: (state) => {
      state.shiftMoney.state = SHIFT_MONEY_STATE.CONFIRMATION
    },
    shiftMoneyFinished: (state) => {
      state.shiftMoney = initialState.shiftMoney
    },
    resetShitMoneySourceTerminalUsers: (state) => {
      state.shiftMoney.sourceTerminalUsers = []
    },
    resetShitMoneyTargetTerminalUsers: (state) => {
      state.shiftMoney.targetTerminalUsers = []
    },

    // -------- Change Terminal Password -------- \\

    /**
     * @typedef ChangeTerminalPasswordStartedPayloadAction
     * @property {string} id - terminal id
     * @property {string} phoneNumber - terminal phone number
     *
     *
     * @param { import("@reduxjs/toolkit").PayloadAction<ChangeTerminalPasswordStartedPayloadAction> } action
     */
    changingTerminalPasswordStarted: (state, action) => {
      state.changingTerminalPassword.state =
        CHANGING_TERMINAL_PASSWORD_STATE.STARTED
      state.changingTerminalPassword.terminalId = action.payload.id
    },
    changingTerminalPasswordSet: (state, action) => {
      state.changingTerminalPassword.state =
        CHANGING_TERMINAL_PASSWORD_STATE.PASSWORD_SET
      state.changingTerminalPassword.password = action.payload.password
    },
    changingTerminalPasswordAuthorizing: (state) => {
      state.changingTerminalPassword.state =
        CHANGING_TERMINAL_PASSWORD_STATE.AUTHORIZING
    },
    changingTerminalPasswordAborting: (state) => {
      state.changingTerminalPassword.state =
        CHANGING_TERMINAL_PASSWORD_STATE.ABORTING
    },
    changingTerminalPasswordRejected: (state) => {
      state.changingTerminalPassword.state =
        CHANGING_TERMINAL_PASSWORD_STATE.REJECTED
    },
    changingTerminalPasswordAuthorized: (state) => {
      state.changingTerminalPassword.state =
        CHANGING_TERMINAL_PASSWORD_STATE.AUTHORIZED
    },
    changingTerminalPasswordFinished: (state) => {
      state.changingTerminalPassword = initialState.changingTerminalPassword
    },
  },

  extraReducers: (builder) => {
    // -------- Terminal Users Including Deleted Terminals -------- \\
    builder.addCase(fetchTerminalUsersIncludingDeleted.pending, (state) => {
      state.isTerminalUsersIncludingDeletedLoading = true
    })
    builder.addCase(
      fetchTerminalUsersIncludingDeleted.fulfilled,
      (state, action) => {
        state.terminalUsersIncludingDeleted = action.payload.data.terminals
        state.isTerminalUsersIncludingDeletedLoading = false
      }
    )
    builder.addCase(
      fetchTerminalUsersIncludingDeleted.rejected,
      (state, action) => {
        state.isTerminalUsersIncludingDeletedLoading = false
        state.terminalUsersIncludingDeletedError = {
          name: action.error.name,
          message: action.error.message,
          code: action.error.code,
        }
      }
    )
    // -------- Terminal Users -------- \\
    builder.addCase(fetchMyTerminalUsers.pending, (state) => {
      state.isTerminalUsersLoading = true
    })
    builder.addCase(fetchMyTerminalUsers.fulfilled, (state, action) => {
      state.isTerminalUsersLoading = false
      state.terminalUsers = action.payload.data.content
      state.isFirst = action.payload.data.first
      state.isLast = action.payload.data.last
      state.pageNumber = action.payload.data.pageNumber
      state.pageSize = action.payload.data.pageSize
      state.totalElements = action.payload.data.totalElements
      state.totalPages = action.payload.data.totalPages
    })
    builder.addCase(fetchMyTerminalUsers.rejected, (state, action) => {
      state.isTerminalUsersLoading = false
      state.terminalUsersError = {
        name: action.error.name,
        message: action.error.message,
        code: action.error.code,
      }
    })
    // -------- Terminal Info -------- \\
    builder.addCase(fetchTerminalInfo.pending, (state) => {
      state.terminalDetails.isTerminalInfoLoading = true
      state.terminalDetails.terminalInfoError = undefined
    })
    builder.addCase(fetchTerminalInfo.fulfilled, (state, action) => {
      state.terminalDetails.isTerminalInfoLoading = false
      state.terminalDetails.terminalInfoError = undefined
      state.terminalDetails.terminalInfo = action.payload.data
    })
    builder.addCase(fetchTerminalInfo.rejected, (state, action) => {
      state.terminalDetails.isTerminalInfoLoading = false

      let error = {
        code: GENERAL_ERROR,
        message: "web_c_general_error_massage",
      }

      if (action.payload?.data) {
        const { data, status } = action.payload
        error = { ...data.errors[0], status }
      }

      state.terminalDetails.terminalInfoError = error
    })
    // -------- Check for phone number existance -------- \\
    builder.addCase(
      terminalCreationCheckPhoneNumberExistence.pending,
      (state) => {
        state.terminalCreation.isCheckingPhoneNumberUsage = true
        state.terminalCreation.isPhoneNumberUsedBefore =
          initialState.terminalCreation.isPhoneNumberUsedBefore
      }
    )
    builder.addCase(
      terminalCreationCheckPhoneNumberExistence.fulfilled,
      (state, action) => {
        state.terminalCreation.isCheckingPhoneNumberUsage = false
        state.terminalCreation.isPhoneNumberUsedBefore =
          action.payload.data.exists

        if (
          state.terminalCreation.error?.name === PHONE_NUMBER_USAGE_CHECK_FAILED
        ) {
          state.terminalCreation.error = undefined
        }
      }
    )
    builder.addCase(
      terminalCreationCheckPhoneNumberExistence.rejected,
      (state) => {
        state.terminalCreation.error = { name: PHONE_NUMBER_USAGE_CHECK_FAILED }
        state.terminalCreation.isCheckingPhoneNumberUsage = false
      }
    )
    // -------- Terminal Creation -------- \\
    builder.addCase(terminalCreationSavingTerminal.pending, (state) => {
      state.terminalCreation.state = TERMINAL_CREATION_STATE.CREATING
    })
    builder.addCase(
      terminalCreationSavingTerminal.fulfilled,
      (state, action) => {
        state.terminalCreation.createdTerminalId = action.payload.data.id
        state.terminalCreation.state = TERMINAL_CREATION_STATE.CREATED
      }
    )
    builder.addCase(
      terminalCreationSavingTerminal.rejected,
      (state, action) => {
        state.terminalCreation.state = TERMINAL_CREATION_STATE.ERRORED
        state.terminalCreation.error = {
          name: action.error.name,
          message: action.error.message,
        }
      }
    )
    // -------- Editing Terminal Name -------- \\
    builder.addCase(editingTerminalName.fulfilled, (state) => {
      state.editingTerminalName.state = EDITING_TERMINAL_NAME_STATE.DONE
    })
    builder.addCase(editingTerminalName.rejected, (state, action) => {
      state.editingTerminalName.state = EDITING_TERMINAL_NAME_STATE.ERRORED
      state.editingTerminalName.error = {
        name: action.error.name,
        message: action.error.message,
      }
    })
    // -------- Terminal Deletion -------- \\
    builder.addCase(deleteTerminal.pending, (state) => {
      state.terminalDeletion.state = TERMINAL_DELETION_STATE.DELETING
    })

    builder.addCase(deleteTerminal.fulfilled, (state) => {
      state.terminalDeletion.state = TERMINAL_DELETION_STATE.DELETED
    })

    builder.addCase(deleteTerminal.rejected, (state, action) => {
      state.terminalDeletion.state = TERMINAL_DELETION_STATE.ERRORED
      state.terminalDeletion.error = {
        name: action.error.name,
        message: action.error.message,
      }
    })

    // -------- Toggle Terminal Visibility For Agents -------- \\
    builder.addCase(toggleTerminalVisibilityForAgents.pending, (state) => {
      state.toggleTerminalVisibilityForAgents.state =
        TOGGLE_TERMINAL_VISIBILITY_FOR_AGENTS_STATE.CHANGING
    })

    builder.addCase(toggleTerminalVisibilityForAgents.fulfilled, (state) => {
      state.toggleTerminalVisibilityForAgents.state =
        TOGGLE_TERMINAL_VISIBILITY_FOR_AGENTS_STATE.CHANGED
    })

    builder.addCase(
      toggleTerminalVisibilityForAgents.rejected,
      (state, action) => {
        state.toggleTerminalVisibilityForAgents.state =
          TOGGLE_TERMINAL_VISIBILITY_FOR_AGENTS_STATE.ERRORED
        state.toggleTerminalVisibilityForAgents.error = {
          name: action.error.name,
          message: action.error.message,
        }
      }
    )

    // ----- Change Terminal Password ------ \\
    builder.addCase(changingTerminalPassword.fulfilled, (state) => {
      state.changingTerminalPassword.state =
        CHANGING_TERMINAL_PASSWORD_STATE.CHANGED
    })
    builder.addCase(changingTerminalPassword.rejected, (state, action) => {
      state.changingTerminalPassword.state =
        CHANGING_TERMINAL_PASSWORD_STATE.ERRORED

      const error = { message: "web_c_general_error_massage" }

      if (Array.isArray(action.payload?.errors)) {
        const { detail } = action.payload.errors[0]
        error.message = detail
      } else if (action.error.message !== "Rejected") {
        error.message = action.error.message
      }

      state.changingTerminalPassword.error = error
    })

    // -------- Terminal Transactions -------- \\
    builder.addCase(fetchTerminalTransactionHistory.pending, (state) => {
      state.terminalDetails.isTerminalTransactionsLoading = true
    })
    builder.addCase(
      fetchTerminalTransactionHistory.fulfilled,
      (state, action) => {
        const {
          content,
          first,
          last,
          pageNumber,
          pageSize,
          totalElements,
          totalPages,
        } = action.payload.data
        state.terminalDetails.isTerminalTransactionsLoading = false
        state.terminalDetails.transactions = transformTerminalTransactionData(
          content,
          action.meta.arg.isRTL
        )
        state.terminalDetails.isFirst = first
        state.terminalDetails.isLast = last
        state.terminalDetails.pageNumber = pageNumber
        state.terminalDetails.pageSize = pageSize
        state.terminalDetails.totalElements = totalElements
        state.terminalDetails.totalPages = totalPages
      }
    )
    builder.addCase(
      fetchTerminalTransactionHistory.rejected,
      (state, action) => {
        state.terminalDetails.isTerminalTransactionsLoading = false
        state.terminalDetails.terminalTransactionError = {
          name: action.error.name,
          message: action.error.message,
          code: action.error.code,
        }
      }
    )
    // -------- shift money -------- \\
    builder.addCase(
      fetchTerminalTransferConfiguration.fulfilled,
      (state, action) => {
        state.shiftMoney.possibleAmountRange = action.payload.data.amountRange
        state.shiftMoney.senderBalance = action.payload.data.balance
      }
    )
    builder.addCase(
      fetchTerminalTransferConfiguration.rejected,
      (state, action) => {
        state.shiftMoney.configurationError = {
          name: action.error.name,
          message: action.error.message,
        }
      }
    )
    builder.addCase(
      fetchTerminalTransferTransactionDetails.fulfilled,
      (state, action) => {
        state.shiftMoney.state = SHIFT_MONEY_STATE.INFORMATION_SET
        state.shiftMoney.sourceAccount = action.payload.data.sourceAccount
        state.shiftMoney.amount = action.payload.data.monetaryValue.amount
        state.shiftMoney.targetAccount = action.payload.data.targetAccount
        state.shiftMoney.createdAt = action.payload.data.createdAt
      }
    )
    builder.addCase(
      fetchTerminalTransferTransactionDetails.rejected,
      (state, action) => {
        state.shiftMoney.state = SHIFT_MONEY_STATE.ERRORED
        state.shiftMoney.error = action.error
      }
    )
    builder.addCase(transactionInitiation.fulfilled, (state, action) => {
      state.shiftMoney.state = SHIFT_MONEY_STATE.CONFIRMATION
      state.shiftMoney.transactionInitiation = action.payload.data
    })
    builder.addCase(transactionInitiation.rejected, (state, action) => {
      state.shiftMoney.state = SHIFT_MONEY_STATE.ERRORED

      const preparedError = {
        code: GENERAL_ERROR,
        message: "web_c_general_error_massage",
      }

      if (Array.isArray(action.payload?.errors)) {
        const [firstError] = action.payload.errors
        preparedError.code = firstError.code
        preparedError.message = firstError.detail
      }

      state.shiftMoney.error = preparedError
    })
    builder.addCase(transactionConfirmation.pending, (state) => {
      state.shiftMoney.isTransactionConfirmedLoading = true
    })
    builder.addCase(transactionConfirmation.fulfilled, (state) => {
      state.shiftMoney.state = SHIFT_MONEY_STATE.AUTHORIZING
      state.shiftMoney.isTransactionConfirmedLoading = false
      state.shiftMoney.isTransactionConfirmed = true
    })
    builder.addCase(transactionConfirmation.rejected, (state, action) => {
      state.shiftMoney.state = SHIFT_MONEY_STATE.ERRORED
      state.shiftMoney.isTransactionConfirmedLoading = false
      state.shiftMoney.error = {
        name: action.error.name,
        message: action.error.message,
      }
    })
    builder.addCase(confirmedTransactionAuthorization.fulfilled, (state) => {
      state.shiftMoney.state = SHIFT_MONEY_STATE.SHIFTED
    })
    builder.addCase(
      confirmedTransactionAuthorization.rejected,
      (state, action) => {
        state.shiftMoney.state = SHIFT_MONEY_STATE.ERRORED

        const error = { message: "web_c_general_error_massage" }

        if (Array.isArray(action.payload?.errors)) {
          const { detail } = action.payload.errors[0]
          error.message = detail
        } else if (action.error.message !== "Rejected") {
          error.message = action.error.message
        }

        state.shiftMoney.error = error
      }
    )
    builder.addCase(fetchShiftMoneyTerminalUsers.pending, (state, action) => {
      switch (action.meta.arg.accountToBeUpdated) {
        case ACCOUNT_TO_BE_UPDATED.BOTH:
          state.shiftMoney.isSourceTerminalUsersLoading = true
          state.shiftMoney.isTargetTerminalUsersLoading = true
          break
        case ACCOUNT_TO_BE_UPDATED.SOURCE:
          state.shiftMoney.isSourceTerminalUsersLoading = true
          break
        case ACCOUNT_TO_BE_UPDATED.TARGET:
          state.shiftMoney.isTargetTerminalUsersLoading = true
          break
        default:
      }
    })
    builder.addCase(fetchShiftMoneyTerminalUsers.fulfilled, (state, action) => {
      switch (action.meta.arg.accountToBeUpdated) {
        case ACCOUNT_TO_BE_UPDATED.BOTH:
          // updating source state
          state.shiftMoney.isSourceTerminalUsersLoading = false
          state.shiftMoney.sourceTerminalUsers = [
            ...state.shiftMoney.sourceTerminalUsers,
            ...action.payload.data.content,
          ]
          state.shiftMoney.hasMoreSourceTerminalUsers =
            !action.payload.data.last
          state.shiftMoney.sourceTerminalUsersPageNumber =
            action.payload.data.pageNumber

          // updating target states
          state.shiftMoney.isTargetTerminalUsersLoading = false
          state.shiftMoney.targetTerminalUsers = [
            ...state.shiftMoney.targetTerminalUsers,
            ...action.payload.data.content,
          ]
          state.shiftMoney.hasMoreTargetTerminalUsers =
            !action.payload.data.last
          state.shiftMoney.targetTerminalUsersPageNumber =
            action.payload.data.pageNumber
          break
        case ACCOUNT_TO_BE_UPDATED.SOURCE:
          // updating source state
          state.shiftMoney.isSourceTerminalUsersLoading = false
          state.shiftMoney.sourceTerminalUsers = [
            ...state.shiftMoney.sourceTerminalUsers,
            ...action.payload.data.content,
          ]
          state.shiftMoney.hasMoreSourceTerminalUsers =
            !action.payload.data.last
          state.shiftMoney.sourceTerminalUsersPageNumber =
            action.payload.data.pageNumber
          break
        case ACCOUNT_TO_BE_UPDATED.TARGET:
          // updating target states
          state.shiftMoney.isTargetTerminalUsersLoading = false
          state.shiftMoney.targetTerminalUsers = [
            ...state.shiftMoney.targetTerminalUsers,
            ...action.payload.data.content,
          ]
          state.shiftMoney.hasMoreTargetTerminalUsers =
            !action.payload.data.last
          state.shiftMoney.targetTerminalUsersPageNumber =
            action.payload.data.pageNumber
          break
        default:
      }
    })
    builder.addCase(fetchShiftMoneyTerminalUsers.rejected, (state, action) => {
      switch (action.meta.arg.accountToBeUpdated) {
        case ACCOUNT_TO_BE_UPDATED.BOTH:
          state.shiftMoney.isSourceTerminalUsersLoading = false
          state.shiftMoney.isTargetTerminalUsersLoading = false
          break
        case ACCOUNT_TO_BE_UPDATED.SOURCE:
          state.shiftMoney.isSourceTerminalUsersLoading = false
          break
        case ACCOUNT_TO_BE_UPDATED.TARGET:
          state.shiftMoney.isTargetTerminalUsersLoading = false
          break
        default:
      }
      state.shiftMoney.state = SHIFT_MONEY_STATE.ERRORED

      const error = { message: "web_c_general_error_massage" }

      if (Array.isArray(action.payload?.errors)) {
        const { detail } = action.payload.errors[0]
        error.message = detail
      } else if (action.error.message !== "Rejected") {
        error.message = action.error.message
      }

      state.shiftMoney.error = error
    })
  },
})

export const {
  // -------- Terminals Page -------- \\
  resetTerminalUsersError,
  resetTerminalUsers,
  setTerminalUsers,
  // -------- Terminal Details -------- \\
  resetTerminalDetails,
  // -------- Terminal Creation -------- \\
  terminalCreationStarted,
  terminalCreationInformationSet,
  terminalCreationPasswordSet,
  terminalCreationAuthorizing,
  terminalCreationAborting,
  terminalCreationRejected,
  terminalCreationAuthorized,
  terminalCreationFinished,
  // -------- Edit Terminal Name -------- \\
  editingTerminalNameIdled,
  editingTerminalNameStarted,
  editingTerminalNameSetName,
  // -------- Terminal Deletion -------- \\
  terminalDeletionStarted,
  terminalDeletionAuthorizing,
  terminalDeletionAborting,
  terminalDeletionAuthorized,
  terminalDeletionRejected,
  terminalDeletionFinished,
  // -------- Toggle Terminal Visibility For Agents -------- \\
  toggleTerminalVisibilityForAgentsStarted,
  toggleTerminalVisibilityForAgentsAuthorizing,
  toggleTerminalVisibilityForAgentsAborting,
  toggleTerminalVisibilityForAgentsAuthorized,
  toggleTerminalVisibilityForAgentsRejected,
  toggleTerminalVisibilityForAgentsFinished,
  // ----------- Shift Money ----------- \\
  shiftMoneyStarted,
  shiftMoneyInformationSet,
  shiftMoneyConfirmation,
  shiftMoneyAuthorizing,
  shiftMoneyAborting,
  shiftMoneyAuthorized,
  shiftMoneyFinished,
  shiftMoneyRejected,
  resetShitMoneySourceTerminalUsers,
  resetShitMoneyTargetTerminalUsers,
  // ----- Change Terminal Password ------ \\
  changingTerminalPasswordStarted,
  changingTerminalPasswordSet,
  changingTerminalPasswordAuthorizing,
  changingTerminalPasswordAborting,
  changingTerminalPasswordRejected,
  changingTerminalPasswordAuthorized,
  changingTerminalPasswordFinished,
} = terminalsSlice.actions

export default terminalsSlice.reducer
