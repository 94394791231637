import { ReactComponent as ErrorIcon } from "../../assets/icons/error.svg"
import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg"

export const TOAST_TYPE_ERROR = "TOAST_TYPE_ERROR"
export const TOAST_TYPE_SUCCESS = "TOAST_TYPE_SUCCESS"

export const MAP_TOAST_TYPE_TO_ICON = {
  [TOAST_TYPE_ERROR]: ErrorIcon,
  [TOAST_TYPE_SUCCESS]: CheckIcon,
}
