import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import {
  fetchCurrentUserBankAccounts,
  fetchBankAccountBalanceHistory,
  getAggregativeBalanceHistory,
} from "../../api/endpoints"
import {
  mergeAccountIdAndBalanceHistory,
  prepareAggregatedBalanceHistory,
  prepareBalancesInRange,
} from "./aggregatedBalanceHistory-helpers"

const SLICE_NAME = "accounts"

/**
 * Tries to fetch the accounts depending on the `accountTypes`
 * and return the `status`, `statusText` and actual `data`
 * @param  {string[]} accountTypes
 */
export const fetchMyBankAccountsPayloadCreator = async (accountTypes) => {
  const response = await fetchCurrentUserBankAccounts(accountTypes)
  const { data, status, statusText } = response
  return { data, status, statusText }
}

export const fetchMyBankAccounts = createAsyncThunk(
  `${SLICE_NAME}/fetchMyBankAccounts`,
  fetchMyBankAccountsPayloadCreator
)

/**
 * @param {object} info an object containing informations
 * to fetch certain balance history data.
 * @param {string} info.accountId the currency account id
 * of which the balance should be fetched.
 * @param { import("../../shared/constants/analytics/dashboard").BALANCE_HISTORY_OPTION_TODAY |
 *          import("../../shared/constants/analytics/dashboard").BALANCE_HISTORY_OPTION_THIS_WEEK |
 *          import("../../shared/constants/analytics/dashboard").BALANCE_HISTORY_OPTION_THIS_MONTH |
 *          import("../../shared/constants/analytics/dashboard").BALANCE_HISTORY_OPTION_THIS_YEAR
 * } info.timeFrame the time range balance data should consist of.
 */
export const fetchMyBankAccountBalanceHistoryPayloadCreator = async ({
  accountId,
  timeFrame,
}) => {
  const response = await fetchBankAccountBalanceHistory(accountId, timeFrame)
  const { data, status, statusText } = response
  return { data, status, statusText }
}

export const fetchMyBankAccountBalanceHistory = createAsyncThunk(
  `${SLICE_NAME}/fetchMyBankAccountBalanceHistory`,
  fetchMyBankAccountBalanceHistoryPayloadCreator
)

/**
 * @typedef {object} MonetaryValue
 * @property {number} amount
 * @property {import("../../shared/constants/bank-accounts").CURRENCY_TYPE} currency
 *
 * @typedef {object} BalanceHistoryItem
 * @property {boolean} hasValue
 * @property {MonetaryValue} monetaryValue
 * @property {number} timestamp
 *
 * @typedef {object} AggregatedBalanceHistory
 * @property {string} accountId
 * @property {BalanceHistoryItem[]} values
 *
 * @typedef {object} AggregatedBalanceHistoryResponse
 * @property {AggregatedBalanceHistory[]} data
 *
 * @param {object} info an object containing informations
 * to fetch certain aggregated balance history data.
 * @param {string} info.accountIds the list of account id
 * of which the balance should be fetched.
 * @param {string} info.currencies the list of currencies
 * of corresponding account id in @param info.accountIds
 * of which the balance should be fetched.
 * @param { import("../../shared/constants/analytics/dashboard").BALANCE_HISTORY_OPTION_CUSTOM } info.timeFrame the time range balance data should consist of.
 * @param {Date} info.startDate the start date of the range.
 * @param {Date} info.endDate the end date of the range.
 * @returns {Promise<AggregatedBalanceHistoryResponse}
 */
export const fetchAggregatedAccountBalanceHistoryPayloadCreator = async ({
  accountIds,
  currencies,
  timeFrame,
  startDate,
  endDate,
}) => {
  const response = await getAggregativeBalanceHistory({
    accountIds,
    currencies,
    startDate,
    endDate,
    timeFrame,
  })
  const { data, status, statusText } = response
  return { data, status, statusText }
}

export const fetchAggregatedAccountBalanceHistory = createAsyncThunk(
  `${SLICE_NAME}/fetchAggregatedAccountBalanceHistory`,
  fetchAggregatedAccountBalanceHistoryPayloadCreator
)

const initialState = {
  /**
   * Indicates whether accounts currently loading or not.
   */
  isAccountsLoading: false,

  /**
   * The last account types used to update accounts list
   * it can be used to refetch accounts with different
   * account types in different situations, and if you're
   * interested in current accounts, just use `accounts`.
   * @type {string[]}
   */
  lastAccountTypes: undefined,

  /**
   * List of balance accounts of current user. The list
   * contains all account types represented by `lastAccountTypes`
   */
  accounts: undefined,

  /**
   * List of balance history of current user. The list
   * contains balance of certain account and for a certain
   * timeframe.
   */
  balanceHistory: undefined,

  /**
   * Indicates whether Aggregative List of balance history loading or not.
   */
  isAggregatedBalanceHistoryLoading: false,

  /**
   * Aggregative List of balance history of current user and it's
   * and maybe it's Terminal users. The list contains balance of
   * certain accounts and for a custom duration.
   */
  aggregatedBalanceHistory: undefined,

  /**
   * Maximum balance in the aggregative list of balance history
   * caluclated on `aggregatedBalanceHistory`
   */
  aggregatedBalanceHistoryMaxBalance: 0,

  /**
   * Minimum balance in the aggregative list of balance history
   * caluclated on `aggregatedBalanceHistory`
   */
  aggregatedBalanceHistoryMinBalance: 0,

  /**
   * List of all transactions statistics which represents
   * `RECEIVED`, `SENT`, `ALL` transactions with their counts.
   */
  allTransactionStatistics: undefined,

  /**
   * Wether there is any `RECEIVED` or `SENT` transaction
   * since last time transaction statistics fetched.
   */
  anySentReceivedTransaction: true,

  /**
   * List of `RECEIVED`, `SENT` transactions statistics which
   * with their counts.
   */
  sentReceivedTransactionStatistics: undefined,
}

const accountsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchMyBankAccounts.pending, (state) => {
      state.isAccountsLoading = true
    })

    builder.addCase(fetchMyBankAccounts.fulfilled, (state, action) => {
      state.isAccountsLoading = false
      state.lastAccountTypes = action.meta.arg
      state.accounts = action.payload.data
    })

    builder.addCase(
      fetchMyBankAccountBalanceHistory.fulfilled,
      (state, action) => {
        state.balanceHistory = action.payload.data
      }
    )

    builder.addCase(fetchAggregatedAccountBalanceHistory.pending, (state) => {
      state.isAggregatedBalanceHistoryLoading = true
    })

    builder.addCase(
      fetchAggregatedAccountBalanceHistory.fulfilled,
      (state, action) => {
        const rawDataSets = mergeAccountIdAndBalanceHistory(action.payload.data)

        const aggregatedBalanceDataSet = prepareAggregatedBalanceHistory(
          rawDataSets,
          action.meta.arg.startDate,
          action.meta.arg.endDate
        )

        const allBalanceInRange = prepareBalancesInRange(rawDataSets)

        state.aggregatedBalanceHistory = aggregatedBalanceDataSet
        state.aggregatedBalanceHistoryMaxBalance = Math.max(
          ...allBalanceInRange
        )
        state.aggregatedBalanceHistoryMinBalance = Math.min(
          ...allBalanceInRange
        )
        state.isAggregatedBalanceHistoryLoading = false
      }
    )
  },
})

export default accountsSlice.reducer
