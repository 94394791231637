const routes = {
  home: {
    path: "/",
  },
  balance: {
    path: "/balance",
  },
  transactionHistory: {
    path: "/transaction-history",
  },
  quickTransfer: {
    path: "/quick-transfer",
  },
  bankTransfer: {
    path: "/bank-transfer",
  },
  verifyQrcode: {
    path: "/verify-qrcode",
  },
  verifyOTP: {
    path: "/verify-otp",
  },
  terminals: {
    path: "/terminals",

    terminalDetails: {
      path: "/terminals/:terminalId",
    },
  },
  employees: {
    path: "/employees",
  },
  teams: {
    path: "/teams",

    teamDetails: {
      path: "/teams/:teamId",
    },
  },
  payroll: {
    path: "/payroll",
  },
  transfer: {
    path: "/transfer",
  },
  roles: {
    path: "/roles",
  },
  users: {
    path: "/users",
  },
  pendingApprovals: {
    path: "/pending-approvals",
  },
  notAllowedUsers: {
    path: "/not-allowed-users",
  },
}

export default routes
