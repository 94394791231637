import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import {
  getTeams,
  addTeam,
  deleteTeam,
  getTeamDetails,
  updateTeam,
  getTeamsAndEmployees,
  getTeamMembers,
  deleteTeamMember,
} from "../../api/endpoints/teams"
import { TEAM_DELETION_STATE } from "../../shared/constants/teams/delete-team"
import { ADD_TEAM_STATE } from "../../shared/constants/teams/add-team"
import { EDIT_TEAM_STATE } from "../../shared/constants/teams/edit-team"
import { TEAM_MEMBER_DELETION_STATE } from "../../shared/constants/teams/team-details"
import { GENERAL_ERROR } from "../../shared/constants/general"

const SLICE_NAME = "teams"

export const teamAddition = createAsyncThunk(
  `${SLICE_NAME}/teamAddition`,
  async (_, thunkAPI) => {
    const { elevatedToken } = thunkAPI.getState().otac

    if (!elevatedToken) {
      throw new Error("web_c_general_notauthorized_error_text")
    }

    const { name, employeeIds } = thunkAPI.getState().teams.addTeam

    try {
      const response = await addTeam(
        { name: name.trim(), employeeIds },
        { headers: { Authorization: `Bearer ${elevatedToken}` } }
      )
      const { data, status, statusText } = response
      return { data, status, statusText }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

export const editingTeam = createAsyncThunk(
  `${SLICE_NAME}/editingTeam`,
  async (_, thunkAPI) => {
    const { elevatedToken } = thunkAPI.getState().otac

    if (!elevatedToken) {
      throw new Error("web_c_general_notauthorized_error_text")
    }

    const { id, name, employeeIds } = thunkAPI.getState().teams.editingTeam

    try {
      const response = await updateTeam(
        id,
        { name: name.trim(), employeeIds },
        { headers: { Authorization: `Bearer ${elevatedToken}` } }
      )
      const { data, status, statusText } = response
      return { data, status, statusText }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

export const fetchTeamsPayloadCreator = async ({ page, size, searchQuery }) => {
  const response = await getTeams({
    page,
    size,
    searchQuery,
  })
  const { data, status, statusText } = response
  return { data, status, statusText }
}

export const fetchTeamsAndEmployees = createAsyncThunk(
  `${SLICE_NAME}/fetchTeamsAndEmployees`,
  async () => {
    const { data, status, statusText } = await getTeamsAndEmployees()
    return { data, status, statusText }
  }
)

export const fetchTeams = createAsyncThunk(
  `${SLICE_NAME}/fetchTeams`,
  fetchTeamsPayloadCreator
)

export const teamDeletion = createAsyncThunk(
  `${SLICE_NAME}/teamDeletion`,
  async (_, { getState, rejectWithValue }) => {
    const { elevatedToken } = getState().otac
    if (!elevatedToken)
      throw new Error("web_c_general_notauthorized_error_text")

    const { id } = getState().teams.teamDeletion

    try {
      const response = await deleteTeam(id, {
        headers: { Authorization: `Bearer ${elevatedToken}` },
      })
      const { data, status, statusText } = response
      return { data, status, statusText }
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const fetchTeamDetailsPayloadCreator = async (id) => {
  const { data, status, statusText } = await getTeamDetails(id)
  return { data, status, statusText }
}

export const fetchTeamDetails = createAsyncThunk(
  `${SLICE_NAME}/fetchTeamDetails`,
  async (teamId, thunkAPI) => {
    try {
      return await fetchTeamDetailsPayloadCreator(teamId)
    } catch (error) {
      const { data, status } = error.response
      return thunkAPI.rejectWithValue({ data, status })
    }
  }
)

export const fetchTeamMembersPayloadCreator = async ({
  size,
  page,
  teamId,
  searchQuery,
}) => {
  const response = await getTeamMembers({
    size,
    page,
    teamId,
    searchQuery,
  })
  const { data, status, statusText } = response
  return { data, status, statusText }
}

export const fetchTeamMembers = createAsyncThunk(
  `${SLICE_NAME}/fetchTeamMembers`,
  fetchTeamMembersPayloadCreator
)

export const fetchEditingTeamMembers = createAsyncThunk(
  `${SLICE_NAME}/fetchEditingTeamMembers`,
  fetchTeamMembersPayloadCreator
)

export const fetchEditingTeamDetails = createAsyncThunk(
  `${SLICE_NAME}/fetchEditingTeamDetails`,
  (_, thunkAPI) => {
    const { id, state } = thunkAPI.getState().teams.editingTeam

    if (state !== EDIT_TEAM_STATE.STARTED) {
      throw new Error(`fetching team details unnecessarily in state '${state}'`)
    }

    return fetchTeamDetailsPayloadCreator(id)
  }
)

export const teamMemberDeletion = createAsyncThunk(
  `${SLICE_NAME}/teamMemberDeletion`,
  async (_, thunkAPI) => {
    const { elevatedToken } = thunkAPI.getState().otac

    if (!elevatedToken) {
      throw new Error("web_c_general_notauthorized_error_text")
    }

    const { employeeId, teamId } = thunkAPI.getState().teams.teamMemberDeletion

    const response = await deleteTeamMember(employeeId, teamId, {
      headers: {
        Authorization: `Bearer ${elevatedToken}`,
      },
    })
    const { data, status, statusText } = response
    return { data, status, statusText }
  }
)

export const initialState = {
  /**
   * @type {Array}
   */
  teams: undefined,

  /**
   * @type {boolean}
   */
  isTeamsLoading: false,

  teamDetails: {
    /**
     * @type {boolean}
     * @description
     * Whether the team details are being fetched or not.
     */
    isTeamDetailsLoading: false,

    /**
     * @type {Object}
     * @description
     * The team details.
     */
    teamInfo: undefined,

    /**
     * @type {object}
     * @property {string} name
     * @property {string} message
     * @description
     * Team details error in case of failure.
     */
    teamDetailsError: undefined,

    /**
     * @type {boolean}
     * @description
     * Whether the team members are being fetched or not.
     */
    isTeamMembersLoading: false,

    /**
     * @type {Array}
     * @description
     * Team members list.
     */
    teamMembers: undefined,

    /**
     * @type {object}
     * @property {string} name
     * @property {string} message
     * @description
     * Team members error in case of failure.
     */
    teamMembersError: undefined,

    /**
     * defining the current state
     * and pagination of the team members.
     */
    isFirst: false,
    isLast: false,
    pageNumber: undefined,
    pageSize: undefined,
    totalElements: undefined,
    totalPages: undefined,
  },

  /**
   * @type {object}
   * @property {string} name
   * @property {string} message
   */
  teamsError: undefined,

  /**
   * defining the current state
   * and pagination of the page
   */
  isFirst: false,
  isLast: false,
  pageNumber: undefined,
  pageSize: undefined,
  totalElements: undefined,
  totalPages: undefined,

  /**
   * state for tracking add team process
   */
  addTeam: {
    /**
     * @type {ADD_TEAM_STATE}
     */
    state: ADD_TEAM_STATE.IDLE,

    /**
     * name of the team to be added.
     * @type {string}
     */
    name: undefined,

    /**
     * employees attached to this team
     * to be added.
     * @type {string[]}
     */
    employeeIds: undefined,

    /**
     * possible errors might happen during
     * add team process.
     * @type {object}
     * @property {string} message
     */
    error: undefined,
  },

  /**
   * state for tracking edit team process
   */
  editingTeam: {
    /**
     * @type {EDIT_TEAM_STATE}
     */
    state: EDIT_TEAM_STATE.IDLE,

    /**
     * The id of the team
     * @type {string}
     */
    id: undefined,

    /**
     * whether team details is loading or not
     * @type {boolean}
     */
    isTeamDetailsLoading: undefined,

    /**
     *
     * @type {import("../../api/endpoints/teams").GetTeamDetailsResponse}
     */
    teamDetails: undefined,

    /**
     * employee Ids to be send with the request
     * @type {string[]}
     */
    employeeIds: undefined,

    /**
     * possible errors might happen during
     * add team process.
     * @type {object}
     * @property {string} message
     */
    error: undefined,
  },

  /**
   * represents team deletion process
   * @type {object}
   */
  teamDeletion: {
    /**
     * The state of the process
     * @type {TEAM_DELETION_STATE}
     */
    state: TEAM_DELETION_STATE.IDLE,

    /**
     * The current team id to be deleted
     * @type {string}
     */
    id: undefined,

    /**
     * The current team name to be deleted
     * @type {string}
     */
    name: undefined,

    /**
     * The current team id to be deleted
     * @type {object}
     * @property {string} name
     * @property {string} message
     */
    error: undefined,
  },

  teamMemberDeletion: {
    /**
     * @type { TEAM_MEMBER_DELETION_STATE.IDLE | TEAM_MEMBER_DELETION_STATE.STARTED }
     */
    state: TEAM_MEMBER_DELETION_STATE.IDLE,

    /**
     * @type {string}
     */
    employeeId: undefined,

    /**
     * @type {string}
     */
    name: undefined,

    /**
     * @type {import("@reduxjs/toolkit").SerializedError}
     */
    error: undefined,

    /**
     * @type {string}
     */
    teamId: undefined,
  },

  /**
   * contains all teams and their employees
   *
   * @type {import("../../api/endpoints/teams").TeamsAndEmployeesResponse}
   */
  teamsAndEmployees: undefined,
  /**
   * is teams and their employees loading
   *
   * @type {boolean}
   */
  isTeamsAndEmployeesLoading: false,
  /**
   * an error that happen when loading
   * teams and their employees
   *
   * @type {import("@reduxjs/toolkit").SerializedError}
   */
  teamsAndEmployeesError: undefined,
}

export const teamsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    // -------- Add Team -------- \\
    addTeamStarted: (state) => {
      if (state.addTeam.state !== ADD_TEAM_STATE.IDLE) {
        throw new Error(
          `Wrong state transition into '${ADD_TEAM_STATE.STARTED}' from '${state.addTeam.state}'`
        )
      }

      state.addTeam.state = ADD_TEAM_STATE.STARTED
    },
    /**
     * @param {object} action
     * @param {object} action.payload
     * @param {string} action.payload.name
     * @param {string[]} action.payload.employeeIds
     */
    addTeamInformationSet: (state, action) => {
      if (state.addTeam.state !== ADD_TEAM_STATE.STARTED) {
        throw new Error(
          `Wrong state transition into '${ADD_TEAM_STATE.INFORMATION_SET}' from '${state.addTeam.state}'`
        )
      }
      state.addTeam.state = ADD_TEAM_STATE.INFORMATION_SET
      state.addTeam.name = action.payload.name
      state.addTeam.employeeIds = action.payload.employeeIds
    },

    addTeamAuthorizing: (state) => {
      if (
        state.addTeam.state !== ADD_TEAM_STATE.INFORMATION_SET &&
        state.addTeam.state !== ADD_TEAM_STATE.ABORTING
      ) {
        throw new Error(
          `Wrong state transition into '${ADD_TEAM_STATE.AUTHORIZING}' from '${state.addTeam.state}'`
        )
      }
      state.addTeam.state = ADD_TEAM_STATE.AUTHORIZING
    },
    addTeamAborting: (state) => {
      state.addTeam.state = ADD_TEAM_STATE.ABORTING
    },
    addTeamRejected: (state) => {
      state.addTeam.state = ADD_TEAM_STATE.REJECTED
    },
    addTeamFinished: (state) => {
      state.addTeam = initialState.addTeam
    },

    // -------- Edit Team -------- \\
    /**
     * @param {object} action
     * @param {object} action.payload
     * @param {string} action.payload.id
     * @param {string} action.payload.name
     */
    editingTeamStarted: (state, action) => {
      if (state.editingTeam.state !== EDIT_TEAM_STATE.IDLE) {
        throw new Error(
          `Wrong state transition into '${EDIT_TEAM_STATE.STARTED}' from '${state.editingTeam.state}'`
        )
      }

      state.editingTeam.state = EDIT_TEAM_STATE.STARTED
      state.editingTeam.id = action.payload.id
      state.editingTeam.name = action.payload.name
      state.editingTeam.isTeamDetailsLoading = true
    },
    /**
     * @param {object} action
     * @param {object} action.payload
     * @param {string} action.payload.name
     * @param {string[]} action.payload.employeeIds
     */
    editingTeamInformationSet: (state, action) => {
      if (state.editingTeam.state !== EDIT_TEAM_STATE.STARTED) {
        throw new Error(
          `Wrong state transition into '${EDIT_TEAM_STATE.INFORMATION_SET}' from '${state.editingTeam.state}'`
        )
      }
      state.editingTeam.state = EDIT_TEAM_STATE.INFORMATION_SET
      state.editingTeam.name = action.payload.name
      state.editingTeam.employeeIds = action.payload.employeeIds
    },

    editingTeamAuthorizing: (state) => {
      if (
        state.editingTeam.state !== EDIT_TEAM_STATE.INFORMATION_SET &&
        state.editingTeam.state !== EDIT_TEAM_STATE.ABORTING
      ) {
        throw new Error(
          `Wrong state transition into '${EDIT_TEAM_STATE.AUTHORIZING}' from '${state.editingTeam.state}'`
        )
      }
      state.editingTeam.state = EDIT_TEAM_STATE.AUTHORIZING
    },
    editingTeamAborting: (state) => {
      state.editingTeam.state = EDIT_TEAM_STATE.ABORTING
    },
    editingTeamRejected: (state) => {
      state.editingTeam.state = EDIT_TEAM_STATE.REJECTED
    },
    editingTeamFinished: (state) => {
      state.editingTeam = initialState.editingTeam
    },

    // --------- Team Deletion --------- \\
    teamDeletionStarted: (state, action) => {
      state.teamDeletion.state = TEAM_DELETION_STATE.STARTED
      state.teamDeletion.id = action.payload.id
      state.teamDeletion.name = action.payload.name
    },
    teamDeletionAuthorizing: (state) => {
      state.teamDeletion.state = TEAM_DELETION_STATE.AUTHORIZING
    },
    teamDeletionAborting: (state) => {
      state.teamDeletion.state = TEAM_DELETION_STATE.ABORTING
    },
    teamDeletionRejected: (state) => {
      state.teamDeletion.state = TEAM_DELETION_STATE.REJECTED
    },
    teamDeletionFinished: (state) => {
      state.teamDeletion = initialState.teamDeletion
    },

    // ---------- Team Member Deletion ---------- \\
    teamMemberDeletionStarted: (state, action) => {
      state.teamMemberDeletion.state = TEAM_MEMBER_DELETION_STATE.STARTED
      state.teamMemberDeletion.employeeId = action.payload.employeeId
      state.teamMemberDeletion.name = action.payload.name
      state.teamMemberDeletion.teamId = action.payload.teamId
    },
    teamMemberDeletionAuthorizing: (state) => {
      state.teamMemberDeletion.state = TEAM_MEMBER_DELETION_STATE.AUTHORIZING
    },
    teamMemberDeletionAuthorized: (state) => {
      state.teamMemberDeletion.state = TEAM_MEMBER_DELETION_STATE.AUTHORIZED
    },
    teamMemberDeletionAborting: (state) => {
      state.teamMemberDeletion.state = TEAM_MEMBER_DELETION_STATE.ABORTING
    },
    teamMemberDeletionRejected: (state) => {
      state.teamMemberDeletion.state = TEAM_MEMBER_DELETION_STATE.REJECTED
    },
    teamMemberDeletionFinished: (state) => {
      state.teamMemberDeletion = initialState.teamMemberDeletion
    },

    // ---------- Team Details ---------- \\
    resetTeamDetails: (state) => {
      state.teamDetails = initialState.teamDetails
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTeams.pending, (state) => {
      state.isTeamsLoading = true
    })
    builder.addCase(fetchTeams.fulfilled, (state, action) => {
      state.isTeamsLoading = false
      state.teams = action.payload.data.content
      state.isLast = action.payload.data.last
      state.isFirst = action.payload.data.first
      state.pageSize = action.payload.data.pageSize
      state.totalPages = action.payload.data.totalPages
      state.pageNumber = action.payload.data.pageNumber
      state.totalElements = action.payload.data.totalElements
    })
    builder.addCase(fetchTeams.rejected, (state, action) => {
      state.isTeamsLoading = false
      state.teamsError = {
        name: action.error.name,
        message: action.error.message,
      }
    })

    // -------- Teams and Employees -------- \\
    builder.addCase(fetchTeamsAndEmployees.pending, (state) => {
      state.isTeamsAndEmployeesLoading = true
    })

    builder.addCase(fetchTeamsAndEmployees.fulfilled, (state, action) => {
      state.isTeamsAndEmployeesLoading = false
      state.teamsAndEmployeesError = undefined
      state.teamsAndEmployees = action.payload.data.teams
    })

    builder.addCase(fetchTeamsAndEmployees.rejected, (state, action) => {
      state.isTeamsAndEmployeesLoading = false
      state.teamsAndEmployeesError = {
        name: action.error.name,
        message: action.error.message,
        code: action.error.code,
      }
    })

    // -------- Add Team -------- \\
    builder.addCase(teamAddition.fulfilled, (state) => {
      state.addTeam.state = ADD_TEAM_STATE.ADDED
    })

    builder.addCase(teamAddition.rejected, (state, action) => {
      state.addTeam.state = ADD_TEAM_STATE.ERRORED

      const error = { message: "web_c_general_error_massage" }

      if (Array.isArray(action?.payload?.errors)) {
        const { detail } = action.payload.errors.pop()
        error.message = detail
      } else {
        error.message = action.error.message
      }

      state.addTeam.error = error
    })

    // -------- Edit Team -------- \\
    builder.addCase(fetchEditingTeamDetails.fulfilled, (state, action) => {
      state.editingTeam.isTeamDetailsLoading = false
      state.editingTeam.teamDetails = action.payload.data
    })
    builder.addCase(fetchEditingTeamDetails.rejected, (state) => {
      state.editingTeam.isTeamDetailsLoading = false
    })

    builder.addCase(fetchEditingTeamMembers.fulfilled, (state, action) => {
      state.editingTeam.teamMembers = action.payload.data.content
    })

    builder.addCase(editingTeam.fulfilled, (state) => {
      state.editingTeam.state = EDIT_TEAM_STATE.EDITED
    })

    builder.addCase(editingTeam.rejected, (state, action) => {
      state.editingTeam.state = EDIT_TEAM_STATE.ERRORED

      const error = { message: "web_c_general_error_massage" }

      if (Array.isArray(action?.payload?.errors)) {
        const { detail } = action.payload.errors.pop()
        error.message = detail
      } else {
        error.message = action.error.message
      }

      state.editingTeam.error = error
    })

    // --------- Team Deletion --------- \\
    builder.addCase(teamDeletion.fulfilled, (state) => {
      state.teamDeletion.state = TEAM_DELETION_STATE.DELETED
    })
    builder.addCase(teamDeletion.rejected, (state, action) => {
      state.teamDeletion.state = TEAM_DELETION_STATE.ERRORED
      state.teamDeletion.error = action.meta.rejectedWithValue
        ? action.payload.errors
        : {
            name: action.error.name,
            message: action.error.message,
          }
    })

    // --------- Team Details --------- \\
    builder.addCase(fetchTeamDetails.pending, (state) => {
      state.teamDetails.isTeamDetailsLoading = true
    })
    builder.addCase(fetchTeamDetails.fulfilled, (state, action) => {
      state.teamDetails.isTeamDetailsLoading = false
      state.teamDetails.teamDetailsError = undefined
      state.teamDetails.teamInfo = action.payload.data
    })
    builder.addCase(fetchTeamDetails.rejected, (state, action) => {
      state.teamDetails.isTeamDetailsLoading = false

      let error = {
        code: GENERAL_ERROR,
        message: "web_c_general_error_massage",
      }

      if (action.payload?.data) {
        const { data, status } = action.payload
        error = { ...data.errors[0], status }
      }

      state.teamDetails.teamDetailsError = error
    })

    // --------- Team Members --------- \\
    builder.addCase(fetchTeamMembers.pending, (state) => {
      state.teamDetails.isTeamMembersLoading = true
    })
    builder.addCase(fetchTeamMembers.fulfilled, (state, action) => {
      state.teamDetails.isTeamMembersLoading = false
      state.teamDetails.teamMembersError = undefined
      state.teamDetails.teamMembers = action.payload.data.content
      state.teamDetails.isFirst = action.payload.data.first
      state.teamDetails.isLast = action.payload.data.last
      state.teamDetails.pageNumber = action.payload.data.pageNumber
      state.teamDetails.pageSize = action.payload.data.pageSize
      state.teamDetails.totalElements = action.payload.data.totalElements
      state.teamDetails.totalPages = action.payload.data.totalPages
    })
    builder.addCase(fetchTeamMembers.rejected, (state, action) => {
      state.teamDetails.isTeamMembersLoading = false
      state.teamDetails.teamMembersError = action.error
    })

    // --------- Team Member Deletion --------- \\
    builder.addCase(teamMemberDeletion.pending, (state) => {
      state.teamMemberDeletion.state = TEAM_MEMBER_DELETION_STATE.DELETING
    })
    builder.addCase(teamMemberDeletion.fulfilled, (state) => {
      state.teamMemberDeletion.state = TEAM_MEMBER_DELETION_STATE.DELETED
    })
    builder.addCase(teamMemberDeletion.rejected, (state, action) => {
      state.teamMemberDeletion.state = TEAM_MEMBER_DELETION_STATE.ERRORED
      state.teamMemberDeletion.error = {
        name: action.error.name,
        message: action.error.message,
      }
    })
  },
})

export const {
  // -------- Add Team -------- \\
  addTeamStarted,
  addTeamInformationSet,
  addTeamAuthorizing,
  addTeamAborting,
  addTeamRejected,
  addTeamFinished,

  // -------- Edit Team -------- \\
  editingTeamStarted,
  editingTeamInformationSet,
  editingTeamAuthorizing,
  editingTeamAborting,
  editingTeamRejected,
  editingTeamFinished,

  // --------- Team Deletion --------- \\
  teamDeletionStarted,
  teamDeletionAuthorizing,
  teamDeletionAborting,
  teamDeletionRejected,
  teamDeletionFinished,

  // --------- Team Member Deletion --------- \\
  teamMemberDeletionStarted,
  teamMemberDeletionAuthorizing,
  teamMemberDeletionAuthorized,
  teamMemberDeletionAborting,
  teamMemberDeletionRejected,
  teamMemberDeletionFinished,

  // --------- Team Details --------- \\
  resetTeamDetails,
} = teamsSlice.actions

export default teamsSlice.reducer
