import { useTranslation } from "react-i18next"

const useDir = () => {
  const { i18n } = useTranslation()

  // FIXME temp fix for Kurdish Badini. Should be removed when Kurdish Badini is supported by i18n
  // supported languages: https://github.com/i18next/i18next/blob/448e2be33042ed959d762eaff270fa804e2baaf4/src/i18next.js#L503
  const dir = i18n.language === "ba" ? "rtl" : i18n.dir(i18n.language)
  const isRTL = i18n.language === "ba" || dir === "rtl"

  return { isRTL, dir }
}

export default useDir
