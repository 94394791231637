import * as yup from "yup"

/**
 * @readonly
 * @enum {string}
 */
export const SHIFT_MONEY_STATE = {
  IDLE: "IDLE",
  STARTED: "STARTED",
  INFORMATION_SET: "INFORMATION_SET",
  CONFIRMATION: "CONFIRMATION",
  AUTHORIZING: "AUTHORIZING",
  ABORTING: "ABORTING",
  ERRORED: "ERRORED",
  REJECTED: "REJECTED",
  AUTHORIZED: "AUTHORIZED",
  SHIFTED: "SHIFTED",
}

export const shiftMoneyAmountSchema = (min, max) =>
  yup.number().min(min).max(max).required()

export const getStepNumberFromShiftMoneyStates = (shiftMoneyState) => {
  switch (shiftMoneyState) {
    case SHIFT_MONEY_STATE.STARTED:
    case SHIFT_MONEY_STATE.INFORMATION_SET:
      return 0
    case SHIFT_MONEY_STATE.CONFIRMATION:
    case SHIFT_MONEY_STATE.AUTHORIZING:
    case SHIFT_MONEY_STATE.ABORTING:
    case SHIFT_MONEY_STATE.REJECTED:
      return 1
    default:
      return -1
  }
}

export const ACCOUNT_TO_BE_UPDATED = {
  SOURCE: "SOURCE",
  TARGET: "TARGET",
  BOTH: "BOTH",
}
