import classNames from "classnames"
import PropTypes from "prop-types"

const NoData = ({ icon, title, text, noDelay }) => (
  <div
    className={classNames("no-data", {
      "no-data--no-delay": noDelay,
    })}
    data-testid="no-data"
  >
    {icon}
    <h2 className="no-data__title">{title}</h2>
    <p className="no-data__text">{text}</p>
  </div>
)

NoData.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  noDelay: PropTypes.bool,
}

NoData.defaultProps = {
  noDelay: false,
}

export default NoData
