import PropTypes from "prop-types"
import { thousandFormatter } from "../../lib/general"
import { mapCurrencyTypeToCurrencySymbol } from "../../lib/analytics/dashboard/bankAccounts"
import {
  CURRENCY_TYPE_EUR,
  CURRENCY_TYPE_IQD,
  CURRENCY_TYPE_USD,
} from "../../shared/constants/bank-accounts"

const SectionExpanded = ({
  fee,
  isRTL,
  feeLabel,
  monetaryValue,
  totalAmountLabel,
  baseMonetaryValue,
  transactionTypeLabel,
}) => (
  <div
    data-testid="details-sidebar-body-expanded-transfer"
    className="details-sidebar-body__expanded-transfer"
  >
    <div className="details-sidebar-body__expanded-transfer-row">
      <p className="details-sidebar-body__expanded-transfer-row-label">
        {transactionTypeLabel}
      </p>
      <p>
        {thousandFormatter(baseMonetaryValue.amount, isRTL)}{" "}
        {mapCurrencyTypeToCurrencySymbol(baseMonetaryValue.currency, isRTL)}
      </p>
    </div>
    <div className="details-sidebar-body__expanded-transfer-row">
      <p className="details-sidebar-body__expanded-transfer-row-label">
        {feeLabel}
      </p>
      <p data-testid="details-sidebar-body-expanded-transfer-fee">
        {thousandFormatter(fee?.amount, isRTL)}{" "}
        {mapCurrencyTypeToCurrencySymbol(
          fee?.currency || monetaryValue.currency,
          isRTL
        )}
      </p>
    </div>
    <div className="details-sidebar-body__expanded-transfer-separator" />
    <div className="details-sidebar-body__expanded-transfer-total-amount-row">
      <p>{totalAmountLabel}</p>
      <p>
        {thousandFormatter(monetaryValue.amount, isRTL)}{" "}
        {mapCurrencyTypeToCurrencySymbol(monetaryValue.currency, isRTL)}
      </p>
    </div>
  </div>
)

const monetaryValueProps = {
  amount: PropTypes.number.isRequired,
  currency: PropTypes.oneOf([
    CURRENCY_TYPE_IQD,
    CURRENCY_TYPE_USD,
    CURRENCY_TYPE_EUR,
  ]).isRequired,
}

SectionExpanded.propTypes = {
  isRTL: PropTypes.bool,
  feeLabel: PropTypes.string.isRequired,
  fee: PropTypes.shape(monetaryValueProps),
  totalAmountLabel: PropTypes.string.isRequired,
  transactionTypeLabel: PropTypes.string.isRequired,
  monetaryValue: PropTypes.shape(monetaryValueProps).isRequired,
  baseMonetaryValue: PropTypes.shape(monetaryValueProps).isRequired,
}

SectionExpanded.defaultProps = {
  isRTL: false,
  fee: undefined,
}

export default SectionExpanded
