export const ENGLISH_LOCALE = {
  id: 1,
  name: "English",
  lang: "en",
  dir: "ltr",
}
export const ARABIC_LOCALE = {
  id: 2,
  name: "Arabic",
  lang: "ar",
  dir: "rtl",
}
export const KURDISH_CENTRAL_LOCALE = {
  id: 3,
  name: "Kurdish SO",
  lang: "ckb",
  dir: "rtl",
}
export const KURDISH_KURMANJI_LOCALE = {
  id: 4,
  name: "Kurdish BA",
  lang: "ba",
  dir: "rtl",
}

export const LANGUAGES = {
  [ENGLISH_LOCALE.lang]: ENGLISH_LOCALE,
  [ARABIC_LOCALE.lang]: ARABIC_LOCALE,
  [KURDISH_CENTRAL_LOCALE.lang]: KURDISH_CENTRAL_LOCALE,
  [KURDISH_KURMANJI_LOCALE.lang]: KURDISH_KURMANJI_LOCALE,
}
