/**
 * @typedef ProcessApprovalState
 * @property {string} IDLE
 * @property {string} STARTED
 * @property {string} CONFIRMATION
 * @property {string} AUTHORIZING
 * @property {string} ABORTING
 * @property {string} REJECTED
 * @property {string} DELETED
 * @property {string} ERRORED
 */

/**
 * @type {ProcessApprovalState}
 * @enum {string}
 */
export const PROCESS_APPROVAL_STATE = {
  IDLE: "IDLE",
  STARTED: "STARTED",
  CONFIRMATION: "CONFIRMATION",
  AUTHORIZING: "AUTHORIZING",
  ABORTING: "ABORTING",
  REJECTED: "REJECTED",
  APPROVING: "APPROVING",
  APPROVED: "APPROVED",
  ERRORED: "ERRORED",
}
