import { createSelector } from "@reduxjs/toolkit"
import { getTeamMembersCSV } from "../../api/endpoints/teams"
import { fetchTeams } from "../../features/teams/teams-slice"
import { DEFAULT_INFINITE_PARAMS } from "../../shared/constants/general"
import { downloadFile } from "../downloadFile"

export const downloadTeamMembersCSV = async (teamId) => {
  const response = await getTeamMembersCSV(teamId)
  downloadFile(response.data, "team-members-list")
}

export const refreshTeamsBuilder = (dispatch) => () =>
  dispatch(fetchTeams(DEFAULT_INFINITE_PARAMS))

export const refreshTeamsAndPushToBuilder =
  (dispatch, navigate, route) => () => {
    dispatch(fetchTeams({ ...DEFAULT_INFINITE_PARAMS }))
    navigate(route)
  }

/**
 *
 * @param {object} params
 * @param {object} params.event
 * @param {*} params.event.target
 * @param {object} params.employeesPopoverElement
 * @param {()=>boolean} params.employeesPopoverElement.contains
 * @param {*} params.searchEmployeeElement
 * @param {()=>{}} params.callback
 */
export const handleMouseDown = ({
  event,
  employeesPopoverElement,
  searchEmployeeElement,
  callback,
}) => {
  if (
    !employeesPopoverElement ||
    (event.target !== searchEmployeeElement &&
      !employeesPopoverElement.contains(event.target))
  ) {
    callback()
  }
}

export const filteredTeamlessEmployeesSelector = createSelector(
  (state) => state.employees.teamlessEmployees,
  (_, excludedEmployees) => excludedEmployees,
  (_, __, includedEmployees) => includedEmployees,
  (_, __, ___, searchQuery) => searchQuery,
  (
    teamlessEmployees,
    excludedEmployees,
    includedEmployees,
    searchQuery = ""
  ) => {
    if (!Array.isArray(teamlessEmployees)) return []

    return teamlessEmployees
      .concat(...includedEmployees)
      .filter(
        ({ id, name }) =>
          !excludedEmployees.some(
            (excludedEmployee) => excludedEmployee.id === id
          ) && name.toLowerCase().includes(searchQuery.toLowerCase())
      )
      .map(({ id, name }) => ({ id, name }))
  }
)
