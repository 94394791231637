export const BANK_TRANSFER_BREADCRUMB_DATA = {
  title: "web_c_transfer_page_title",
  breadcrumbsList: [{ text: "web_c_transfer_page_title" }],
}

export const PURPOSE_TYPE_OTHER = "OTHER"

export const MAX_FILE_NUMBER = 5
export const MAX_FILE_SIZE = 2.5e7
export const ACCEPTED_FILES = {
  "image/png": [".png"],
  "": [".jpg", ".jpeg"],
  "application/pdf": [".pdf"],
}

const ACCEPTED_FILES_COMBINED = Object.keys(ACCEPTED_FILES)
  .filter((type) => type.length > 0)
  .concat(...Object.values(ACCEPTED_FILES))

/**
 * Builds a function that checks files if they're of certain
 * types or not, sets an error with passed `message`.
 *
 * @param {string} message
 */
export const testFileTypesBuilder =
  (
    message
    /**
     *
     * @param {FileList} files
     */
  ) =>
  (files, ctx) =>
    !files ||
    Array.from(files).every(
      (file) =>
        ACCEPTED_FILES_COMBINED.includes(file.type) ||
        ACCEPTED_FILES_COMBINED.includes(`.${file.name.split(".").pop()}`)
    ) ||
    ctx.createError({
      type: "invalid file type",
      message,
    })
