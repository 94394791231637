import dayjs from "dayjs"
import dayjsKu from "dayjs/locale/ku"
import dayjsArIq from "dayjs/locale/ar-iq"
import preParsePostFormat from "dayjs/plugin/preParsePostFormat"

import {
  ARABIC_LOCALE,
  ENGLISH_LOCALE,
  KURDISH_CENTRAL_LOCALE,
  KURDISH_KURMANJI_LOCALE,
  LANGUAGES,
} from "../shared/constants/languages"

export const saveLocale = (locale) => {
  localStorage.setItem("locale", JSON.stringify(locale))
}

export const getCurrentLocale = () => localStorage.getItem("locale")

export const getLocaleByLanguageCode = (langCode) => LANGUAGES[langCode]

export const dayjsLanguageChanger = (lang) => {
  if (lang !== ENGLISH_LOCALE.lang) {
    dayjs.extend(preParsePostFormat)
  }

  switch (lang) {
    case ARABIC_LOCALE.lang:
      dayjs.locale(dayjsArIq)
      break
    case KURDISH_CENTRAL_LOCALE.lang:
    case KURDISH_KURMANJI_LOCALE.lang:
      dayjs.locale(dayjsKu)
      break
    case ENGLISH_LOCALE.lang:
    default:
      dayjs.locale("en")
  }
}

export const languageChangeHandler = (langCode) => {
  const newLocale = getLocaleByLanguageCode(langCode)
  saveLocale(newLocale)
  dayjsLanguageChanger(newLocale.lang)
  document.dir = newLocale.dir
}
