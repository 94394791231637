import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

const Row = ({ onClickHandler, children }) => (
  <tr
    className={classNames({ clickable: !!onClickHandler })}
    data-testid="table-row"
    onClick={onClickHandler}
  >
    {children}
  </tr>
)

Row.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  onClickHandler: PropTypes.func,
}

Row.defaultProps = {
  children: undefined,
  onClickHandler: undefined,
}

export default Row
