import * as yup from "yup"
import { ALPHABETICAL_NAME_VALIDATOR_REGEX } from "../general"

export const EDIT_TEAM_STATE = {
  IDLE: "IDLE",
  STARTED: "STARTED",
  INFORMATION_SET: "INFORMATION_SET",
  AUTHORIZING: "AUTHORIZING",
  ABORTING: "ABORTING",
  REJECTED: "REJECTED",
  AUTHORIZED: "AUTHORIZED",
  ERRORED: "ERRORED",
  EDITED: "EDITED",
}

export const getStepNumberFromEditIngTeamState = (state) => {
  switch (state) {
    case EDIT_TEAM_STATE.STARTED:
    case EDIT_TEAM_STATE.INFORMATION_SET:
      return 0
    case EDIT_TEAM_STATE.AUTHORIZING:
    case EDIT_TEAM_STATE.ABORTING:
    case EDIT_TEAM_STATE.REJECTED:
    case EDIT_TEAM_STATE.AUTHORIZED:
    case EDIT_TEAM_STATE.EDITED:
      return 1
    case EDIT_TEAM_STATE.IDLE:
    default:
      return -1
  }
}

export const EDIT_TEAM_INFORMATION_FORM_ID = "EDIT_TEAM_INFORMATION_FORM_ID"

export const editTeamInformationSchema = yup.object({
  name: yup
    .string()
    .required("web_c_teams_teammodal_namevalidation1_label")
    .max(30, "web_c_teams_teammodal_namevalidation2_label")
    .matches(ALPHABETICAL_NAME_VALIDATOR_REGEX, {
      message: "web_c_teams_teammodal_namevalidation3_label",
    }),
})
