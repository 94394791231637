import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import {
  dayjsLanguageChanger,
  getCurrentLocale,
  languageChangeHandler,
} from "../lib/i18n"
import english from "../locale/en.json"
import arabic from "../locale/ar_IQ.json"
import centralKurdish from "../locale/ku_CKB.json"
import badiniKurdish from "../locale/ku_BA.json"

export const DEFAULT_LOCALE = "en"

const previouslySavedLocale = JSON.parse(getCurrentLocale())

if (previouslySavedLocale?.lang) {
  dayjsLanguageChanger(previouslySavedLocale.lang)
}

i18n.use(initReactI18next).init({
  resources: {
    [DEFAULT_LOCALE]: { translation: english },
    ar: { translation: arabic },
    ckb: { translation: centralKurdish },
    ba: { translation: badiniKurdish },
  },
  lng: previouslySavedLocale?.lang || DEFAULT_LOCALE,
  returnEmptyString: false,
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
})

i18n.on("languageChanged", languageChangeHandler)

export default i18n
