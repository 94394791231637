import React from "react"
import dayjs from "dayjs"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { formatNumbers, thousandFormatter } from "../../../lib/general"
import useDir from "../../../shared/hooks/useDir"
import { MonetaryValuePropTypes } from "../../../shared/constants/bank-accounts"
import DetailsSidebar from "../../detailsSidebar/DetailsSidebar"

const EmployeeDetails = ({ employee }) => {
  const { t } = useTranslation()
  const { isRTL } = useDir()

  return (
    <div>
      <DetailsSidebar.UserInfo
        name={employee?.name}
        iconURL={employee?.iconUrl}
        initials={employee?.initials}
        iban={employee?.mainAccountIban}
      />
      <DetailsSidebar.Section
        header={t("web_c_employees_employeedetail_label")}
      >
        <DetailsSidebar.SectionRow
          name={t("web_c_employees_employeedetail_name_label")}
          value={employee.name}
        />
        <DetailsSidebar.SectionRow
          name={t("web_c_employees_employeedetail_dateofbirth_label")}
          value={dayjs(employee?.dateOfBirth).format("DD/MM/YYYY")}
        />
        <DetailsSidebar.SectionRow
          className="employee-details__phone-number"
          name={t("web_c_employees_employeedetail_phonenumber_label")}
          value={
            employee?.phoneNumber
              ? formatNumbers(employee.phoneNumber, isRTL)
              : "-"
          }
          valueTestId="summary-item-phone-number"
        />
        <DetailsSidebar.SectionRow
          name={t("web_c_employees_employeedetail_iban_label")}
          value={employee?.mainAccountIban}
        />
        <DetailsSidebar.SectionRow
          name={t("web_c_employees_employeedetail_position_label")}
          value={employee?.position ? employee.position : "-"}
          valueTestId="summary-item-position"
        />
        <DetailsSidebar.SectionRow
          name={t("web_c_employees_employeedetail_salary_label")}
          value={thousandFormatter(employee?.salary?.amount, isRTL)}
        />
        <DetailsSidebar.SectionRow
          name={t("web_c_employees_employeedetail_team_label")}
          value={employee?.team?.name ? employee.team.name : "-"}
          valueTestId="summary-item-team-name"
        />
      </DetailsSidebar.Section>
    </div>
  )
}

EmployeeDetails.propTypes = {
  employee: PropTypes.shape({
    iconUrl: PropTypes.string,
    initials: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
    userId: PropTypes.string,
    phoneNumber: PropTypes.string,
    position: PropTypes.string,
    salary: MonetaryValuePropTypes.isRequired,
    mainAccountIban: PropTypes.string,
    mainAccountId: PropTypes.string,
    dateOfBirth: PropTypes.string,
    team: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  }).isRequired,
}

export default EmployeeDetails
