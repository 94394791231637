import * as yup from "yup"
import { ALPHANUMERIC_REGEX } from "../general"

/**
 * @readonly
 * @enum {string}
 *
 *                                  ┌───► DONE ──────────┐
 *                                  │                    │
 * IDLE ───► STARTED ───► NAME_SET ─┤                    │
 *  ▲          │             │      │                    │
 *  │          │             │      └───► ERRORED ──┐    │
 *  │          │             │                      │    │
 *  │          │             │                      │    │
 *  └─ ◄───────┘ ◄───────────┘ ◄────────────────────┘ ◄──┘
 */

export const EDITING_TERMINAL_NAME_STATE = {
  IDLE: "IDLE",
  STARTED: "STARTED",
  NAME_SET: "NAME_SET",
  ERRORED: "ERRORED",
  DONE: "DONE",
}

export const editTerminalNameSchema = yup.object({
  name: yup
    .string()
    .matches(
      ALPHANUMERIC_REGEX,
      "web_c_terminals_editterminalnamemodal_namevalidation1_label"
    )
    .min(1, "web_c_terminals_editterminalnamemodal_namevalidation2_label")
    .max(30, "web_c_terminals_editterminalnamemodal_namevalidation3_label")
    .required("web_c_terminals_editterminalnamemodal_namevalidation4_label"),
  displayName: yup
    .string()
    .test(
      "display name pattern",
      "web_c_terminals_editterminalnamemodal_displayname_validation1_label",
      (displayName) => {
        if (displayName && displayName?.length > 0) {
          return ALPHANUMERIC_REGEX.test(displayName)
        }

        return true
      }
    )
    .test(
      "maximum length",
      "web_c_terminals_editterminalnamemodal_displayname_validation3_label",
      (displayName) => {
        if (displayName && displayName?.length > 0) {
          return displayName?.length < 31
        }

        return true
      }
    ),
})
