import PropTypes from "prop-types"

const SectionTotal = ({ name, value }) => (
  <section className="details-sidebar-body__total-section">
    <div>{name}</div>
    <div>{value}</div>
  </section>
)

SectionTotal.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
}

export default SectionTotal
