import * as yup from "yup"
import { isFileNameValid } from "../../../lib/general"
import { ALPHABETICAL_NAME_VALIDATOR_REGEX, BIC_CODE_REGEX } from "../general"
import {
  MAX_FILE_NUMBER,
  MAX_FILE_SIZE,
  PURPOSE_TYPE_OTHER,
  testFileTypesBuilder,
} from "./main"

/**
 * @typedef BankTransferState
 * @property {string} IDLE
 * @property {string} STARTED
 * @property {string} INFORMATION_SET
 * @property {string} CONFIRMATION
 * @property {string} AUTHORIZING
 * @property {string} ABORTING
 * @property {string} REJECTED
 * @property {string} TRANSFERED
 * @property {string} ERRORED
 *
 * @readonly
 * @enum {BankTransferState}
 */
export const BANK_TRANSFER = {
  IDLE: "IDLE",
  STARTED: "STARTED",
  INFORMATION_SET: "INFORMATION_SET",
  CONFIRMATION: "CONFIRMATION",
  AUTHORIZING: "AUTHORIZING",
  ABORTING: "ABORTING",
  REJECTED: "REJECTED",
  TRANSFERED: "TRANSFERED",
  ERRORED: "ERRORED",
}

export const getStepNumberFromBankTransferState = (bankTransfer) => {
  switch (bankTransfer) {
    case BANK_TRANSFER.STARTED:
    case BANK_TRANSFER.INFORMATION_SET:
      return 0
    case BANK_TRANSFER.CONFIRMATION:
    case BANK_TRANSFER.AUTHORIZING:
    case BANK_TRANSFER.ABORTING:
    case BANK_TRANSFER.REJECTED:
    case BANK_TRANSFER.TRANSFERED:
      return 1
    case BANK_TRANSFER.IDLE:
    case BANK_TRANSFER.ERRORED:
    default:
      return -1
  }
}

export const IBAN_VALIDATION_MESSAGE_REQUIRED =
  "web_c_transfer_banktransfermodal_iban_validation1"

export const bankTransferInformationStepSchema = yup.object({
  name: yup
    .string()
    .required("web_c_transfer_banktransfermodal_receivername_validation1")
    .max(30, "web_c_transfer_banktransfermodal_receivername_validation3")
    .matches(
      ALPHABETICAL_NAME_VALIDATOR_REGEX,
      "web_c_transfer_banktransfermodal_receivername_validation2"
    ),
  iban: yup.string().uppercase().required(IBAN_VALIDATION_MESSAGE_REQUIRED),
  bicCode: yup
    .string()
    .optional()
    .matches(
      BIC_CODE_REGEX,
      "web_c_transfer_banktransfermodal_swift/biccode_validcode_validation_text"
    ),
  amount: yup
    .number()
    .when("$amount", {
      is: (value) => value === undefined,
      then: yup
        .number()
        .typeError("web_c_transfer_banktransfermodal_amount_validation2"),
    })
    .when(["$minAmount"], (min, schema) =>
      schema.min(min, "web_c_transfer_banktransfermodal_amount_validation3")
    )
    .when(["$balance"], (max, schema) =>
      schema.max(max, "web_c_transfer_banktransfermodal_amount_validation4")
    ),
  attachment: yup
    .mixed()
    .test(
      "file numbers",
      (files, ctx) =>
        files?.length <= MAX_FILE_NUMBER ||
        ctx.createError({
          type: "too many files",
          message: "web_c_transfer_banktransfermodal_attachments_validation2",
        })
    )
    .test(
      "file types",
      testFileTypesBuilder(
        "web_c_transfer_banktransfermodal_attachments_validation4"
      )
    )
    .test(
      "file size",
      (files, ctx) =>
        !files ||
        Array.from(files).every((file) => file.size <= MAX_FILE_SIZE) ||
        ctx.createError({
          type: "file is too big",
          message: "web_c_transfer_banktransfermodal_attachments_validation3",
        })
    )
    // FIXME: This vaidation will look for file names that contain irregular file names!
    // so we might need to remove this incase it was fixed on BE side.
    .test(
      "file name",
      (files, ctx) =>
        Array.from(files).every((file) => isFileNameValid(file.name)) ||
        ctx.createError({
          type: "file name is not allowed",
          message: "web_c_transfer_banktransfermodal_attachments_validation5",
        })
    ),
  purpose: yup
    .object()
    .nullable()
    .required("web_c_transfer_banktransfermodal_pupose_validation1"),
  brief: yup
    .string()
    .max(300, "web_c_transfer_banktransfermodal_brief_validation3")
    .when("purpose", {
      is: (purpose) => purpose?.id === PURPOSE_TYPE_OTHER,
      then: (schema) =>
        schema
          .required("web_c_transfer_banktransfermodal_brief_validation1")
          .min(5, "web_c_transfer_banktransfermodal_brief_validation2"),
      otherwise: (schema) => schema.ensure(),
    })
    .when({
      is: (brief) => brief?.length > 0,
      then: (schema) =>
        schema.min(5, "web_c_transfer_banktransfermodal_brief_validation2"),
      otherwise: (schema) => schema.ensure(),
    }),
})

// FIXME this mapping should be removed when Backend returns
// the error messages instead of setting it ourselves
/**
 * @constant
 * @readonly
 */
export const SENT_DAILY_TRANSACTION_LIMITS_EXCEEDED =
  "SENT_DAILY_TRANSACTION_LIMITS_EXCEEDED"
export const SENT_MONTHLY_TRANSACTION_LIMITS_EXCEEDED =
  "SENT_MONTHLY_TRANSACTION_LIMITS_EXCEEDED"
export const RECEIVED_DAILY_TRANSACTION_LIMITS_EXCEEDED =
  "RECEIVED_DAILY_TRANSACTION_LIMITS_EXCEEDED"
export const RECEIVED_MONTHLY_TRANSACTION_LIMITS_EXCEEDED =
  "RECEIVED_MONTHLY_TRANSACTION_LIMITS_EXCEEDED"

export const MAP_BANK_TRANSFER_ERROR_CODE_TO_MESSAGE = {
  [SENT_DAILY_TRANSACTION_LIMITS_EXCEEDED]:
    "web_c_transfer_dailytransferlimitexceed_error_text",
  [SENT_MONTHLY_TRANSACTION_LIMITS_EXCEEDED]:
    "web_c_transfer_monthlytransferlimitexceed_error_text",
  [RECEIVED_DAILY_TRANSACTION_LIMITS_EXCEEDED]:
    "web_c_transfer_dailydepositlimitexceed_error_text",
  [RECEIVED_MONTHLY_TRANSACTION_LIMITS_EXCEEDED]:
    "web_c_transfer_monthlydepositlimitexceed_error_text",
}
