import * as yup from "yup"
import FIBQRCodeLogoIcon from "../../../assets/images/qrcode/FIBLogo.svg"
import { isFileNameValid } from "../../../lib/general"
import {
  MAX_FILE_NUMBER,
  MAX_FILE_SIZE,
  PURPOSE_TYPE_OTHER,
  testFileTypesBuilder,
} from "./main"

const EXPORTED_QR_CODE_IMAGE_CONFIG = {
  source: FIBQRCodeLogoIcon,
  width: 6,
  height: 6,
  x: "center",
  y: "center",
}

export const getTargetIfEnvIsNotTest = (env, target) =>
  env === "test" ? undefined : target

export const EXPORTED_QR_CODE_CONFIG = {
  level: "H",
  padding: 20,
  scale: 12,
  fgColor: "#24292e",
  bgColor: "#ffffff",
  image: getTargetIfEnvIsNotTest(
    process.env.NODE_ENV,
    EXPORTED_QR_CODE_IMAGE_CONFIG
  ),
}

/**
 * @readonly
 * @enum {string}
 */
export const RECEIVE_PAYMENT = {
  IDLE: "IDLE",
  STARTED: "STARTED",
  UPLOADING_ATTACHMENTS: "UPLOADING_ATTACHMENTS",
  UPLOADED_ATTACHMENTS: "UPLOADED_ATTACHMENTS",
  INFORMATION_SET: "INFORMATION_SET",
  ABORTING: "ABORTING",
  CREATING: "CREATING",
  CREATED: "CREATED",
  ERRORED: "ERRORED",
}

export const getStepNumberFromReceivePaymentStates = (state) => {
  switch (state) {
    case RECEIVE_PAYMENT.STARTED:
    case RECEIVE_PAYMENT.UPLOADING_ATTACHMENTS:
    case RECEIVE_PAYMENT.UPLOADED_ATTACHMENTS:
    case RECEIVE_PAYMENT.INFORMATION_SET:
    case RECEIVE_PAYMENT.ABORTING:
    case RECEIVE_PAYMENT.CREATING:
      return 0
    case RECEIVE_PAYMENT.CREATED:
      return 1

    case RECEIVE_PAYMENT.IDLE:
    case RECEIVE_PAYMENT.ERRORED:
    default:
      return -1
  }
}

export const receivePaymentSchema = yup.object({
  amount: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .nullable(true)
    .required("web_c_transfer_receivepaymentmodal_amount_validation2")
    .when(["$minAmount"], (min, schema) =>
      schema.min(min, "web_c_transfer_recievepaymentmodal_amount_validation1")
    ),
  qrcodeExpiration: yup
    .object({ id: yup.string().required() })
    .strict(true)
    .required("web_c_transfer_receivepaymentmodal_qrcodeexpiration_validation"),
  purpose: yup
    .object({ id: yup.string().required() })
    .strict(true)
    .required("web_c_transfer_receivepaymentmodal_pupose_validation"),
  attachments: yup
    .mixed()
    .test(
      "file numbers",
      (files, ctx) =>
        files?.length <= MAX_FILE_NUMBER ||
        ctx.createError({
          type: "too many files",
          message: "web_c_transfer_receivepaymentmodal_attachments_validation2",
        })
    )
    .test(
      "file types",
      testFileTypesBuilder(
        "web_c_transfer_receivepaymentmodal_attachments_validation4"
      )
    )
    .test(
      "file size",
      /**
       *
       * @param {FileList} files
       */
      (files, ctx) =>
        !files ||
        Array.from(files).every((file) => file.size <= MAX_FILE_SIZE) ||
        ctx.createError({
          type: "file is too big",
          message: "web_c_transfer_receivepaymentmodal_attachments_validation3",
        })
    )
    // FIXME: This vaidation will look for file names that contain irregular characters!
    // so we might need to remove this incase it was fixed on BE side.
    .test(
      "file name",
      (files, ctx) =>
        Array.from(files).every((file) => isFileNameValid(file.name)) ||
        ctx.createError({
          type: "file name is not allowed",
          message: "web_c_transfer_receivepaymentmodal_attachments_validation5",
        })
    ),
  brief: yup
    .string()
    .transform((value) => (value.length > 0 ? value : undefined))
    .nullable(true)
    .min(5, "web_c_transfer_receivepaymentmodal_brief_validation1")
    .max(300, "web_c_transfer_receivepaymentmodal_brief_validation2")
    .when("purpose", {
      is: (purpose) => purpose?.id === PURPOSE_TYPE_OTHER,
      then: (schema) =>
        schema
          .nullable(false)
          .required("web_c_transfer_receivepaymentmodal_brief_validation3"),
    }),
})
