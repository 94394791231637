export const filterDisabled = (data) => data.filter(({ disabled }) => !disabled)

export const prepareSelectedItems = (
  options,
  optionsToBeSelected,
  isAllSelected
) =>
  isAllSelected
    ? filterDisabled(options)
    : filterDisabled(
        optionsToBeSelected.map(({ isSelected, ...otherProperties }) => ({
          ...otherProperties,
        }))
      )
