import { configureStore } from "@reduxjs/toolkit"

import userReducer from "../../features/user/user-slice"
import otacReducer from "../../features/otac/otac-slice"
import bankAccountsReducer from "../../features/bankAccounts/bankAccounts-slice"
import transactionsReducer from "../../features/transactions/transactions-slice"
import terminalsReducer from "../../features/terminals/terminals-slice"
import employeesReducer from "../../features/employees/employees-slice"
import teamsReducer from "../../features/teams/teams-slice"
import payrollReducer from "../../features/payroll/payroll-slice"
import transfersReducer from "../../features/transfers/transfers-slice"
import rolesReducer from "../../features/roles/roles-slice"
import pendingApprovalsReducer from "../../features/pendingApprovals/pending-approvals-slice"

export const createStore = () =>
  configureStore({
    reducer: {
      user: userReducer,
      otac: otacReducer,
      bankAccounts: bankAccountsReducer,
      transactions: transactionsReducer,
      terminals: terminalsReducer,
      employees: employeesReducer,
      teams: teamsReducer,
      payroll: payrollReducer,
      transfers: transfersReducer,
      roles: rolesReducer,
      pendingApprovals: pendingApprovalsReducer,
    },
  })

export default createStore()
