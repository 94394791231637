import PropTypes from "prop-types"

export const TRANSACTION_TYPE_P2P = "P2P"
export const TRANSACTION_TYPE_OTHER = "OTHER"

export const TRANSACTION_TYPES = [
  "BUY_NOW_PAY_LATER_REPAYMENT",
  "CARD_ATM_REVERSAL",
  "CARD_DEPOSIT",
  "CARD_ONLINE_PAYMENT",
  "CARD_ORDER_FEE",
  "CARD_PAYMENT",
  "CARD_PAYMENT_COMMISSION",
  "CARD_RECURRING_FEE",
  "CARD_WITHDRAWAL",
  "CARD_WITHDRAWAL_COMMISSION",
  "COMMISSION_FOR_SALARY",
  "CURRENCY_EXCHANGE",
  "DELIVERY_DEPOSIT_FEE",
  "DELIVERY_WITHDRAWAL_FEE",
  "DEPOSIT",
  "DEPOSIT_AT_BRANCH",
  "DOMESTIC_BANK_TRANSFER",
  "FAMILY_ACCOUNT_TRANSFER",
  "FEES_BANK_TRANSFER",
  "INCOMING_BANK_TRANSFER",
  "INTERNATIONAL_BANK_TRANSFER",
  "INVITE_TRANSFER",
  "INVITE_TRANSFER_FORWARD",
  "INVITE_TRANSFER_RETURN",
  "LOAN_GRANT",
  "LOAN_REPAYMENT",
  "MONEY_BOX_TRANSFER",
  "ONLINE_PAYMENT",
  "OTHER",
  "P2P",
  "PAYMENT",
  "PRO_MEMBERSHIP_RECURRING_FEE",
  "REMAINING_FUNDS_TRANSFER",
  "SALARY",
  "SERVICESTORE_PAYMENT",
  "TERMINAL_TRANSFER",
  "WITHDRAWAL",
  "WITHDRAWAL_AT_BRANCH",
  "BANK_OPERATION",
  "TOPUP",
  "CARD_TRANSFER",
  "CARD_TRANSFER_REFUND",
  "ONLINE_PAYMENT_REFUND",
  "CARD_ORDER_FEE_REFUND",
  "CASHBACK",
  "FUNDS_BLOCKED",
]

export const TransactionPropTypes = PropTypes.oneOf(TRANSACTION_TYPES)

export const MAP_TRANSACTION_TYPE_TO_LOKALISE_KEY = {
  CARD_ATM_REVERSAL: "general_transactiontype_cardatmreversal_label",
  CARD_DEPOSIT: "general_transactiontype_carddeposit_label",
  CARD_ONLINE_PAYMENT: "general_transactiontype_cardonlinepayment_label",
  CARD_ORDER_FEE: "general_transactiontype_cardorderfee_label",
  CARD_PAYMENT: "general_transactiontype_cardpayment_label",
  CARD_PAYMENT_COMMISSION:
    "general_transactiontype_cardpaymentcommission_label",
  CARD_RECURRING_FEE: "general_transactiontype_cardrecurringfee_label",
  CARD_WITHDRAWAL: "general_transactiontype_cardwithdrawal_label",
  CARD_WITHDRAWAL_COMMISSION:
    "general_transactiontype_cardwithdrawalcommission_label",
  COMMISSION_FOR_SALARY: "general_transactiontype_commissionforsalary_label",
  CURRENCY_EXCHANGE: "general_transactiontype_currencyexchange_label",
  DELIVERY_DEPOSIT_FEE: "general_transactiontype_deliverydepositfee_label",
  DELIVERY_WITHDRAWAL_FEE:
    "general_transactiontype_deliverywithdrawalfee_label",
  DEPOSIT: "general_transactiontype_deposit_label",
  DEPOSIT_AT_BRANCH: "general_transactiontype_depositatbranch_label",
  DOMESTIC_BANK_TRANSFER: "general_transactiontype_domesticbanktransfer_label",
  FAMILY_ACCOUNT_TRANSFER:
    "general_transactiontype_familyaccounttransfer_label",
  FEES_BANK_TRANSFER: "general_transactiontype_feesbanktransfer_label",
  INCOMING_BANK_TRANSFER: "general_transactiontype_incomingbanktransfer_label",
  INTERNATIONAL_BANK_TRANSFER:
    "general_transactiontype_internationalbanktransfer_label",
  INVITE_TRANSFER: "general_transactiontype_invitetransfer_label",
  INVITE_TRANSFER_FORWARD:
    "general_transactiontype_invitetransferforward_label",
  INVITE_TRANSFER_RETURN: "general_transactiontype_invitetransferreturn_label",
  MONEY_BOX_TRANSFER: "general_transactiontype_moneyboxtransfer_label",
  ONLINE_PAYMENT: "general_transactiontype_onlinepayment_label",
  OTHER: "general_transactiontype_other_label",
  P2P: "general_transactiontype_p2p_label",
  PAYMENT: "general_transactiontype_payment_label",
  PRO_MEMBERSHIP_RECURRING_FEE:
    "general_transactiontype_promembershiprecurringfee_label",
  REMAINING_FUNDS_TRANSFER:
    "general_transactiontype_remainingfundstransfer_label",
  SALARY: "general_transactiontype_salary_label",
  SERVICESTORE_PAYMENT: "general_transactiontype_servicestorepayment_label",
  TERMINAL_TRANSFER: "general_transactiontype_terminaltransfer_label",
  WITHDRAWAL: "general_transactiontype_withdrawal_label",
  WITHDRAWAL_AT_BRANCH: "general_transactiontype_withdrawalatbranch_label",
  LOAN_REPAYMENT: "general_transactiontype_loanrepayment_label",
  LOAN_GRANT: "general_transactiontype_loangrant_label",
  BUY_NOW_PAY_LATER_REPAYMENT:
    "general_transactiontype_buynowpaylaterrepayment_label",
  BANK_OPERATION: "general_transactiontype_bankops_label",
  TOPUP: "general_transactiontype_depositwithcard_label",
  CARD_TRANSFER: "general_transactiontype_cardtransfer_label",
  CARD_TRANSFER_REFUND: "general_transactiontype_cardtransferrefund_label",
  ONLINE_PAYMENT_REFUND: "general_transactiontype_onlinepaymentrefund_label",
  CARD_ORDER_FEE_REFUND: "general_transactiontype_cardorderrefund_label",
  CASHBACK: "general_transactiontype_cashback_label",
  FUNDS_BLOCKED: "general_transactiontype_pendingtransaction_label",
}
