import AxiosInstance from "../axios"

/**
 * @typedef DynamicPaymentTransactionConfig
 * @property {object} amountRange
 * @property {import("../../features/bankAccounts/bankAccounts-slice").MonetaryValue} amountRange.min
 * @property {import("../../features/bankAccounts/bankAccounts-slice").MonetaryValue} amountRange.max
 * @property {import("../../features/bankAccounts/bankAccounts-slice").MonetaryValue} balance
 *
 * @returns {Promise<import("axios").AxiosResponse<DynamicPaymentTransactionConfig>>}
 */
export const getDynamicPaymentTransactionConfiguration = () =>
  AxiosInstance.get("/v1/transactions/configurations/dynamic-payment")

/**
 * @typedef BankTransferTransactionConfig
 * @property {object} amountRange
 * @property {import("../../features/bankAccounts/bankAccounts-slice").MonetaryValue} amountRange.min
 * @property {import("../../features/bankAccounts/bankAccounts-slice").MonetaryValue} amountRange.max
 * @property {import("../../features/bankAccounts/bankAccounts-slice").MonetaryValue} balance
 *
 * @returns {Promise<import("axios").AxiosResponse<BankTransferTransactionConfig>>}
 */
export const getBankTransferTransactionConfiguration = () =>
  AxiosInstance.get("/v1/transactions/configurations/transfer")
