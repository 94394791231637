// istanbul ignore file
import React from "react"
import { BrowserRouter as Router } from "react-router-dom"
import ReactDOM from "react-dom/client"

import "react-toastify/dist/ReactToastify.min.css"
import "./styles/index.scss"
import "./i18n/i18n"
import App from "./App"
import { ENGLISH_LOCALE } from "./shared/constants/languages"
import Keycloak from "./lib/authentication/keycloak"
import {
  getRefreshToken,
  saveAuthenticationData,
} from "./lib/authentication/auth-utils"
import { dayjsLanguageChanger, getCurrentLocale, saveLocale } from "./lib/i18n"

const root = ReactDOM.createRoot(document.getElementById("root"))

fetch("/config.json")
  .then((res) => res.json())
  .then((config) => {
    localStorage.setItem("apiUrl", config.apiUrl)
    localStorage.setItem("kcAuthUrl", config.keycloak.url)

    if (!getCurrentLocale()) {
      saveLocale(ENGLISH_LOCALE)
    }

    const kc = Keycloak.getInstance(config.keycloak)
    kc.init({
      onLoad: "login-required",
      pkceMethod: "S256",
      checkLoginIframe: false,
      refreshToken: getRefreshToken(),
    }).then((isAuthenticated) => {
      const currentLocale = JSON.parse(getCurrentLocale())
      if (currentLocale) {
        try {
          dayjsLanguageChanger(currentLocale.lang)
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error)
        }
      }
      if (isAuthenticated) {
        saveAuthenticationData(kc)
        root.render(
          // TODO wrap the application with React.StrictMode for better reseliency.
          // Its intentionally left for last steps to make initial migration easier.
          // WATCH_OUT that adding it might break the application or dependency packages
          // that aren't updated to React v18, note that it has very strict rules especially
          // regarding useEffects, since they trigger twice in DEVELOPMENT, again, for
          // better reseliency of the app.
          <Router>
            <App />
          </Router>
        )
      }
    })

    kc.onTokenExpired = () => {
      kc.updateToken(5)
        .then(() => saveAuthenticationData(kc))
        .catch(() => window.location.reload())
    }
  })
  .catch(() => {
    root.render("Something went wrong, try again later!")
  })
