import AxiosInstance from "../axios"
import { buildPendingApprovalsParams } from "../endpointsHelper"

/**
 *
 * @param {string} id
 * @param {import("axios").AxiosRequestConfig} config
 */
export const approvePendingApproval = (id, config) =>
  AxiosInstance.post(
    `/v1/corporate-users/me/approvals/${id}/approve`,
    null,
    config
  )

/**
 *
 * @param {string} id
 * @param {import("axios").AxiosRequestConfig} config
 */
export const rejectPendingApproval = (id, config) =>
  AxiosInstance.post(
    `/v1/corporate-users/me/approvals/${id}/reject`,
    null,
    config
  )

export const getPendingApprovals = ({
  page,
  size,
  type,
  dateRange,
  searchQuery,
}) => {
  const params = buildPendingApprovalsParams({
    page,
    size,
    type,
    dateRange,
    searchQuery,
  })

  return AxiosInstance.get(`/v1/corporate-users/me/approvals?${params}`)
}
