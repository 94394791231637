import AxiosInstance from "../axios"

/**
 * @typedef UploadTransactionAttachmentResponse
 * @property {string} id
 *
 * @param {FormData} data
 * @param {import("axios").AxiosRequestConfig} config
 * @returns {Promise<import("axios").AxiosResponse<UploadTransactionAttachmentResponse>>}
 */
export const uploadTransactionAttachment = (data, config) =>
  AxiosInstance.post("/v1/transactions/attachments", data, config)
