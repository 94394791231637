/**
 * @readonly
 * @enum {string}
 */
export const TOGGLE_TERMINAL_VISIBILITY_FOR_AGENTS_STATE = {
  IDLE: "IDLE",
  STARTED: "STARTED",
  AUTHORIZING: "AUTHORIZING",
  ABORTING: "ABORTING",
  REJECTED: "REJECTED",
  AUTHORIZED: "AUTHORIZED",
  CHANGING: "CHANGING",
  CHANGED: "CHANGED",
  ERRORED: "ERRORED",
}
