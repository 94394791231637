/**
 * @readonly
 * @enum
 */
export const EDIT_EMPLOYEE_STATE = {
  IDLE: "IDLE",
  STARTED: "STARTED",
  INFORMATION_SET: "INFORMATION_SET",
  AUTHORIZING: "AUTHORIZING",
  ABORTING: "ABORTING",
  REJECTED: "REJECTED",
  EDITED: "EDITED",
  ERRORED: "ERRORED",
}

export const getStepNumberFromEditEmployeeState = (editingEmployeeState) => {
  switch (editingEmployeeState) {
    case EDIT_EMPLOYEE_STATE.STARTED:
    case EDIT_EMPLOYEE_STATE.INFORMATION_SET:
      return 0
    case EDIT_EMPLOYEE_STATE.AUTHORIZING:
    case EDIT_EMPLOYEE_STATE.ABORTING:
    case EDIT_EMPLOYEE_STATE.REJECTED:
      return 1
    default:
      return -1
  }
}
