import { useCallback, useState, useMemo, useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import classNames from "classnames"

import { InView } from "react-intersection-observer"
import { ReactComponent as TriangleDownIcon } from "../../assets/icons/triangle-down.svg"
import useClickAwayListener from "../../shared/hooks/useClickAwayListener"
import { getCommaByDir, hashItems } from "../../lib/general"
import { filterDisabled, prepareSelectedItems } from "../../lib/selectBox"
import SearchField from "../searchField/SearchField"
import Spinner from "../spinner/Spinner"

/**
 * This constant left here because it's sole purpose is to be used
 * in this component, will be moved to relevant constant file if
 * number of constant files increased or the option required to be
 * accessible outside of this component.
 */
export const ALL_OPTIONS_ITEM = {
  id: "ALL",
  text: "web_c_general_listbox_all_button",
}

const SelectBox = ({
  id,
  label,
  options,
  isMultiple,
  selectedItems,
  setSelectedItems,
  isDisabled,
  selectionLimit,
  isRTL,
  isLoading,
  withInfiniteScroll,
  onScrollEnd,
  onSearch,
  searchValue,
  searchPlaceholder,
  shouldWrapperBeVisibleWithNoOptions,
}) => {
  const { t } = useTranslation()
  const selectBoxListWrapperRef = useRef(null)
  const selectBoxHandleRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const [isDirty, setIsDirty] = useState(false)
  const [activeListDescendantId, setActiveListDescendantId] = useState()
  const [optionsToBeSelected, setOptionsToBeSelected] = useState(
    isMultiple ? [...selectedItems] : []
  )
  const [isAllSelected, setIsAllSelected] = useState(false)

  const clickAwayHandler = useCallback(() => {
    setIsOpen(false)
    if (isMultiple) {
      setOptionsToBeSelected(selectedItems)
    }
    if (isAllSelected) {
      setIsAllSelected(false)
    }
  }, [isAllSelected, isMultiple, selectedItems])
  useClickAwayListener(
    selectBoxListWrapperRef,
    selectBoxHandleRef,
    clickAwayHandler
  )

  const preparedOptions = useMemo(
    () =>
      options.map((option) => ({
        ...option,
        isSelected:
          !isAllSelected &&
          (isMultiple
            ? optionsToBeSelected.findIndex(
                (optionToBeSelected) => optionToBeSelected.id === option.id
              ) > -1
            : selectedItems?.id === option.id),
      })),
    [isAllSelected, isMultiple, options, optionsToBeSelected, selectedItems?.id]
  )

  const shouldDisableUnselectedOptions = useMemo(
    () =>
      isMultiple &&
      selectionLimit > 0 &&
      preparedOptions.map(({ isSelected }) => isSelected).filter(Boolean)
        .length >= selectionLimit,
    [isMultiple, preparedOptions, selectionLimit]
  )

  const shouldDisableApplyButton = useMemo(() => {
    if (!isAllSelected) {
      return (
        hashItems(optionsToBeSelected.map((option) => option.id).sort()) ===
        (Array.isArray(selectedItems) &&
          hashItems(selectedItems.map((option) => option.id).sort()))
      )
    }

    return selectedItems.length === options.length
  }, [isAllSelected, options.length, optionsToBeSelected, selectedItems])

  const onBoxHandleClickedHandler = () => setIsOpen((prevIsOpen) => !prevIsOpen)

  const onApplyClickedHandler = () => {
    setIsOpen(false)
    setIsDirty(false)
    setSelectedItems(
      prepareSelectedItems(options, optionsToBeSelected, isAllSelected)
    )
    selectBoxHandleRef.current.focus()
  }

  const listBoxItemOnClickHandlerBuilder = (option) => () => {
    if (option !== ALL_OPTIONS_ITEM.id && !option.isSelected) {
      setOptionsToBeSelected((prevOptionsToBeSelected) =>
        isMultiple ? prevOptionsToBeSelected.concat(option) : [option]
      )
    } else if (
      option !== ALL_OPTIONS_ITEM.id &&
      // TODO: add the uncheckable functionality to multiple selections also
      (isMultiple || (!isMultiple && !option.isUncheckable))
    ) {
      setOptionsToBeSelected((prevOptionsToBeSelected) =>
        prevOptionsToBeSelected.filter((item) => item.id !== option.id)
      )
    }

    setIsAllSelected(option === ALL_OPTIONS_ITEM.id && !isAllSelected)

    if (
      !isDirty &&
      // TODO: add the uncheckable functionality to multiple selections also
      (isMultiple ||
        (!isMultiple && !(option.isUncheckable && option.isSelected)))
    )
      setIsDirty(true)
  }

  const onSearchEnter = useCallback(
    (newSearchValue) => {
      onSearch(newSearchValue)
    },
    [onSearch]
  )

  /**
   * [Non-Multiple ONLY]
   * When an item (un)/selected then the Box must be closed.
   */
  useEffect(() => {
    if (!isMultiple && isDirty) setIsOpen(false)
  }, [isDirty, isMultiple, setIsOpen])

  useEffect(() => {
    if (!isOpen && isDirty) {
      const preparedSelectedItems = prepareSelectedItems(
        options,
        optionsToBeSelected,
        isAllSelected
      )

      if (!isMultiple) {
        setSelectedItems(preparedSelectedItems[0])
        setIsDirty(false)
      }

      setActiveListDescendantId(undefined)

      selectBoxHandleRef.current.focus()
    }
  }, [
    isAllSelected,
    isDirty,
    isMultiple,
    isOpen,
    options,
    optionsToBeSelected,
    setSelectedItems,
  ])

  /**
   * [Multiple ONLY]
   * update optionsToBeSelected (state) to reflect currently
   * selectedItems (prop) updated outside of the component.
   */
  useEffect(() => {
    if (isMultiple) {
      setOptionsToBeSelected([...selectedItems])
    }
  }, [isMultiple, selectedItems])

  /**
   * [Multiple ONLY]
   * When `isAllSelected` is true, then there is no option to be
   * marked as selected, because `All` currently selected.
   */
  useEffect(() => {
    if (isMultiple && isAllSelected) setOptionsToBeSelected([])
  }, [isAllSelected, isMultiple])

  /**
   * [Multiple ONLY]
   * When selectedItems (prop) changes, means the state should
   * reflect the changes happened from outside, so if each
   * option in the options (prop) isn't exist in selectedItems
   * (prop) it means `isAllselected` is no longer true.
   */
  useEffect(() => {
    if (
      isMultiple &&
      isAllSelected &&
      !isDirty &&
      selectedItems.length !== 0 &&
      filterDisabled(options).some(
        ({ id: optionId }) =>
          selectedItems.findIndex(
            ({ id: selectedItemId }) => optionId === selectedItemId
          ) < 0
      )
    ) {
      setIsAllSelected(false)
    }
  }, [isAllSelected, isDirty, isMultiple, options, selectedItems])

  return (
    <div
      className={classNames("select-box", {
        "select-box--none-selected":
          !isAllSelected &&
          (isMultiple
            ? selectedItems.length === 0
            : selectedItems === undefined),
      })}
    >
      <button
        id={id}
        className="select-box__handle"
        data-testid="select-box-handle"
        type="button"
        aria-haspopup="listbox"
        aria-expanded={isOpen}
        onMouseUp={onBoxHandleClickedHandler}
        onKeyUp={(e) => {
          if (/^space|enter$/.test(e.code.toLowerCase())) {
            setIsOpen((prevIsOpen) => !prevIsOpen)
            if (isMultiple) {
              setOptionsToBeSelected(selectedItems)
            }
            if (isAllSelected) {
              setIsAllSelected(false)
            }
          }
        }}
        onKeyUpCapture={(e) => {
          if (e.code.toLowerCase() === "escape") {
            setIsOpen(false)
          }
        }}
        ref={selectBoxHandleRef}
        disabled={isDisabled}
      >
        <span
          data-testid="select-box-handle-label"
          className="select-box__handle-label"
        >
          {!isMultiple && selectedItems?.text}
          {isMultiple &&
            selectedItems.length > 0 &&
            selectedItems
              .map(({ text }) => text)
              .join(`${getCommaByDir(isRTL)} `)}
          {(selectedItems === undefined || selectedItems.length === 0) && label}
        </span>
        <TriangleDownIcon
          className={classNames("select-box__handle__indicator", {
            "select-box__handle__indicator--disabled": isDisabled,
          })}
          width={18}
          height={18}
        />
      </button>
      {isOpen &&
        (shouldWrapperBeVisibleWithNoOptions || options.length > 0) && (
          <div
            ref={selectBoxListWrapperRef}
            className={classNames("select-box__list-wrapper", {
              "select-box__list-wrapper--scrollable": withInfiniteScroll,
            })}
            data-testid="select-box-list-wrapper"
            onKeyUpCapture={(e) => {
              if (e.code.toLowerCase() === "escape") {
                setIsOpen(false)
              }
            }}
          >
            {onSearch && (
              <SearchField
                placeholderLabel={searchPlaceholder}
                searchText={searchValue}
                onEnter={onSearchEnter}
              />
            )}
            <ul
              role="listbox"
              className="select-box__items-list"
              aria-activedescendant={activeListDescendantId}
              aria-multiselectable={isMultiple}
              aria-labelledby={id}
              tabIndex={-1}
            >
              {isMultiple && selectionLimit <= 0 && (
                <>
                  <SelectBoxItem
                    key={`${id}-${ALL_OPTIONS_ITEM.id}`}
                    optionId={ALL_OPTIONS_ITEM.id}
                    isSelected={isAllSelected}
                    activateHandler={listBoxItemOnClickHandlerBuilder(
                      ALL_OPTIONS_ITEM.id
                    )}
                    onFocus={() => {
                      setActiveListDescendantId(ALL_OPTIONS_ITEM.id)
                    }}
                    text={t(ALL_OPTIONS_ITEM.text)}
                    isAllSelected={isAllSelected}
                  />
                  <hr className="select-box__separator select-box__separator" />
                </>
              )}
              {preparedOptions.map((option, index) => {
                const {
                  id: optionId,
                  text,
                  isSelected,
                  isUncheckable,
                  disabled: isOptionDisabled,
                } = option
                const activateHandler = listBoxItemOnClickHandlerBuilder(option)
                if (
                  withInfiniteScroll &&
                  index === preparedOptions.length - 1
                ) {
                  return (
                    <InView
                      key={optionId}
                      as="div"
                      onChange={(inView) => {
                        if (inView) onScrollEnd?.()
                      }}
                    >
                      <SelectBoxItem
                        key={optionId}
                        optionId={optionId}
                        isSelected={!isAllSelected && isSelected}
                        activateHandler={activateHandler}
                        onFocus={() => {
                          setActiveListDescendantId(optionId)
                        }}
                        text={text}
                        disabled={
                          (!isSelected &&
                            (shouldDisableUnselectedOptions ||
                              isOptionDisabled)) ||
                          isAllSelected ||
                          (!isMultiple && isSelected && isUncheckable)
                        }
                        isAllSelected={isAllSelected}
                      />
                    </InView>
                  )
                }
                return (
                  <SelectBoxItem
                    key={optionId}
                    optionId={optionId}
                    isSelected={!isAllSelected && isSelected}
                    activateHandler={activateHandler}
                    onFocus={() => {
                      setActiveListDescendantId(optionId)
                    }}
                    text={text}
                    disabled={
                      (!isSelected &&
                        (shouldDisableUnselectedOptions || isOptionDisabled)) ||
                      isAllSelected ||
                      (!isMultiple && isSelected && isUncheckable)
                    }
                    isAllSelected={isAllSelected}
                  />
                )
              })}
              {isLoading && (
                <Spinner
                  variant="small"
                  rootStyle={{
                    margin: "0 auto 5px auto",
                  }}
                />
              )}
            </ul>
            {isMultiple && (
              <>
                <hr className="select-box__separator select-box__separator--my-10" />
                <button
                  onClick={onApplyClickedHandler}
                  disabled={shouldDisableApplyButton}
                  className="button button--outline-secondary select-box__apply-btn"
                  type="button"
                  data-testid="apply-btn"
                >
                  {t("web_c_general_listbox_apply_button")}
                </button>
              </>
            )}
          </div>
        )}
    </div>
  )
}

SelectBox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
      isUncheckable: PropTypes.bool,
    })
  ).isRequired,
  isMultiple: PropTypes.bool,
  selectedItems: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      })
    ),
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ]),
  setSelectedItems: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  selectionLimit: PropTypes.number,
  isRTL: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  withInfiniteScroll: PropTypes.bool,
  onScrollEnd: PropTypes.func,
  onSearch: PropTypes.func,
  searchValue: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  shouldWrapperBeVisibleWithNoOptions: PropTypes.bool,
}

SelectBox.defaultProps = {
  isMultiple: false,
  selectedItems: undefined,
  isDisabled: false,
  selectionLimit: -1,
  isLoading: false,
  withInfiniteScroll: false,
  onScrollEnd: undefined,
  onSearch: undefined,
  searchValue: "",
  searchPlaceholder: "",
  shouldWrapperBeVisibleWithNoOptions: false,
}

export default SelectBox

const SelectBoxItem = ({
  optionId,
  isSelected,
  activateHandler,
  onFocus,
  text,
  disabled,
  isAllSelected,
}) => (
  <li
    id={optionId}
    role="option"
    aria-selected={isSelected}
    data-testid="select-box-item"
    data-disabled={disabled}
    className={classNames("select-box-item", {
      "select-box-item--selected": isSelected,
      "select-box-item--disabled": disabled,
    })}
    tabIndex={disabled ? -1 : 0}
    onClick={!disabled ? activateHandler : undefined}
    onKeyUp={(e) => {
      if (["ENTER", "SPACE"].includes(e.code.toUpperCase()) && !disabled) {
        activateHandler()
      }
    }}
    onFocus={onFocus}
    title={text}
  >
    <input
      className={classNames("select-box-item__input", {
        "select-box-item__input--checked": isSelected,
      })}
      aria-labelledby={`${optionId}-label`}
      tabIndex={-1}
      type="checkbox"
      name={`${optionId}-checkbox`}
      id={`${optionId}-checkbox`}
      disabled={optionId !== ALL_OPTIONS_ITEM.id && isAllSelected}
    />
    <span
      tabIndex={-1}
      className="select-box-item__text"
      id={`${optionId}-label`}
    >
      {text}
    </span>
  </li>
)

SelectBoxItem.propTypes = {
  optionId: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  activateHandler: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  isAllSelected: PropTypes.bool,
}

SelectBoxItem.defaultProps = {
  disabled: false,
  isAllSelected: false,
}
