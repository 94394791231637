import dayjs from "dayjs"
import { thousandFormatter } from "../../lib/general"
import { MAP_TRANSACTION_TYPE_TO_LOKALISE_KEY } from "../../shared/constants/transactions"

/**
 *
 * @param {object[]} [data=[]]
 * @param {string} data.id
 * @param {object} data.counterparty
 * @param {string} data.counterparty.name
 * @param {string} data.counterparty.iban
 * @param {object} data.transaction
 * @param {string} data.transaction.type
 * @param {number} data.timestamp
 * @param {import("../bankAccounts/bankAccounts-slice").MonetaryValue} data.monetaryValue
 * @param {boolean} isRTL
 * @returns
 */

export const transformTerminalTransactionData = (data, isRTL) => {
  if (!Array.isArray(data)) return []

  return data.map((item) => {
    let formattedAmount = thousandFormatter(item?.monetaryValue?.amount, isRTL)

    if (item?.monetaryValue?.amount > 0) formattedAmount = `+${formattedAmount}`

    return {
      id: item.id,
      name: item?.counterparty?.name,
      iban: item?.counterparty?.iban,
      typeLokaliseKey:
        MAP_TRANSACTION_TYPE_TO_LOKALISE_KEY[item?.transaction?.type],
      amount: formattedAmount,
      currency: item?.monetaryValue?.currency,
      date: dayjs(item?.timestamp).format("MM/DD/YYYY, hh:mm:ss A"),
      status: item.status
    }
  })
}
