import routes from "../../routes/routeDefinitions"

export const AGENT_FEATURES = {
  DASHBOARD: "DASHBOARD",
  BALANCE: "BALANCE",
  OPERATION_HISTORY: "OPERATION_HISTORY",
  TERMINALS: "TERMINALS",
  SHIFT_MONEY: "SHIFT_MONEY",
  EMPLOYEES: "EMPLOYEES",
  TEAMS: "TEAMS",
  TEAM_SALARY_DISTRIBUTIONS: "TEAM_SALARY_DISTRIBUTIONS",
  FILE_SALARY_DISTRIBUTIONS: "FILE_SALARY_DISTRIBUTIONS",
  BANK_TRANSFERS: "BANK_TRANSFERS",
  RECEIVE_PAYMENTS: "RECEIVE_PAYMENTS",
  BULK_BANK_TRANSFERS: "BULK_BANK_TRANSFERS",
  ROLES: "ROLES",
  AGENTS: "AGENTS",
}

export const PERMISSIONS = {
  ADD: "ADD",
  VIEW: "VIEW",
  EDIT: "EDIT",
  DELETE: "DELETE",
}

export const AGENT_APPROVAL_THRESHOLDS = {
  SHIFT_MONEY: "SHIFT_MONEY",
  SALARY_DISTRIBUTIONS: "SALARY_DISTRIBUTIONS",
  BANK_TRANSFERS: "BANK_TRANSFERS",
  RECEIVE_PAYMENTS: "RECEIVE_PAYMENTS",
  BULK_TRANSFERS: "BULK_TRANSFERS",
}

export const MAP_AGENT_FEATURES_TO_ROUTES = {
  DASHBOARD: routes.home.path,
  BALANCE: routes.balance.path,
  OPERATION_HISTORY: routes.transactionHistory.path,
  TERMINALS: routes.terminals.path,
  SHIFT_MONEY: routes.terminals.terminalDetails.path,
  EMPLOYEES: routes.employees.path,
  TEAMS: routes.teams.path,
  TEAM_SALARY_DISTRIBUTIONS: routes.payroll.path,
  FILE_SALARY_DISTRIBUTIONS: routes.payroll.path,
  BANK_TRANSFERS: routes.transfer.path,
  RECEIVE_PAYMENTS: routes.transfer.path,
  BULK_BANK_TRANSFERS: routes.transfer.path,
  ROLES: routes.roles.path,
  AGENTS: routes.users.path,
}

export const analyticsLinks = [
  {
    to: routes.home.path,
    text: "web_c_analytics_submenu_dashboard_label",
  },
  {
    to: routes.balance.path,
    text: "web_c_analytics_submenu_balance_label",
  },
  {
    to: routes.transactionHistory.path,
    text: "web_c_analytics_submenu_transactionhistory_label",
  },
]

export const employeesLinks = [
  {
    to: routes.employees.path,
    text: "web_c_employee_submenu_employee_label",
  },
  {
    to: routes.payroll.path,
    text: "web_c_employee_submenu_payroll_label",
  },
  {
    to: routes.teams.path,
    text: "web_c_employee_submenu_teams_label",
  },
]

export const usersLinks = [
  {
    to: routes.roles.path,
    text: "web_c_users_submenu_roles_label",
  },
  {
    to: routes.users.path,
    text: "web_c_users_submenu_users_label",
  },
]
